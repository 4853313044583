import { Button, Form, Input, Modal, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import { CloseCircle } from '../../../assets/svg';
import { FormFieldConfig } from '../../../types/common.type';

type AddProjectEnvPropTypes = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  form: FormInstance;
  formTitle: string;
  formItems: FormFieldConfig[];
  // eslint-disable-next-line no-unused-vars
  onFinish: (values: { environment: string }) => void;
  submitButton: string;
  loadings: boolean;
};

const AddProjectEnvironmentModal = ({
  form,
  isModalOpen,
  setIsModalOpen,
  formTitle,
  formItems,
  onFinish,
  submitButton,
  loadings,
}: AddProjectEnvPropTypes) => {
  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
    form?.resetFields();
  };

  return (
    <>
      <Modal
        centered
        wrapClassName="environment-modal"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        closeIcon={<CloseCircle />}
        footer={null}
        onOk={form?.submit}
        forceRender
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <div className="add-container">
            <div className="d-flex flex-horizontal justify-between">
              <p className="add-title">{formTitle}</p>
            </div>
            <div className="d-flex flex-vertical justify-between">
              {formItems?.length > 0 &&
                formItems?.map((formItem) => {
                  return (
                    <Form.Item
                      key={formItem?.name}
                      label={formItem?.title}
                      name={formItem?.name}
                      rules={formItem?.rules}
                    >
                      <Input
                        className="input-box"
                        placeholder={formItem?.placeholder}
                      />
                    </Form.Item>
                  );
                })}
            </div>
            <Space size="middle" className="mt-20">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={loadings}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddProjectEnvironmentModal;
