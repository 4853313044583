import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import '../../styles/pages/login.less';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { SEND_OTP } from './graphql/Mutations';
const { required, email } = formValidatorRules;

const Login = () => {
  const { navigate } = useRouter();

  const { dispatch } = useContext(AppContext) as AppContextType;
  const [userEmail, setUserEmail] = useState<string>('');
  const [loadings, setLoadings] = useState<boolean>(false);

  const [sendOtpFun] = useMutation(SEND_OTP, {
    variables: {
      email: userEmail,
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    return localStorage?.removeItem('USER');
  }, []);

  const onFinish = async () => {
    setLoadings(true);
    try {
      const response = await sendOtpFun();
      const data = response?.data;
      if (data) {
        dispatch({
          type: AppActionType.setCurrentUser,
          data: { email: userEmail },
        });
        setLoadings(false);
        navigate(ROUTES?.VERIFY);
      }
    } catch (error) {
      setLoadings(false);
      return error;
    }
  };

  return (
    <div className="gx-login-container login">
      <Spin spinning={false} wrapperClassName="gx-login-content">
        <div className="text-center site-title mb-32">Sendbay</div>
        <div className="gx-login-header">
          <span className="gx-login-header-title white-color mb-8">
            Get started
          </span>
          <span className="gx-login-header-subtitle white-color">
            You will receive 6 digit OTP in your inbox for verification
          </span>
          {/* <img src={logo} alt="Boilerplate" className="mb-4 fill-width" /> */}
        </div>
        <Form
          name="Login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
          validateTrigger="onBlur"
        >
          <Form.Item name="email" rules={[required, email]}>
            <Input
              placeholder="Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e?.target?.value)}
            />
          </Form.Item>
          <Form.Item className="text-center w-100">
            <Button
              loading={loadings}
              type="primary"
              className="fill-width primary-button "
              htmlType="submit"
            >
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Login;
