import { message } from 'antd';
import { UploadFile } from 'antd/lib';
import dayjs from 'dayjs';
import client from '../apollo';
import { GET_SIGNED_URL } from '../components/graphql/Mutation';
import api from './api';
import { defaultDateFormat, REGEX } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId: string) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isPortalIdExists = (portalId: string) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId: string) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isDocumentIdExist = (portalId: string) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  datetime: string,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (datetime && dayjs && format) {
    return dayjs(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value).toString())) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
  phoneNo: () => ({
    validator(rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.PHONE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid phone no!');
      }
      return Promise?.resolve();
    },
  }),
  text: () => ({
    validator(rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NOT_ALLOWED_SPECIAL_CHAR_NUM?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please remove special characters or numbers!');
      }
      return Promise?.resolve();
    },
  }),
  textSpecialChar: () => ({
    validator(rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NOT_ALLOWED_SPECIAL_CHAR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please remove special characters!');
      }
      return Promise?.resolve();
    },
  }),
  hyphenAndChar: () => ({
    validator(rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.ALLOWED_HYPHEN_AND_CHAR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Please remove special characters other than hyphen!',
        );
      }
      return Promise?.resolve();
    },
  }),
};

export const formatPhoneNumber = (str: string) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const capitalizeAndSplitFromCapitalLetter = (string: string) => {
  return (
    (string?.charAt(0)?.toUpperCase() + string?.slice(1)).match(
      /[A-Z][a-z]+|[0-9]+/g,
    ) || []
  ).join(' ');
};

export const formatPhoneNumberWithoutMask = (str: string) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const getSignedUrl = async (fileObj: UploadFile) => {
  const fileName = fileObj?.name;

  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (
  fileObj: UploadFile,
  signedRequest?: string | null,
) => {
  if (signedRequest) {
    await api(signedRequest, {
      method: 'PUT',
      data: fileObj?.originFileObj || fileObj,
      headers: {
        'Content-Type': fileObj?.type,
      },
    });
  } else {
    message.error('Invalid signed url');
  }
};

export const fetchImage = async (fileObj: UploadFile) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });
};

export const handleCopy = (event: string) => {
  // eslint-disable-next-line no-undef
  if (event) {
    window.navigator.clipboard.writeText(event);
    message.success('Text copied to clipboard');
  }
};
