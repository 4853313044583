import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Form,
  message,
  Modal,
  Popconfirm,
  TableColumnsType,
} from 'antd';

import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { CloseCircle } from '../../../assets/svg';
import { USERS_ROLE } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import Nodata from '../../../components/Nodata';
import SearchComponent from '../../../components/SearchComponent';
import {
  ChannelEmailUserSortBy,
  ChannelMemberResponse,
  ChannelMemberResponseData,
} from '../../../gql/graphql';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { AppContextType } from '../../../types/appContext.type';
import { CommonModalProps } from '../../../types/common.type';
import { ADD_MEMBERS, DELETE_MEMBER } from '../graphql/Mutation';
import { GET_CHANNEL_MEMBERS } from '../graphql/Queries';
import AddMembersModal from './AddMembersModal';

type ViewMembersModalPropType = CommonModalProps & {
  channelId: string;
};
const ViewMembersModal = ({
  isModalOpen,
  setIsModalOpen,
  channelId,
}: ViewMembersModalPropType) => {
  const [form] = Form.useForm();
  const { state, getCurrentRole } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const [channelMembers, setChannelMembers] = useState<
    ChannelMemberResponse['data']
  >([]);
  const [isChannelMemberLoading, setIsChannelMemberLoading] =
    useState<boolean>(true);
  const [channelMembersSearchTerm, setChannelMembersSearchTerm] =
    useState<string>('');
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] =
    useState<boolean>(false);
  const [isAddMembersBtnLoading, setIsAddMembersBtnLoading] =
    useState<boolean>(false);
  const [isEmptyChannelMembersList, setIsEmptyChannelMembersList] =
    useState<boolean>(false);
  const [isFetchMoreMembersLoading, setIsFetchMoreMembersLoading] =
    useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);

  const limit = 10;

  const [executeAddMembers] = useMutation(ADD_MEMBERS, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeDeleteMember] = useMutation(DELETE_MEMBER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  const [executeChannelMembersList] = useLazyQuery(GET_CHANNEL_MEMBERS, {
    onCompleted: (response) => {
      if (
        response?.channelEmailUsers?.data &&
        response?.channelEmailUsers?.data?.length < limit
      ) {
        setIsActive(false);
      }
      if (response?.channelEmailUsers?.count === 0) {
        setIsEmptyChannelMembersList(true);
      }
      setChannelMembers([
        ...(channelMembers || []),
        ...(response?.channelEmailUsers?.data || []),
      ]);
      setIsChannelMemberLoading(false);
      setIsFetchMoreMembersLoading(false);
    },
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError() {},
  });

  useEffect(() => {
    if (state?.projectEnvId && channelId && isModalOpen) {
      setIsChannelMemberLoading(true);
      setIsActive(true);
      setChannelMembers([]);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: 0,
            limit,
            sortBy: ChannelEmailUserSortBy.CreatedAtDesc,
          },
          channelId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, state?.projectEnvId, isModalOpen]);

  const handleReset = () => {
    form?.resetFields();
  };

  const addMembersFun = async (membersDetail: { email: string[] }) => {
    if (membersDetail?.email) {
      setIsAddMembersBtnLoading(true);
      const response = await executeAddMembers({
        variables: {
          data: {
            emails: membersDetail?.email,
          },
          channelId,
        },
      });
      if (response?.data) {
        setIsAddMembersModalOpen(false);
        setIsChannelMemberLoading(true);
        setChannelMembers([]);
        setChannelMembersSearchTerm('');
        setIsActive(true);
        executeChannelMembersList({
          variables: {
            filter: {
              skip: 0,
              limit,
              sortBy: ChannelEmailUserSortBy.CreatedAtDesc,
            },
            channelId,
          },
        });
        // eslint-disable-next-line no-undef
        setTimeout(handleReset, 500);
      }
      setIsAddMembersBtnLoading(false);
    } else {
      message.error('Member emails not found');
    }
  };

  const deleteMemberFun = async (id: string) => {
    const response = await executeDeleteMember({
      variables: {
        channelMemberId: id,
        channelId,
      },
    });
    if (response?.data) {
      setIsChannelMemberLoading(true);
      setChannelMembers([]);
      setIsActive(true);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: 0,
            limit,
            search: channelMembersSearchTerm,
            sortBy: ChannelEmailUserSortBy.CreatedAtDesc,
          },
          channelId,
        },
      });
    }
  };

  const handleSearch = (value: string) => {
    const trimValue = value?.trim();
    setChannelMembersSearchTerm(trimValue);
    setIsChannelMemberLoading(true);
    setChannelMembers([]);
    setIsActive(true);
    executeChannelMembersList({
      variables: {
        filter: {
          skip: 0,
          limit,
          search: trimValue,
          sortBy: ChannelEmailUserSortBy.CreatedAtDesc,
        },
        channelId,
      },
    });
  };

  const handleMembersScroll = () => {
    // eslint-disable-next-line no-console
    console.log('sdasdasdasdasdasdsad');
    if (!isFetchMoreMembersLoading && !isChannelMemberLoading) {
      const currentLength = channelMembers?.length || 0;
      setIsFetchMoreMembersLoading(true);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: currentLength,
            limit,
            search: '',
            sortBy: ChannelEmailUserSortBy.CreatedAtDesc,
          },
          channelId,
        },
      });
    }
  };

  const columns: TableColumnsType<ChannelMemberResponseData> = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: ChannelMemberResponseData, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'EMAIl',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 300,
      align: 'left' as const,

      className: 'max-width-column',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      hidden: false,
      width: 250,
      render: (_: string, record: ChannelMemberResponseData) => {
        return (
          <>
            <div className="action-button">
              <Popconfirm
                title={`Are you sure you want to delete ${record?.email}?`}
                onConfirm={() => deleteMemberFun(record?.id as string)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ]?.filter((item) => {
    if (userRole === USERS_ROLE?.READ_ONLY && item?.dataIndex === 'actions') {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyChannelMembersList ? '' : <span />,
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleMembersScroll, isActive);

  return (
    <Modal
      centered
      closeIcon={<CloseCircle />}
      wrapClassName="member-modal"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      forceRender
      width={1000}
    >
      <AddMembersModal
        form={form}
        isModalOpen={isAddMembersModalOpen}
        setIsModalOpen={setIsAddMembersModalOpen}
        formTitle="Add Members"
        submitButton="Add"
        handleReset={handleReset}
        onFinish={addMembersFun}
        loadings={isAddMembersBtnLoading}
      />
      <div className="channels">
        <p className="add-title mb-16">Members</p>
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            <SearchComponent
              query={channelMembersSearchTerm}
              setQuery={setChannelMembersSearchTerm}
              className="search-input"
              getData={handleSearch}
            />
          </div>
          <>
            {(userRole === USERS_ROLE?.OWNER ||
              userRole === USERS_ROLE?.WRITE) && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="primary-button"
                onClick={() => setIsAddMembersModalOpen(true)}
              >
                Add Members
              </Button>
            )}
          </>
        </div>
        <div className="mt-30 flex-vertical">
          {isChannelMemberLoading ? (
            <div className="view-member-modal">
              <LoaderComponent
                size="large"
                setHeight="60"
                spinning={isChannelMemberLoading}
              />
            </div>
          ) : (
            <>
              {channelMembers && channelMembers?.length > 0 ? (
                <CommonTable
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                  className="members-table"
                  locale={locale}
                  columns={columns}
                  data={
                    channelMembers?.filter(
                      (channel): channel is ChannelMemberResponseData =>
                        !!channel,
                    ) || []
                  }
                  loadingData={
                    isChannelMemberLoading || isFetchMoreMembersLoading
                  }
                  scroll={{ y: 350 }}
                  rowKey="id"
                  onRow={() => {
                    return {
                      ref: infiniteScrollRef,
                    };
                  }}
                />
              ) : (
                <div className="analytics-illustration-members">
                  <Nodata />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewMembersModal;
