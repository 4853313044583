import { gql } from '../../../__generated__';

export const CREATE_PLATFORM = gql(`
  mutation createPlatform($data: PlatformData!) {
    createPlatform(data: $data) {
      message
      status
      data {
        id
        name
      }
    }
  }
`);

export const UPDATE_PLATFORM = gql(`
  mutation updatePlatform($data: PlatformUpdateData!, $id: ID!) {
    updatePlatform(data: $data, id: $id) {
      message
      status
      data {
        id
        name
        platformConfigs
      }
    }
  }
`);

export const DELETE_PLATFORM = gql(`
  mutation deletePlatform($id: ID!) {
    deletePlatform(id: $id) {
      status
      message
    }
  }
`);
