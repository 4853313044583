import { gql } from '../../__generated__';

export const GET_SIGNED_URL = gql(`
  mutation getSignedUrl($data: UploadData!) {
    getSignedUrl(data: $data) {
      signedRequest
      url
    }
  }
`);
