import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { PROVIDER_FIELD_TYPE } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { PlatformType, Provider } from '../../../gql/graphql';
import { AppContextType } from '../../../types/appContext.type';
import { CREATE_PLATFORM } from '../graphql/Mutation';
import { GET_PROVIDER } from '../graphql/Queries';

const { number } = formValidatorRules;
type CreateEditPlatformFormPropType = {
  current: number;
  providerId: string;
  providerValue: string;
  providerSlug: string;
  // eslint-disable-next-line no-unused-vars
  setCurrent: (value: number | ((prevState: number) => number)) => void;
  // eslint-disable-next-line no-unused-vars
  setProviderId: (value: string | ((prevState: string) => string)) => void;
};
const CreateEditPlatformForm = ({
  current,
  providerId,
  providerValue,
  providerSlug,
  setCurrent,
  setProviderId,
}: CreateEditPlatformFormPropType) => {
  const {
    state: { projectEnvId },
  } = useContext(AppContext) as AppContextType;
  const [providerDetail, setProviderDetail] = useState<Provider | null>();
  const [isCreateEditBtnPlatformLoading, setIsCreateEditPlatformBtnLoading] =
    useState<boolean>(false);

  const [executeCreatePlatform] = useMutation(CREATE_PLATFORM, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  const [executeProvider, { loading: isProviderLoading }] = useLazyQuery(
    GET_PROVIDER,
    {
      onCompleted: (response) => {
        setProviderDetail(response?.provider);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    },
  );

  useEffect(() => {
    if (providerId) {
      executeProvider({
        variables: {
          id: providerId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const providerConfigs: Record<string, any>[] | null =
    providerDetail?.providerConfigs || [];

  const prev = () => {
    setProviderId('');
    const prevCurrent = current - 1;
    setCurrent(prevCurrent);
  };

  const createNewPlatformFun = async (values: {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    platformConfigs: any;
  }) => {
    setIsCreateEditPlatformBtnLoading(true);
    const response = await executeCreatePlatform({
      variables: {
        data: {
          name: values?.name?.trim(),
          platformConfigs:
            providerDetail?.name === 'DEFAULT'
              ? {
                  EMAIL: { DEFAULT: {} },
                }
              : values?.platformConfigs || {},
          projectEnvId: projectEnvId,
          type: PlatformType.Email,
          slug: providerSlug,
        },
      },
    });
    if (response?.data) {
      setIsCreateEditPlatformBtnLoading(false);
      prev();
    }
    setIsCreateEditPlatformBtnLoading(false);
  };

  return (
    <Spin spinning={isProviderLoading}>
      <div className="add-container setting">
        <div className="d-flex flex-horizontal justify-between">
          <p className="add-title">New Platform</p>
        </div>
        <Form
          name="basic"
          autoComplete="off"
          layout="horizontal"
          onFinish={createNewPlatformFun}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
        >
          <Form.Item
            label="Platform Name"
            name="name"
            className="mt-10"
            initialValue={providerValue}
          >
            <Input className="input-box" disabled />
          </Form.Item>
          {providerConfigs?.map((providerConfig) => {
            if (providerConfig?.type === PROVIDER_FIELD_TYPE?.NUMBER) {
              return (
                <Form.Item
                  key={providerConfig?.key}
                  label={providerConfig?.label}
                  name={[
                    'platformConfigs',
                    'EMAIL',
                    providerDetail?.slug,
                    providerConfig?.key,
                  ]}
                  className="mt-10"
                  rules={[
                    {
                      required: providerConfig?.isRequired,
                      message: `Please input ${providerConfig?.label}`,
                    },
                    number,
                  ]}
                >
                  {providerConfig?.type === PROVIDER_FIELD_TYPE?.PASSWORD ? (
                    <Input.Password className="customize-password" />
                  ) : (
                    <Input className="input-box" />
                  )}
                </Form.Item>
              );
            }
            return (
              <Form.Item
                key={providerConfig?.key}
                label={providerConfig?.label}
                name={[
                  'platformConfigs',
                  'EMAIL',
                  providerDetail?.slug,
                  providerConfig?.key,
                ]}
                className="mt-10"
                rules={[
                  {
                    required: providerConfig?.isRequired,
                    message: `Please input ${providerConfig?.label}`,
                  },
                ]}
              >
                {providerConfig?.type === PROVIDER_FIELD_TYPE?.PASSWORD ? (
                  <Input.Password className="customize-password" />
                ) : (
                  <Input className="input-box" />
                )}
              </Form.Item>
            );
          })}
          <Form.Item
            wrapperCol={{
              offset: 3,
              span: 21,
            }}
          >
            <Button
              className="primary-button"
              type="primary"
              htmlType="submit"
              loading={isCreateEditBtnPlatformLoading}
            >
              Create New Platform
            </Button>
            <Button className="secondary-button" onClick={prev}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default CreateEditPlatformForm;
