import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Popconfirm,
  TableColumnsType,
  TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DATE_FORMAT, ROUTES, SORT, USERS_ROLE } from '../../common/constants';
import { formatDate } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import {
  Channel,
  ChannelSortBy,
  ChannelsResponse,
  ChannelType,
} from '../../gql/graphql';
import { AppContextType } from '../../types/appContext.type';
import './channels.less';
import AddChannel from './components/AddChannel';
import SendEmailModal from './components/SendEmailModal';
import ViewMembersModal from './components/ViewMembersModal';
import {
  CREATE_CHANNEL,
  DELETE_CHANNEL,
  UPDATE_CHANNEL,
} from './graphql/Mutation';
import { GET_CHANNELS } from './graphql/Queries';

const initialPaginationValue: TablePaginationConfig = {
  total: 0,
  current: 1,
  pageSize: 10,
};

type ChannelPropType = {
  // eslint-disable-next-line no-unused-vars
  setIsMailSend: (value: boolean) => void;
  activeKey: string;
};
const ChannelComponent = ({ setIsMailSend, activeKey }: ChannelPropType) => {
  const [form] = Form.useForm();
  const { state, getCurrentRole } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Channel> | null>(
    {},
  );
  const [isEmptyChannelList, setIsEmptyChannelList] = useState<boolean>(false);
  const [paginationProp, setPaginationProp] = useState<TablePaginationConfig>(
    initialPaginationValue,
  );

  const [channelList, setChannelList] = useState<ChannelsResponse['channels']>(
    [],
  );
  const [isAddChannelModalOpen, setAddChannelModalOpen] =
    useState<boolean>(false);
  const [isSendMailModalOpen, setIsSendMailModalOpen] =
    useState<boolean>(false);
  const [isChannelLoading, setIsChannelLoading] = useState<boolean>(true);
  const [isChannelSearchLoading, setIsChannelSearchLoading] =
    useState<boolean>(false);
  const [isCreateChannelBtnLoading, setIsCreateChannelBtnLoading] =
    useState<boolean>(false);
  const [channelDetails, setChannelDetails] = useState<Channel | null>(null);
  const [channelId, setChannelId] = useState<string>('');
  const [channelSearchTerm, setChannelSearchTerm] = useState<string>('');
  const [isViewMembersModalOpen, setIsViewMembersModalOpen] =
    useState<boolean>(false);
  const [isAllowClear, setIsAllowClear] = useState<boolean>(false);

  const limit = 10;

  const [executeCreateChannel] = useMutation(CREATE_CHANNEL, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeUpdateChannel] = useMutation(UPDATE_CHANNEL, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeDeleteChannel] = useMutation(DELETE_CHANNEL, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  const [executeChannelList] = useLazyQuery(GET_CHANNELS, {
    onCompleted: (response) => {
      setChannelList([
        ...(channelList || []),
        ...(response?.channels?.channels || []),
      ]);
      if (
        response?.channels?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyChannelList(true);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.channels?.count,
      };
      setPaginationProp(pagination as TablePaginationConfig);
      setIsChannelLoading(false);
      setIsChannelSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsChannelLoading(false);
    },
  });

  useEffect(() => {
    setIsChannelLoading(true);
    setChannelList([]);
    if (state?.projectEnvId && activeKey === ROUTES?.CHANNELS) {
      executeChannelList({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            search: '',
            sortBy: ChannelSortBy.CreatedAtDesc,
            projectEnvId: state?.projectEnvId,
            type: ChannelType.Email,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, activeKey]);

  const handleAddChannelReset = () => {
    form?.resetFields();
    setChannelDetails(null);
  };

  const createChannelFun = async (channelDetail: Channel) => {
    setIsCreateChannelBtnLoading(true);
    const response = await executeCreateChannel({
      variables: {
        data: {
          name: channelDetail?.name?.trim() as string,
          projectEnvId: state?.projectEnvId as string,
          type: ChannelType.Email,
        },
      },
    });
    if (response?.data) {
      setAddChannelModalOpen(false);
      setIsChannelLoading(true);
      setChannelList([]);
      setPaginationProp({ ...paginationProp, current: 1 });
      setChannelSearchTerm('');
      executeChannelList({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            search: '',
            sortBy: ChannelSortBy.CreatedAtDesc,
            projectEnvId: state?.projectEnvId as string,
            type: ChannelType.Email,
          },
        },
      });
      // eslint-disable-next-line no-undef
      setTimeout(handleAddChannelReset, 500);
    }
    setIsCreateChannelBtnLoading(false);
  };

  const updateChannelFun = async (channelDetail: Channel) => {
    setIsCreateChannelBtnLoading(true);
    const response = await executeUpdateChannel({
      variables: {
        id: channelDetails?.id as string,
        data: {
          name: channelDetail?.name?.trim(),
        },
      },
    });
    if (response?.data) {
      setAddChannelModalOpen(false);
      setIsChannelLoading(true);
      setChannelList([]);
      executeChannelList({
        variables: {
          filter: {
            skip:
              ((paginationProp?.current || 1) - 1) *
              (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: channelSearchTerm,
            sortBy: ChannelSortBy.CreatedAtDesc,
            projectEnvId: state?.projectEnvId as string,
            type: ChannelType.Email,
          },
        },
      });
      // eslint-disable-next-line no-undef
      setTimeout(handleAddChannelReset, 500);
    }
    setIsCreateChannelBtnLoading(false);
  };

  const deleteChannelFun = async (id: string) => {
    const response = await executeDeleteChannel({
      variables: {
        id: id as string,
      },
    });
    if (response?.data) {
      setIsChannelLoading(true);
      setChannelList([]);
      const lastPageEle =
        (paginationProp?.total || 0) % (paginationProp?.pageSize || 1) === 1 &&
        (paginationProp?.total || 0) > 10;
      if (lastPageEle) {
        setPaginationProp({
          ...paginationProp,
          current: (paginationProp?.current || 1) - 1,
        });
      }
      executeChannelList({
        variables: {
          filter: {
            skip: lastPageEle
              ? ((paginationProp?.current || 1) - 1) *
                  (paginationProp?.pageSize || 0) -
                (paginationProp?.pageSize || 0)
              : ((paginationProp?.current || 1) - 1) *
                (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: channelSearchTerm,
            sortBy: ChannelSortBy.CreatedAtDesc,
            projectEnvId: state?.projectEnvId as string,
            type: ChannelType.Email,
          },
        },
      });
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    tablefilter: Record<string, FilterValue | null>,
    sorter: SorterResult<Channel>,
  ) => {
    const { current } = pagination;
    const skip = ((current || 1) - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsChannelLoading(true);
    setChannelList([]);
    if (sorter?.column) {
      executeChannelList({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize || limit,
            search: channelSearchTerm,
            sortBy:
              sorter?.order === 'ascend'
                ? (`${sorter?.field}${SORT.ASC}` as ChannelSortBy)
                : (`${sorter?.field}${SORT.DESC}` as ChannelSortBy),
            projectEnvId: state?.projectEnvId as string,
            type: ChannelType.Email,
          },
        },
      });
    } else {
      executeChannelList({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize || limit,
            search: channelSearchTerm,
            sortBy: ChannelSortBy.CreatedAtDesc,
            projectEnvId: state?.projectEnvId as string,
            type: ChannelType.Email,
          },
        },
      });
    }
  };

  const handleSearch = (value: string) => {
    setIsChannelSearchLoading(true);
    const trimValue = value?.trim() || '';
    setChannelSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1 });
    setIsChannelLoading(true);
    setChannelList([]);
    executeChannelList({
      variables: {
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          sortBy: ChannelSortBy.CreatedAtDesc,
          projectEnvId: state?.projectEnvId as string,
          type: ChannelType.Email,
        },
      },
    });
    setIsAllowClear(false);
  };

  const AddChannelFormsItems = [
    {
      title: 'Name',
      name: 'name',
      placeholder: 'Enter channel name',
      rules: [{ required: true, message: 'Please enter channel name!' }],
    },
  ];
  const columns: TableColumnsType<Channel> = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left' as const,
      className: 'max-width-column',
      render: (_: string, record: Channel, index: number) => {
        return (
          <span>
            {(paginationProp?.pageSize || 0) * (paginationProp?.current || 1) -
              (paginationProp?.pageSize || 0) +
              index +
              1}
          </span>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      width: 200,
      align: 'left' as const,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' ? sortedInfo?.order : null,
    },
    {
      title: 'CREATED ON',
      dataIndex: 'created_on',
      key: 'created_on',
      ellipsis: true,
      width: 200,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: Channel) => {
        return formatDate(record?.createdAt, DATE_FORMAT);
      },
    },
    {
      title: 'SEND MAIL',
      dataIndex: 'send_mail',
      key: 'send_mail',
      hidden: false,
      render: (_: string, record: Channel) => {
        return (
          <Button
            className="secondary-button mail"
            onClick={() => {
              setIsSendMailModalOpen(true);
              setChannelId(record?.id as string);
            }}
          >
            Mail
          </Button>
        );
      },
    },
    {
      title: 'MEMBERS',
      dataIndex: 'view_members',
      key: 'view_members',
      render: (_: string, record: Channel) => {
        return (
          <Button
            type="primary"
            className="primary-button mail"
            onClick={() => {
              setIsViewMembersModalOpen(true);
              setChannelId(record?.id as string);
            }}
          >
            View Members
          </Button>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      hidden: false,
      render: (_: string, record: Channel) => {
        return (
          <>
            <div className="action-button">
              <Button type="link">
                <EditOutlined
                  onClick={() => {
                    setChannelDetails(record);
                    setAddChannelModalOpen(true);
                  }}
                />
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${record?.name}?`}
                onConfirm={() => deleteChannelFun(record?.id as string)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ]?.filter((item) => {
    if (
      userRole === USERS_ROLE?.READ_ONLY &&
      (item?.dataIndex === 'actions' || item?.dataIndex === 'send_mail')
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyChannelList ? '' : <span />,
  };

  return (
    <>
      <AddChannel
        initialValues={channelDetails}
        isModalOpen={isAddChannelModalOpen}
        setIsModalOpen={setAddChannelModalOpen}
        formTitle={channelDetails ? 'Edit Channel' : 'Add Channel'}
        onFinish={channelDetails ? updateChannelFun : createChannelFun}
        formItems={AddChannelFormsItems}
        submitButton={channelDetails ? 'Update' : 'Add'}
        loadings={isCreateChannelBtnLoading}
        form={form}
        handleReset={handleAddChannelReset}
      />
      <SendEmailModal
        isModalOpen={isSendMailModalOpen}
        setIsModalOpen={setIsSendMailModalOpen}
        formTitle="Send Email"
        submitButton="Send Email"
        channelId={channelId}
        setIsMailSend={setIsMailSend}
      />
      <ViewMembersModal
        isModalOpen={isViewMembersModalOpen}
        setIsModalOpen={setIsViewMembersModalOpen}
        channelId={channelId}
      />
      <div className="channels">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            {(channelList && channelList?.length > 0) ||
            channelSearchTerm ||
            isChannelSearchLoading ||
            isAllowClear ? (
              <SearchWithLoading
                setIsAllowClear={setIsAllowClear}
                query={channelSearchTerm}
                setQuery={setChannelSearchTerm}
                getData={handleSearch}
              />
            ) : null}
          </div>
          {(channelList && channelList?.length > 0) ||
          channelSearchTerm ||
          isChannelSearchLoading ||
          isAllowClear ? (
            <>
              {(userRole === USERS_ROLE?.OWNER ||
                userRole === USERS_ROLE?.WRITE) && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="primary-button"
                  onClick={() => setAddChannelModalOpen(true)}
                >
                  Create New Channel
                </Button>
              )}
            </>
          ) : null}
        </div>
        <div className="mt-30 flex-vertical">
          {isChannelLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isChannelLoading}
            />
          ) : (
            <>
              {(channelList && channelList?.length > 0) ||
              channelSearchTerm ||
              isChannelSearchLoading ||
              isAllowClear ? (
                <CommonTable
                  locale={locale}
                  columns={columns}
                  data={
                    channelList?.filter(
                      (channel): channel is Channel => !!channel,
                    ) || []
                  }
                  loadingData={isChannelLoading}
                  onChange={(
                    pagination: TablePaginationConfig,
                    filter: Record<string, FilterValue | null>,
                    sorter: SorterResult<Channel>,
                  ) =>
                    handleTableChange(
                      pagination,
                      filter,
                      sorter as SorterResult<Channel>,
                    )
                  }
                  paginationConfig={paginationProp}
                  rowKey="id"
                />
              ) : (
                <div className="analytics-illustration">
                  <Nodata />
                  <br />
                  {(userRole === USERS_ROLE?.OWNER ||
                    userRole === USERS_ROLE?.WRITE) && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="primary-button"
                      onClick={() => setAddChannelModalOpen(true)}
                    >
                      Create New Channel
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChannelComponent;
