import { gql } from '../../../__generated__';

export const GET_LOGS = gql(`
  query messagesLogs($filter: MessageLogsFilter!) {
    messagesLogs(filter: $filter) {
      count
      messages {
        id
        projectEnvId
        messageDetails
        status
        type
        sender
        recipient
        isScheduled
        isRead
        scheduledTime
        updatedAt
      }
    }
  }
`);
