import { gql } from '../../../__generated__';

export const CREATE_PROJECT_MEMBER = gql(`
  mutation createProjectMember($data: CreateProjectMemberData!) {
    createProjectMember(data: $data) {
      status
      message
    }
  }
`);

export const UPDATE_PROJECT_MEMBER = gql(`
  mutation updateProjectMember($data: UpdatedProjectMemberData!) {
    updateProjectMember(data: $data) {
      data {
        id
        email
        permission
      }
      message
    }
  }
`);

export const DELETE_PROJECT_MEMBER = gql(`
  mutation deleteProjectMember($id: ID!) {
    deleteProjectMember(id: $id) {
      status
      message
    }
  }
`);

export const TRANSFER_OWNERSHIP = gql(`
  mutation TransferOwnership($input: TransferOwnershipInput!) {
    transferOwnership(input: $input) {
      message
    }
  }
`);
