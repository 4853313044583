import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddProject from './components/AddProject';
import EditProject from './components/EditProject';
import Projects from './Projects';
import './projects.less';
import ProjectsDashboard from './ProjectsDashboard';

const ProjectsWrapper = () => {
  return (
    <Routes>
      <Route path={ROUTES?.MAIN} element={<Projects />} />
      <Route path={ROUTES?.ADDPROJECT} element={<AddProject />} />
      <Route path={`/:projectId`} element={<ProjectsDashboard />} />
      <Route
        path={`/:projectId${ROUTES.EDIT_PROJECT}`}
        element={<EditProject />}
      />
      <Route path={`/:projectId/:key/:id`} element={<ProjectsDashboard />} />
      <Route path={`/:projectId/:key`} element={<ProjectsDashboard />} />
    </Routes>
  );
};

export default ProjectsWrapper;
