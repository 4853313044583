import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce, DebouncedFunc } from 'lodash';

// eslint-disable-next-line no-unused-vars
let searchDebounce: DebouncedFunc<(value: string) => void> | null = null;

const SearchWithLoading = (props: {
  name?: string;
  // eslint-disable-next-line no-unused-vars
  getData: (value: string) => void;
  query: string;
  // eslint-disable-next-line no-unused-vars
  setQuery: (value: string) => void;
  // eslint-disable-next-line no-unused-vars
  setIsAllowClear: (value: boolean) => void;
}) => {
  const {
    name = '',
    getData,
    query,
    setQuery,
    setIsAllowClear,
    ...rest
  } = props;

  const handleChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setIsAllowClear(true);
    setQuery(value);
    if (getData) {
      if (searchDebounce) {
        searchDebounce?.cancel();
        searchDebounce = null;
      }
      searchDebounce = debounce(getData, 500);
      searchDebounce(value);
    }
  };

  return (
    <Input
      className="search-input"
      allowClear
      placeholder={`Search ${name}`}
      value={query}
      name={name}
      onChange={handleChange}
      prefix={<SearchOutlined />}
      {...rest}
    />
  );
};

export default SearchWithLoading;
