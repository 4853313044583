import EmailTabs from './components/EmailTabs';

type EmailPropType = {
  // eslint-disable-next-line no-unused-vars
  setActiveKey: (value: string | ((prevState: string) => string)) => void;
  activeKey: string;
};
const Email = ({ setActiveKey, activeKey }: EmailPropType) => {
  return (
    <>
      <h1 className="title ml-30 mt-20">E-mail</h1>
      <EmailTabs setActiveKey={setActiveKey} activeKey={activeKey} />
    </>
  );
};

export default Email;
