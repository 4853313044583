import { Line } from '@ant-design/plots';
import { GraphDataType } from '../types/common.type';

const LineChart = ({ data }: { data: GraphDataType[] }) => {
  const config = {
    data,
    xField: 'label',
    yField: 'count',
    seriesField: 'status',
    legend: {
      position: 'top' as const,
      itemName: {
        style: { marginTop: '30px' },
      },
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  return <Line {...config} className="line-chart" />;
};
export default LineChart;
