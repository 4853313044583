/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation createProjectEnvironment($data: CreateProjectEnvironmentInput!) {\n    createProjectEnvironment(data: $data) {\n      data {\n        id\n        environment\n        projectId\n      }\n      message\n      status\n    }\n  }\n": types.CreateProjectEnvironmentDocument,
    "\n  query projectEnvironment($id: ID!) {\n    projectEnvironment(projectId: $id) {\n      environments {\n        id\n        environment\n      }\n    }\n  }\n": types.ProjectEnvironmentDocument,
    "\n  mutation getSignedUrl($data: UploadData!) {\n    getSignedUrl(data: $data) {\n      signedRequest\n      url\n    }\n  }\n": types.GetSignedUrlDocument,
    "\n  query getAnalytics($filter: GetMessageAnalyticsFilter) {\n    getMessageAnalytics(filter: $filter) {\n      data {\n        FAILED {\n          count\n          label\n        }\n        READ {\n          count\n          label\n        }\n        SUCCESS {\n          count\n          label\n        }\n        UNREAD {\n          count\n          label\n        }\n        stats {\n          failedEmailPercentage\n          readEmailPercentage\n          successEmailPercentage\n          totalEmails\n          totalFailedEmails\n          totalReadEmails\n          totalSuccessEmails\n          totalUnreadEmails\n          unreadEmailPercentage\n        }\n      }\n      status\n    }\n  }\n": types.GetAnalyticsDocument,
    "\n  mutation sendOtp($email: String!) {\n    sendOtp(email: $email) {\n      message\n    }\n  }\n": types.SendOtpDocument,
    "\n  mutation verifyOtp($data: loginData!) {\n    verifyOtp(data: $data) {\n      data {\n        id\n        firstName\n        lastName\n        email\n        profileImage\n        message\n      }\n      token\n      refreshToken\n    }\n  }\n": types.VerifyOtpDocument,
    "\n  mutation logout {\n    logout {\n      message\n    }\n  }\n": types.LogoutDocument,
    "\n  mutation refreshToken($token: String!) {\n    refreshToken(token: $token) {\n      token\n    }\n  }\n": types.RefreshTokenDocument,
    "\n  query User {\n    user {\n      createdAt\n      email\n      firstName\n      id\n      lastName\n      profileImage\n      message\n      refreshToken\n    }\n  }\n": types.UserDocument,
    "\n  query projectEnvAuthDetails($id: ID!) {\n    projectEnvAuthDetails(id: $id) {\n      apiKey\n      secretKey\n    }\n  }\n": types.ProjectEnvAuthDetailsDocument,
    "\n  mutation createChannel($data: CreateChannelData!) {\n    createChannel(data: $data) {\n      message\n    }\n  }\n": types.CreateChannelDocument,
    "\n  mutation updateChannel($id: ID!, $data: UpdateChannelData!) {\n    updateChannel(id: $id, data: $data) {\n      status\n      message\n    }\n  }\n": types.UpdateChannelDocument,
    "\n  mutation deleteChannel($id: ID!) {\n    deleteChannel(id: $id) {\n      status\n      message\n    }\n  }\n": types.DeleteChannelDocument,
    "\n  mutation sendMailInChannel($channelId: ID!, $data: SendMailInChannelData!) {\n    sendMailInChannel(channelId: $channelId, data: $data) {\n      status\n      message\n    }\n  }\n": types.SendMailInChannelDocument,
    "\n  mutation addMemberInChannel($data: AddMemberData!, $channelId: ID!) {\n    addMemberInChannel(data: $data, channelId: $channelId) {\n      status\n      message\n    }\n  }\n": types.AddMemberInChannelDocument,
    "\n  mutation removeMemberFromChannel($channelMemberId: ID!, $channelId: ID!) {\n    removeMemberFromChannel(\n      channelMemberId: $channelMemberId\n      channelId: $channelId\n    ) {\n      status\n      message\n    }\n  }\n": types.RemoveMemberFromChannelDocument,
    "\n  query channels($filter: ChannelFilter!) {\n    channels(filter: $filter) {\n      count\n      channels {\n        id\n        name\n        projectEnvId\n        createdBy\n        createdAt\n      }\n    }\n  }\n": types.ChannelsDocument,
    "\n  query channelEmailUsers($filter: ChannelMemberFilter!, $channelId: ID!) {\n    channelEmailUsers(filter: $filter, channelId: $channelId) {\n      count\n      data {\n        id\n        email\n        createdAt\n      }\n    }\n  }\n": types.ChannelEmailUsersDocument,
    "\n  mutation sendRawEmail($data: SendRawEmailData!, $projectEnvId: ID!) {\n    sendRawEmail(data: $data, projectEnvId: $projectEnvId) {\n      message\n      status\n    }\n  }\n": types.SendRawEmailDocument,
    "\n  mutation ResendEmail($where: ResendEmailWhere!, $projectEnvId: ID!) {\n    resendEmail(where: $where, projectEnvId: $projectEnvId) {\n      message\n      status\n    }\n  }\n": types.ResendEmailDocument,
    "\n  query messagesLogs($filter: MessageLogsFilter!) {\n    messagesLogs(filter: $filter) {\n      count\n      messages {\n        id\n        projectEnvId\n        messageDetails\n        status\n        type\n        sender\n        recipient\n        isScheduled\n        isRead\n        scheduledTime\n        updatedAt\n      }\n    }\n  }\n": types.MessagesLogsDocument,
    "\n  mutation createProjectMember($data: CreateProjectMemberData!) {\n    createProjectMember(data: $data) {\n      status\n      message\n    }\n  }\n": types.CreateProjectMemberDocument,
    "\n  mutation updateProjectMember($data: UpdatedProjectMemberData!) {\n    updateProjectMember(data: $data) {\n      data {\n        id\n        email\n        permission\n      }\n      message\n    }\n  }\n": types.UpdateProjectMemberDocument,
    "\n  mutation deleteProjectMember($id: ID!) {\n    deleteProjectMember(id: $id) {\n      status\n      message\n    }\n  }\n": types.DeleteProjectMemberDocument,
    "\n  mutation TransferOwnership($input: TransferOwnershipInput!) {\n    transferOwnership(input: $input) {\n      message\n    }\n  }\n": types.TransferOwnershipDocument,
    "\n  query projectMembers(\n    $id: ID\n    $projectEnvId: ID!\n    $filter: ProjectMemberFilter!\n  ) {\n    projectMembers(id: $id, projectEnvId: $projectEnvId, filter: $filter) {\n      projectMember {\n        id\n        email\n        inviteStatus\n        permission\n      }\n    }\n  }\n": types.ProjectMembersDocument,
    "\n  mutation updateProject($data: ProjectData!, $id: ID!) {\n    updateProject(data: $data, id: $id) {\n      message\n    }\n  }\n": types.UpdateProjectDocument,
    "\n  mutation createProject($data: ProjectData!) {\n    createProject(data: $data) {\n      message\n    }\n  }\n": types.CreateProjectDocument,
    "\n  mutation DeleteProject($id: ID!) {\n    deleteProject(id: $id) {\n      message\n      status\n    }\n  }\n": types.DeleteProjectDocument,
    "\n  mutation addProjectEnvironment($data: CreateProjectEnvironmentInput!) {\n    createProjectEnvironment(data: $data) {\n      data {\n        id\n        environment\n        projectId\n        createdBy\n      }\n      status\n      message\n    }\n  }\n": types.AddProjectEnvironmentDocument,
    "\n  mutation deleteProjectEnvironment($id: ID!) {\n    deleteProjectEnvironment(id: $id) {\n      data {\n        id\n        environment\n        projectId\n        createdBy\n      }\n      status\n      message\n    }\n  }\n": types.DeleteProjectEnvironmentDocument,
    "\n  query project($id: ID!) {\n    project(id: $id) {\n      id\n      name\n      description\n      logo\n      projectsMember {\n        id\n        email\n      }\n    }\n  }\n": types.ProjectDocument,
    "\n  query projects($filter: ProjectFilter!) {\n    projects(filter: $filter) {\n      projects {\n        id\n        name\n        description\n        logo\n        createdBy\n      }\n    }\n  }\n": types.ProjectsDocument,
    "\n  query getProjectEnvironment($projectId: ID!) {\n    projectEnvironment(projectId: $projectId) {\n      count\n      environments {\n        id\n        environment\n        projectId\n        createdBy\n        permission\n      }\n    }\n  }\n": types.GetProjectEnvironmentDocument,
    "\n  mutation createSender($data: SenderDataCreate!) {\n    createSender(data: $data) {\n      status\n      message\n    }\n  }\n": types.CreateSenderDocument,
    "\n  mutation updateSender($id: ID!, $data: SenderDataUpdate!) {\n    updateSender(id: $id, data: $data) {\n      data {\n        email\n      }\n    }\n  }\n": types.UpdateSenderDocument,
    "\n  mutation deleteSender($id: ID!) {\n    deleteSender(id: $id) {\n      status\n      message\n    }\n  }\n": types.DeleteSenderDocument,
    "\n  mutation verifySender($id: ID!, $token: String, $reVerify: Boolean!) {\n    verifySender(id: $id, token: $token, reVerify: $reVerify) {\n      message\n      status\n    }\n  }\n": types.VerifySenderDocument,
    "\n  query senders(\n    $projectEnvId: ID!\n    $type: ProviderType!\n    $filter: SenderFilter!\n  ) {\n    senders(projectEnvId: $projectEnvId, type: $type, filter: $filter) {\n      count\n      senders {\n        id\n        email\n        name\n        phoneNo\n        isVerified\n        createdAt\n      }\n    }\n  }\n": types.SendersDocument,
    "\n  mutation createPlatform($data: PlatformData!) {\n    createPlatform(data: $data) {\n      message\n      status\n      data {\n        id\n        name\n      }\n    }\n  }\n": types.CreatePlatformDocument,
    "\n  mutation updatePlatform($data: PlatformUpdateData!, $id: ID!) {\n    updatePlatform(data: $data, id: $id) {\n      message\n      status\n      data {\n        id\n        name\n        platformConfigs\n      }\n    }\n  }\n": types.UpdatePlatformDocument,
    "\n  mutation deletePlatform($id: ID!) {\n    deletePlatform(id: $id) {\n      status\n      message\n    }\n  }\n": types.DeletePlatformDocument,
    "\n  query platforms($projectEnvId: ID!, $filter: PlatformFilter!) {\n    platforms(projectEnvId: $projectEnvId, filter: $filter) {\n      platforms {\n        id\n        name\n        projectEnvId\n        createdAt\n        platformConfigs\n        provider {\n          id\n          slug\n          name\n          providerConfigs\n        }\n      }\n    }\n  }\n": types.PlatformsDocument,
    "\n  query providers($filter: ProviderFilter!) {\n    providers(filter: $filter) {\n      providers {\n        id\n        name\n        type\n        providerConfigs\n        slug\n      }\n    }\n  }\n": types.ProvidersDocument,
    "\n  query platform($id: ID!) {\n    platform(id: $id) {\n      id\n      name\n      projectEnvId\n      platformConfigs\n      createdAt\n      updatedAt\n    }\n  }\n": types.PlatformDocument,
    "\n  query provider($id: ID!) {\n    provider(id: $id) {\n      id\n      name\n      providerConfigs\n      type\n      slug\n    }\n  }\n": types.ProviderDocument,
    "\n  query getPlatformConfigs($id: ID!, $type: PlatformType!) {\n    getPlatformConfigs(id: $id, type: $type) {\n      platformConfigs\n      status\n    }\n  }\n": types.GetPlatformConfigsDocument,
    "\n  mutation createTemplate($data: TemplateData!) {\n    createTemplate(data: $data) {\n      status\n      message\n    }\n  }\n": types.CreateTemplateDocument,
    "\n  mutation updateTemplate($data: TemplateUpdateData!, $id: ID!) {\n    updateTemplate(data: $data, id: $id) {\n      message\n      status\n    }\n  }\n": types.UpdateTemplateDocument,
    "\n  mutation deleteTemplate($id: ID!) {\n    deleteTemplate(id: $id) {\n      status\n      message\n    }\n  }\n": types.DeleteTemplateDocument,
    "\n  mutation CopyTemplates($data: CopyTemplatesData!) {\n    copyTemplates(data: $data) {\n      message\n    }\n  }\n": types.CopyTemplatesDocument,
    "\n  query templates($filter: TemplateFilter!) {\n    templates(filter: $filter) {\n      count\n      templates {\n        id\n        key\n        title\n        subject\n        body\n        design\n      }\n    }\n  }\n": types.TemplatesDocument,
    "\n  query template($id: ID!) {\n    template(id: $id) {\n      id\n      key\n      title\n      subject\n      body\n      design\n    }\n  }\n": types.TemplateDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createProjectEnvironment($data: CreateProjectEnvironmentInput!) {\n    createProjectEnvironment(data: $data) {\n      data {\n        id\n        environment\n        projectId\n      }\n      message\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation createProjectEnvironment($data: CreateProjectEnvironmentInput!) {\n    createProjectEnvironment(data: $data) {\n      data {\n        id\n        environment\n        projectId\n      }\n      message\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query projectEnvironment($id: ID!) {\n    projectEnvironment(projectId: $id) {\n      environments {\n        id\n        environment\n      }\n    }\n  }\n"): (typeof documents)["\n  query projectEnvironment($id: ID!) {\n    projectEnvironment(projectId: $id) {\n      environments {\n        id\n        environment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation getSignedUrl($data: UploadData!) {\n    getSignedUrl(data: $data) {\n      signedRequest\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation getSignedUrl($data: UploadData!) {\n    getSignedUrl(data: $data) {\n      signedRequest\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAnalytics($filter: GetMessageAnalyticsFilter) {\n    getMessageAnalytics(filter: $filter) {\n      data {\n        FAILED {\n          count\n          label\n        }\n        READ {\n          count\n          label\n        }\n        SUCCESS {\n          count\n          label\n        }\n        UNREAD {\n          count\n          label\n        }\n        stats {\n          failedEmailPercentage\n          readEmailPercentage\n          successEmailPercentage\n          totalEmails\n          totalFailedEmails\n          totalReadEmails\n          totalSuccessEmails\n          totalUnreadEmails\n          unreadEmailPercentage\n        }\n      }\n      status\n    }\n  }\n"): (typeof documents)["\n  query getAnalytics($filter: GetMessageAnalyticsFilter) {\n    getMessageAnalytics(filter: $filter) {\n      data {\n        FAILED {\n          count\n          label\n        }\n        READ {\n          count\n          label\n        }\n        SUCCESS {\n          count\n          label\n        }\n        UNREAD {\n          count\n          label\n        }\n        stats {\n          failedEmailPercentage\n          readEmailPercentage\n          successEmailPercentage\n          totalEmails\n          totalFailedEmails\n          totalReadEmails\n          totalSuccessEmails\n          totalUnreadEmails\n          unreadEmailPercentage\n        }\n      }\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation sendOtp($email: String!) {\n    sendOtp(email: $email) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation sendOtp($email: String!) {\n    sendOtp(email: $email) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation verifyOtp($data: loginData!) {\n    verifyOtp(data: $data) {\n      data {\n        id\n        firstName\n        lastName\n        email\n        profileImage\n        message\n      }\n      token\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation verifyOtp($data: loginData!) {\n    verifyOtp(data: $data) {\n      data {\n        id\n        firstName\n        lastName\n        email\n        profileImage\n        message\n      }\n      token\n      refreshToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation logout {\n    logout {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation logout {\n    logout {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation refreshToken($token: String!) {\n    refreshToken(token: $token) {\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation refreshToken($token: String!) {\n    refreshToken(token: $token) {\n      token\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query User {\n    user {\n      createdAt\n      email\n      firstName\n      id\n      lastName\n      profileImage\n      message\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  query User {\n    user {\n      createdAt\n      email\n      firstName\n      id\n      lastName\n      profileImage\n      message\n      refreshToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query projectEnvAuthDetails($id: ID!) {\n    projectEnvAuthDetails(id: $id) {\n      apiKey\n      secretKey\n    }\n  }\n"): (typeof documents)["\n  query projectEnvAuthDetails($id: ID!) {\n    projectEnvAuthDetails(id: $id) {\n      apiKey\n      secretKey\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createChannel($data: CreateChannelData!) {\n    createChannel(data: $data) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation createChannel($data: CreateChannelData!) {\n    createChannel(data: $data) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateChannel($id: ID!, $data: UpdateChannelData!) {\n    updateChannel(id: $id, data: $data) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation updateChannel($id: ID!, $data: UpdateChannelData!) {\n    updateChannel(id: $id, data: $data) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteChannel($id: ID!) {\n    deleteChannel(id: $id) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deleteChannel($id: ID!) {\n    deleteChannel(id: $id) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation sendMailInChannel($channelId: ID!, $data: SendMailInChannelData!) {\n    sendMailInChannel(channelId: $channelId, data: $data) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation sendMailInChannel($channelId: ID!, $data: SendMailInChannelData!) {\n    sendMailInChannel(channelId: $channelId, data: $data) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addMemberInChannel($data: AddMemberData!, $channelId: ID!) {\n    addMemberInChannel(data: $data, channelId: $channelId) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation addMemberInChannel($data: AddMemberData!, $channelId: ID!) {\n    addMemberInChannel(data: $data, channelId: $channelId) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removeMemberFromChannel($channelMemberId: ID!, $channelId: ID!) {\n    removeMemberFromChannel(\n      channelMemberId: $channelMemberId\n      channelId: $channelId\n    ) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation removeMemberFromChannel($channelMemberId: ID!, $channelId: ID!) {\n    removeMemberFromChannel(\n      channelMemberId: $channelMemberId\n      channelId: $channelId\n    ) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query channels($filter: ChannelFilter!) {\n    channels(filter: $filter) {\n      count\n      channels {\n        id\n        name\n        projectEnvId\n        createdBy\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query channels($filter: ChannelFilter!) {\n    channels(filter: $filter) {\n      count\n      channels {\n        id\n        name\n        projectEnvId\n        createdBy\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query channelEmailUsers($filter: ChannelMemberFilter!, $channelId: ID!) {\n    channelEmailUsers(filter: $filter, channelId: $channelId) {\n      count\n      data {\n        id\n        email\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query channelEmailUsers($filter: ChannelMemberFilter!, $channelId: ID!) {\n    channelEmailUsers(filter: $filter, channelId: $channelId) {\n      count\n      data {\n        id\n        email\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation sendRawEmail($data: SendRawEmailData!, $projectEnvId: ID!) {\n    sendRawEmail(data: $data, projectEnvId: $projectEnvId) {\n      message\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation sendRawEmail($data: SendRawEmailData!, $projectEnvId: ID!) {\n    sendRawEmail(data: $data, projectEnvId: $projectEnvId) {\n      message\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResendEmail($where: ResendEmailWhere!, $projectEnvId: ID!) {\n    resendEmail(where: $where, projectEnvId: $projectEnvId) {\n      message\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation ResendEmail($where: ResendEmailWhere!, $projectEnvId: ID!) {\n    resendEmail(where: $where, projectEnvId: $projectEnvId) {\n      message\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query messagesLogs($filter: MessageLogsFilter!) {\n    messagesLogs(filter: $filter) {\n      count\n      messages {\n        id\n        projectEnvId\n        messageDetails\n        status\n        type\n        sender\n        recipient\n        isScheduled\n        isRead\n        scheduledTime\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query messagesLogs($filter: MessageLogsFilter!) {\n    messagesLogs(filter: $filter) {\n      count\n      messages {\n        id\n        projectEnvId\n        messageDetails\n        status\n        type\n        sender\n        recipient\n        isScheduled\n        isRead\n        scheduledTime\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createProjectMember($data: CreateProjectMemberData!) {\n    createProjectMember(data: $data) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation createProjectMember($data: CreateProjectMemberData!) {\n    createProjectMember(data: $data) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateProjectMember($data: UpdatedProjectMemberData!) {\n    updateProjectMember(data: $data) {\n      data {\n        id\n        email\n        permission\n      }\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation updateProjectMember($data: UpdatedProjectMemberData!) {\n    updateProjectMember(data: $data) {\n      data {\n        id\n        email\n        permission\n      }\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteProjectMember($id: ID!) {\n    deleteProjectMember(id: $id) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deleteProjectMember($id: ID!) {\n    deleteProjectMember(id: $id) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation TransferOwnership($input: TransferOwnershipInput!) {\n    transferOwnership(input: $input) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation TransferOwnership($input: TransferOwnershipInput!) {\n    transferOwnership(input: $input) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query projectMembers(\n    $id: ID\n    $projectEnvId: ID!\n    $filter: ProjectMemberFilter!\n  ) {\n    projectMembers(id: $id, projectEnvId: $projectEnvId, filter: $filter) {\n      projectMember {\n        id\n        email\n        inviteStatus\n        permission\n      }\n    }\n  }\n"): (typeof documents)["\n  query projectMembers(\n    $id: ID\n    $projectEnvId: ID!\n    $filter: ProjectMemberFilter!\n  ) {\n    projectMembers(id: $id, projectEnvId: $projectEnvId, filter: $filter) {\n      projectMember {\n        id\n        email\n        inviteStatus\n        permission\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateProject($data: ProjectData!, $id: ID!) {\n    updateProject(data: $data, id: $id) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation updateProject($data: ProjectData!, $id: ID!) {\n    updateProject(data: $data, id: $id) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createProject($data: ProjectData!) {\n    createProject(data: $data) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation createProject($data: ProjectData!) {\n    createProject(data: $data) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteProject($id: ID!) {\n    deleteProject(id: $id) {\n      message\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteProject($id: ID!) {\n    deleteProject(id: $id) {\n      message\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addProjectEnvironment($data: CreateProjectEnvironmentInput!) {\n    createProjectEnvironment(data: $data) {\n      data {\n        id\n        environment\n        projectId\n        createdBy\n      }\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation addProjectEnvironment($data: CreateProjectEnvironmentInput!) {\n    createProjectEnvironment(data: $data) {\n      data {\n        id\n        environment\n        projectId\n        createdBy\n      }\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteProjectEnvironment($id: ID!) {\n    deleteProjectEnvironment(id: $id) {\n      data {\n        id\n        environment\n        projectId\n        createdBy\n      }\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deleteProjectEnvironment($id: ID!) {\n    deleteProjectEnvironment(id: $id) {\n      data {\n        id\n        environment\n        projectId\n        createdBy\n      }\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query project($id: ID!) {\n    project(id: $id) {\n      id\n      name\n      description\n      logo\n      projectsMember {\n        id\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query project($id: ID!) {\n    project(id: $id) {\n      id\n      name\n      description\n      logo\n      projectsMember {\n        id\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query projects($filter: ProjectFilter!) {\n    projects(filter: $filter) {\n      projects {\n        id\n        name\n        description\n        logo\n        createdBy\n      }\n    }\n  }\n"): (typeof documents)["\n  query projects($filter: ProjectFilter!) {\n    projects(filter: $filter) {\n      projects {\n        id\n        name\n        description\n        logo\n        createdBy\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProjectEnvironment($projectId: ID!) {\n    projectEnvironment(projectId: $projectId) {\n      count\n      environments {\n        id\n        environment\n        projectId\n        createdBy\n        permission\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProjectEnvironment($projectId: ID!) {\n    projectEnvironment(projectId: $projectId) {\n      count\n      environments {\n        id\n        environment\n        projectId\n        createdBy\n        permission\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createSender($data: SenderDataCreate!) {\n    createSender(data: $data) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation createSender($data: SenderDataCreate!) {\n    createSender(data: $data) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateSender($id: ID!, $data: SenderDataUpdate!) {\n    updateSender(id: $id, data: $data) {\n      data {\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateSender($id: ID!, $data: SenderDataUpdate!) {\n    updateSender(id: $id, data: $data) {\n      data {\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteSender($id: ID!) {\n    deleteSender(id: $id) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deleteSender($id: ID!) {\n    deleteSender(id: $id) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation verifySender($id: ID!, $token: String, $reVerify: Boolean!) {\n    verifySender(id: $id, token: $token, reVerify: $reVerify) {\n      message\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation verifySender($id: ID!, $token: String, $reVerify: Boolean!) {\n    verifySender(id: $id, token: $token, reVerify: $reVerify) {\n      message\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query senders(\n    $projectEnvId: ID!\n    $type: ProviderType!\n    $filter: SenderFilter!\n  ) {\n    senders(projectEnvId: $projectEnvId, type: $type, filter: $filter) {\n      count\n      senders {\n        id\n        email\n        name\n        phoneNo\n        isVerified\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query senders(\n    $projectEnvId: ID!\n    $type: ProviderType!\n    $filter: SenderFilter!\n  ) {\n    senders(projectEnvId: $projectEnvId, type: $type, filter: $filter) {\n      count\n      senders {\n        id\n        email\n        name\n        phoneNo\n        isVerified\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createPlatform($data: PlatformData!) {\n    createPlatform(data: $data) {\n      message\n      status\n      data {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createPlatform($data: PlatformData!) {\n    createPlatform(data: $data) {\n      message\n      status\n      data {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updatePlatform($data: PlatformUpdateData!, $id: ID!) {\n    updatePlatform(data: $data, id: $id) {\n      message\n      status\n      data {\n        id\n        name\n        platformConfigs\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updatePlatform($data: PlatformUpdateData!, $id: ID!) {\n    updatePlatform(data: $data, id: $id) {\n      message\n      status\n      data {\n        id\n        name\n        platformConfigs\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deletePlatform($id: ID!) {\n    deletePlatform(id: $id) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deletePlatform($id: ID!) {\n    deletePlatform(id: $id) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query platforms($projectEnvId: ID!, $filter: PlatformFilter!) {\n    platforms(projectEnvId: $projectEnvId, filter: $filter) {\n      platforms {\n        id\n        name\n        projectEnvId\n        createdAt\n        platformConfigs\n        provider {\n          id\n          slug\n          name\n          providerConfigs\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query platforms($projectEnvId: ID!, $filter: PlatformFilter!) {\n    platforms(projectEnvId: $projectEnvId, filter: $filter) {\n      platforms {\n        id\n        name\n        projectEnvId\n        createdAt\n        platformConfigs\n        provider {\n          id\n          slug\n          name\n          providerConfigs\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query providers($filter: ProviderFilter!) {\n    providers(filter: $filter) {\n      providers {\n        id\n        name\n        type\n        providerConfigs\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  query providers($filter: ProviderFilter!) {\n    providers(filter: $filter) {\n      providers {\n        id\n        name\n        type\n        providerConfigs\n        slug\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query platform($id: ID!) {\n    platform(id: $id) {\n      id\n      name\n      projectEnvId\n      platformConfigs\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query platform($id: ID!) {\n    platform(id: $id) {\n      id\n      name\n      projectEnvId\n      platformConfigs\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query provider($id: ID!) {\n    provider(id: $id) {\n      id\n      name\n      providerConfigs\n      type\n      slug\n    }\n  }\n"): (typeof documents)["\n  query provider($id: ID!) {\n    provider(id: $id) {\n      id\n      name\n      providerConfigs\n      type\n      slug\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getPlatformConfigs($id: ID!, $type: PlatformType!) {\n    getPlatformConfigs(id: $id, type: $type) {\n      platformConfigs\n      status\n    }\n  }\n"): (typeof documents)["\n  query getPlatformConfigs($id: ID!, $type: PlatformType!) {\n    getPlatformConfigs(id: $id, type: $type) {\n      platformConfigs\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createTemplate($data: TemplateData!) {\n    createTemplate(data: $data) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation createTemplate($data: TemplateData!) {\n    createTemplate(data: $data) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateTemplate($data: TemplateUpdateData!, $id: ID!) {\n    updateTemplate(data: $data, id: $id) {\n      message\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation updateTemplate($data: TemplateUpdateData!, $id: ID!) {\n    updateTemplate(data: $data, id: $id) {\n      message\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteTemplate($id: ID!) {\n    deleteTemplate(id: $id) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deleteTemplate($id: ID!) {\n    deleteTemplate(id: $id) {\n      status\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CopyTemplates($data: CopyTemplatesData!) {\n    copyTemplates(data: $data) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation CopyTemplates($data: CopyTemplatesData!) {\n    copyTemplates(data: $data) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query templates($filter: TemplateFilter!) {\n    templates(filter: $filter) {\n      count\n      templates {\n        id\n        key\n        title\n        subject\n        body\n        design\n      }\n    }\n  }\n"): (typeof documents)["\n  query templates($filter: TemplateFilter!) {\n    templates(filter: $filter) {\n      count\n      templates {\n        id\n        key\n        title\n        subject\n        body\n        design\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query template($id: ID!) {\n    template(id: $id) {\n      id\n      key\n      title\n      subject\n      body\n      design\n    }\n  }\n"): (typeof documents)["\n  query template($id: ID!) {\n    template(id: $id) {\n      id\n      key\n      title\n      subject\n      body\n      design\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;