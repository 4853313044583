import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { LOGOUT_USER } from './graphql/Mutations';

const Logout = () => {
  const { dispatch } = useContext(AppContext) as AppContextType;
  const [logout] = useMutation(LOGOUT_USER);

  useEffect(() => {
    const performLogout = async () => {
      try {
        await logout();
        dispatch({ type: AppActionType.logout });
        // eslint-disable-next-line no-undef
        window.location.href = '/login';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const newErrors = error?.graphQLErrors?.[0];
        // eslint-disable-next-line no-console
        console.log('newErrors => ', newErrors);
        dispatch({ type: AppActionType.logout });
        // eslint-disable-next-line no-undef
        window.location.href = '/login';
      }
    };
    performLogout();
  }, [dispatch, logout]);

  return <LoaderComponent />;
};

export default Logout;
