import ProjectList from './components/ProjectList';
import './projects.less';

const Projects = () => {
  return (
    <>
      <ProjectList defaultActiveKey="projects" isFirstTab />
    </>
  );
};

export default Projects;
