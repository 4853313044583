import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Dashboard from './Dashboard';

const DashboardWrapper = () => {
  return (
    <Routes>
      <Route path={ROUTES?.MAIN} element={<Dashboard />} />
    </Routes>
  );
};

export default DashboardWrapper;
