/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import { Permission, User } from '../gql/graphql';
import { FormDataType } from './common.type';

export type AppState = {
  currentUser: { email: string } | User | null;
  authenticated: boolean;
  authToken: string | null;
  currentRole?: Permission | null;
  refreshToken?: string | null;
  windowWidth: number | null;
  formData: FormDataType | null;
  projectEnvId: string | null;
  designHtml: string | null;
  createNewProject: boolean | null;
};

export enum AppActionType {
  setCurrentUser = 'SET_CURRENT_USER',
  setCurrentRole = 'SET_CURRENT_ROLE',
  logout = 'LOGOUT',
  setAuthenticated = 'SET_AUTHENTICATED',
  setAuthToken = 'SET_TOKEN',
  setFormData = 'SET_FORM_DATA',
  setProjectEnvId = 'SET_PROJECT_ENV_ID',
  createNewProject = 'CREATE_NEW_PROJECT',
  setRefreshToken = 'SET_REFRESH_TOKEN',
}

export type AppAction =
  | { type: AppActionType.setCurrentUser; data: User | { email: string } }
  | { type: AppActionType.setAuthenticated; data: boolean }
  | { type: AppActionType.setAuthToken; data: string | null }
  | { type: AppActionType.setRefreshToken; data: string | null }
  | { type: AppActionType.setCurrentRole; data: Permission | null }
  | { type: AppActionType.setProjectEnvId; data: string | null }
  | { type: AppActionType.createNewProject; data: boolean | null }
  | { type: AppActionType.setFormData; data: FormDataType | null }
  | { type: AppActionType.logout };

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
  getToken: () => string | null;
  isAuthenticated: () => boolean;
  getRefreshToken: () => string | null;
  initializeAuth: (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
  ) => void;

  getCurrentUser: () => User | null;

  getFormData: () => FormDataType;
  getCurrentRole: () => Permission | null;
};
