import { useLazyQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { User } from '../../gql/graphql';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { REFRESH_TOKEN } from './graphql/Mutations';
import { GET_PROFILE } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(
    AppContext,
  ) as AppContextType;
  const { navigate } = useRouter();
  const refreshToken = getRefreshToken();

  const [getProfile, { loading: profileLoading }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',

    onError() {
      navigate(ROUTES?.LOGOUT);
    },
  });

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    async onCompleted(response) {
      const accessToken = response?.refreshToken?.token;
      const res = await getProfile({
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      const userData: User | undefined | null = res?.data?.user;
      if (accessToken && userData) {
        initializeAuth(accessToken, userData, userData?.refreshToken);
        navigate(ROUTES?.DASHBOARD);
      }
    },
    onError() {
      navigate(ROUTES?.LOGOUT);
    },
  });

  useEffect(() => {
    if (refreshToken) {
      refresh({
        variables: {
          token: refreshToken,
        },
      });
    } else {
      message.error('Refresh Token does not exist');
    }
  }, []);

  if (loading || profileLoading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
