import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { PROJECT_ENV_ID_ERROR, USERS_ROLE } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import Nodata from '../../../components/Nodata';
import SearchComponent from '../../../components/SearchComponent';
import {
  MemberPermissions,
  Permission,
  ProjectMember,
  ProjectMemberSortBy,
} from '../../../gql/graphql';
import useDebounce from '../../../hooks/useDebounce';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import { PROJECT_ENVIRONMENT } from '../../projects/graphql/Queries';
import {
  CREATE_PROJECT_MEMBER,
  DELETE_PROJECT_MEMBER,
  TRANSFER_OWNERSHIP,
  UPDATE_PROJECT_MEMBER,
} from '../graphql/Mutation';
import { GET_PROJECT_MEMBERS } from '../graphql/Queries';
import InviteMembersModal from './InviteMembersModal';
import InviteTeamMembersCard from './InviteTeamMembersCard';
import OwnershipModal from './OwnershipModal';

const MembersContainer = () => {
  const [form] = Form.useForm();
  const [ownershipForm] = Form.useForm();
  const { projectId } = useParams();
  const {
    state: { projectEnvId },
    getCurrentRole,
    dispatch,
  } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const [inviteTeamMemberList, setInviteTeamMemberList] = useState<
    (ProjectMember | null)[]
  >([]);
  const [inviteTeamMemberSearchTerm, setInviteTeamMemberSearchTerm] =
    useState<string>('');
  const [
    isAllInviteTeamMemberTeamsFetched,
    setIsAllInviteTeamMemberTeamsFetched,
  ] = useState<boolean>(false);
  const [
    isFetchMoreInviteTeamMemberLoading,
    setIsFetchMoreInviteTeamMemberLoading,
  ] = useState<boolean>(false);
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] =
    useState<boolean>(false);
  const [isOwnershipModalOpen, setIsOwnershipModalOpen] =
    useState<boolean>(false);
  const [isInviteNewTeamMemberBtnLoading, setIsInviteNewTeamMemberBtnLoading] =
    useState<boolean>(false);
  const [isOwnershipBtnLoading, setIsOwnershipBtnLoading] =
    useState<boolean>(false);
  const [isInviteTeamMemberLoading, setIsInviteTeamMemberLoading] =
    useState<boolean>(true);
  const [inviteTeamMemberDetails, setInviteTeamMemberDetails] =
    useState<ProjectMember | null>(null);

  const limit = 10;

  const [executeInviteTeamMemberList] = useLazyQuery(GET_PROJECT_MEMBERS, {
    onCompleted: (response) => {
      if (
        response?.projectMembers?.projectMember &&
        response?.projectMembers?.projectMember?.length < limit
      ) {
        setIsAllInviteTeamMemberTeamsFetched(true);
      }
      setIsInviteTeamMemberLoading(false);
      setInviteTeamMemberList([
        ...inviteTeamMemberList,
        ...(response?.projectMembers?.projectMember || []),
      ]);
      setIsFetchMoreInviteTeamMemberLoading(false);
    },
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError() {},
  });

  const [executeProjectEnvironment] = useLazyQuery(PROJECT_ENVIRONMENT, {
    onCompleted: (res) => {
      if (res?.projectEnvironment?.environments?.[0]?.permission) {
        dispatch({
          type: AppActionType.setCurrentRole,
          data: res?.projectEnvironment?.environments?.[0]?.permission,
        });
      }
    },
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError() {},
  });

  const [executeCreateTeamMember] = useMutation(CREATE_PROJECT_MEMBER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeUpdateProjectMember] = useMutation(UPDATE_PROJECT_MEMBER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeDeleteTeamMember] = useMutation(DELETE_PROJECT_MEMBER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeTransferOwnership] = useMutation(TRANSFER_OWNERSHIP, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  useEffect(() => {
    if (projectEnvId && projectId) {
      setInviteTeamMemberList([]);
      setIsInviteTeamMemberLoading(true);
      executeInviteTeamMemberList({
        variables: {
          id: projectId,
          projectEnvId: projectEnvId,
          filter: {
            skip: 0,
            limit,
            search: '',
            sortBy: ProjectMemberSortBy.CreatedAtDesc,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, projectId]);

  const handleInviteTeamMemberScroll = (
    event: React.UIEvent<HTMLDivElement>,
  ) => {
    if (
      !isAllInviteTeamMemberTeamsFetched &&
      !isFetchMoreInviteTeamMemberLoading &&
      !isInviteTeamMemberLoading
    ) {
      const target = event?.currentTarget;
      const currentLength = inviteTeamMemberList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        if (projectEnvId) {
          setIsFetchMoreInviteTeamMemberLoading(true);
          executeInviteTeamMemberList({
            variables: {
              id: projectId,
              projectEnvId: projectEnvId,
              filter: {
                skip: currentLength,
                limit,
                search: inviteTeamMemberSearchTerm,
                sortBy: ProjectMemberSortBy.CreatedAtDesc,
              },
            },
          });
        } else {
          message.error(PROJECT_ENV_ID_ERROR);
        }
      }
    }
  };

  const handleInviteTeamMemberScrollDebounce = useDebounce(
    handleInviteTeamMemberScroll,
    500,
    false,
  );

  const handleReset = () => {
    form?.resetFields();
    setInviteTeamMemberDetails(null);
  };

  const inviteNewTeamMemberFun = async (values: {
    email?: string[];
    permission?: Permission | MemberPermissions;
  }) => {
    if (projectId && projectEnvId) {
      setIsInviteNewTeamMemberBtnLoading(true);
      const response = await executeCreateTeamMember({
        variables: {
          data: {
            id: projectId,
            emails: values?.email || [],
            permission: values?.permission as Permission,
            projectEnvId: projectEnvId,
          },
        },
      });
      if (response?.data) {
        setIsInviteMemberModalOpen(false);
        setIsInviteTeamMemberLoading(true);
        setInviteTeamMemberList([]);
        executeInviteTeamMemberList({
          variables: {
            id: projectId,
            projectEnvId: projectEnvId,
            filter: {
              skip: 0,
              limit,
              search: inviteTeamMemberSearchTerm,
              sortBy: ProjectMemberSortBy.CreatedAtDesc,
            },
          },
        });
        setIsAllInviteTeamMemberTeamsFetched(false);
        // eslint-disable-next-line no-undef
        setTimeout(handleReset, 500);
      }
      setIsInviteNewTeamMemberBtnLoading(false);
    } else {
      message.error(PROJECT_ENV_ID_ERROR);
    }
  };

  const updateProjectMemberFun = async (values: {
    permission?: Permission | MemberPermissions;
  }) => {
    if (inviteTeamMemberDetails?.id) {
      setIsInviteNewTeamMemberBtnLoading(true);
      const response = await executeUpdateProjectMember({
        variables: {
          data: {
            id: inviteTeamMemberDetails?.id,
            projectId: projectId as string,
            projectEnvId: projectEnvId as string,
            permission: values?.permission as MemberPermissions,
          },
        },
      });
      if (response?.data) {
        setIsInviteMemberModalOpen(false);
        setIsInviteTeamMemberLoading(true);
        setInviteTeamMemberList([]);
        executeInviteTeamMemberList({
          variables: {
            id: projectId,
            projectEnvId: projectEnvId as string,
            filter: {
              skip: 0,
              limit,
              search: inviteTeamMemberSearchTerm,
              sortBy: ProjectMemberSortBy.CreatedAtDesc,
            },
          },
        });
        setIsAllInviteTeamMemberTeamsFetched(false);
        // eslint-disable-next-line no-undef
        setTimeout(handleReset, 500);
      }
      setIsInviteNewTeamMemberBtnLoading(false);
    } else {
      message.error('Member details not found');
    }
  };

  const ownershipFun = async (values: { member: string }) => {
    setIsOwnershipBtnLoading(true);
    const response = await executeTransferOwnership({
      variables: {
        input: {
          projectId: projectId as string,
          projectMemberId: values?.member,
          projectEnvId: projectEnvId as string,
        },
      },
    });
    if (response?.data) {
      setIsOwnershipModalOpen(false);
      const res = await executeProjectEnvironment({
        variables: {
          projectId: projectId as string,
        },
      });
      if (res?.data) {
        setIsInviteTeamMemberLoading(true);
        setInviteTeamMemberList([]);
        executeInviteTeamMemberList({
          variables: {
            id: projectId,
            projectEnvId: projectEnvId as string,
            filter: {
              skip: 0,
              limit,
              search: inviteTeamMemberSearchTerm,
              sortBy: ProjectMemberSortBy.CreatedAtDesc,
            },
          },
        });
        setIsAllInviteTeamMemberTeamsFetched(false);
        ownershipForm?.resetFields();
      }
    }
    setIsOwnershipBtnLoading(false);
  };

  const deleteInviteTeamMemberFun = async (id: string) => {
    const response = await executeDeleteTeamMember({
      variables: {
        id: id,
      },
    });
    if (response?.data) {
      setIsInviteTeamMemberLoading(true);
      setInviteTeamMemberList([]);
      executeInviteTeamMemberList({
        variables: {
          id: projectId,
          projectEnvId: projectEnvId as string,
          filter: {
            skip: 0,
            limit,
            search: inviteTeamMemberSearchTerm,
            sortBy: ProjectMemberSortBy.CreatedAtDesc,
          },
        },
      });
      setIsAllInviteTeamMemberTeamsFetched(false);
    }
  };

  const handleInviteTeamMemberSearch = (searchTerm: string) => {
    const trimSearch = searchTerm?.trim();
    setIsAllInviteTeamMemberTeamsFetched(false);
    setIsInviteTeamMemberLoading(true);
    setInviteTeamMemberList([]);
    setInviteTeamMemberSearchTerm(trimSearch);
    executeInviteTeamMemberList({
      variables: {
        id: projectId,
        projectEnvId: projectEnvId as string,
        filter: {
          skip: 0,
          limit,
          search: trimSearch,
          sortBy: ProjectMemberSortBy.CreatedAtDesc,
        },
      },
    });
  };

  return (
    <>
      <InviteMembersModal
        form={form}
        initialValues={inviteTeamMemberDetails}
        formTitle={inviteTeamMemberDetails ? 'Edit Members' : 'Add Members'}
        submitButton={inviteTeamMemberDetails ? 'Update' : 'Invite'}
        isModalOpen={isInviteMemberModalOpen}
        setIsModalOpen={setIsInviteMemberModalOpen}
        onFinish={
          inviteTeamMemberDetails
            ? updateProjectMemberFun
            : inviteNewTeamMemberFun
        }
        loadings={isInviteNewTeamMemberBtnLoading}
        handleReset={handleReset}
      />
      <OwnershipModal
        id={projectId as string}
        projectEnvId={projectEnvId as string}
        form={ownershipForm}
        formTitle="Transfer Ownership"
        submitButton="Save"
        isModalOpen={isOwnershipModalOpen}
        setIsModalOpen={setIsOwnershipModalOpen}
        onFinish={ownershipFun}
        loadings={isOwnershipBtnLoading}
        handleReset={() => ownershipForm?.resetFields()}
      />
      <div className="fill-width">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            <SearchComponent
              query={inviteTeamMemberSearchTerm}
              setQuery={setInviteTeamMemberSearchTerm}
              className="search-input member-search-element"
              getData={handleInviteTeamMemberSearch}
            />
          </div>
          <div>
            {userRole === USERS_ROLE?.OWNER && (
              <Button
                type="primary"
                className="primary-button"
                onClick={() => setIsOwnershipModalOpen(true)}
              >
                Transfer Ownership
              </Button>
            )}
            {userRole === USERS_ROLE?.OWNER && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="primary-button ml-30"
                onClick={() => setIsInviteMemberModalOpen(true)}
              >
                Invite Member
              </Button>
            )}
          </div>
        </div>
      </div>
      <div
        onScroll={handleInviteTeamMemberScrollDebounce}
        className="member-wrapper"
      >
        {!isFetchMoreInviteTeamMemberLoading && isInviteTeamMemberLoading ? (
          <LoaderComponent setHeight="30" size="large" />
        ) : (
          <>
            {inviteTeamMemberList?.length > 0 && (
              <>
                {inviteTeamMemberList?.map((inviteTeamMember) => {
                  return (
                    <InviteTeamMembersCard
                      key={inviteTeamMember?.id}
                      inviteTeamMember={inviteTeamMember}
                      inviteTeamMemberDeleteHandler={deleteInviteTeamMemberFun}
                      setIsInviteMemberModalOpen={setIsInviteMemberModalOpen}
                      setInviteTeamMemberDetails={setInviteTeamMemberDetails}
                      userRole={userRole}
                    />
                  );
                })}
                {isFetchMoreInviteTeamMemberLoading && (
                  <LoaderComponent setHeight="5" />
                )}
              </>
            )}
            {!isFetchMoreInviteTeamMemberLoading &&
              !isInviteTeamMemberLoading &&
              inviteTeamMemberList?.length === 0 && (
                <div className="text-center analytics-illustration">
                  <Nodata />
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default MembersContainer;
