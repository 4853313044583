import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input } from 'antd';
import { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import { ADD_NEW_PROJECT } from '../graphql/Mutations';

const AddProject = () => {
  const { navigate } = useRouter();
  const [loadings, setLoadings] = useState<boolean>(false);
  const [isValidateTrigger, setIsValidateTrigger] = useState<boolean>(false);
  const { dispatch } = useContext(AppContext) as AppContextType;
  const [addNewProject] = useMutation(ADD_NEW_PROJECT);

  const { hyphenAndChar } = formValidatorRules;

  const onFinish = (values: { name: string }) => {
    setLoadings(true);
    addNewProject({
      variables: {
        data: {
          name: values?.name?.trim(),
        },
      },
    })
      .then(() => {
        setLoadings(false);
        dispatch({
          type: AppActionType.createNewProject,
          data: true,
        });
        navigate(ROUTES?.DASHBOARD);
      })
      .catch((error) => {
        setLoadings(false);
        return error;
      });
  };

  return (
    <>
      <h1 className="title ml-30 mt-20">New Project</h1>
      <Card className="full-height-card add-projects">
        <div className="add-container">
          <div className="d-flex flex-horizontal justify-between">
            <p className="add-title">New Project</p>
            <Button
              type="primary"
              className="primary-button"
              onClick={() => navigate(-1)}
            >
              Back to projects
            </Button>
          </div>
          <div className="d-flex flex-vertical justify-between mt-30">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
              onFinishFailed={() => setIsValidateTrigger(true)}
            >
              <Form.Item
                label="Project Name"
                name="name"
                rules={[
                  { required: true, message: 'Please input project name!' },
                  hyphenAndChar,
                ]}
                className="mt-10"
              >
                <Input
                  className="input-box"
                  placeholder="Enter project name"
                  maxLength={70}
                />
              </Form.Item>
              <Form.Item>
                <div className="mt-30 text-center">
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={loadings}
                  >
                    Create New Project
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AddProject;
