/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AddMemberData = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberData>>;
};

export type AddOrUpdateCallbackUrlData = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AddOrUpdateCallbackUrlResponse = {
  __typename?: 'AddOrUpdateCallbackUrlResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AuthDetailsResponse = {
  __typename?: 'AuthDetailsResponse';
  apiKey?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
};

export type Channel = {
  __typename?: 'Channel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectEnvId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChannelEmailUserSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  EmailAsc = 'emailASC',
  EmailDesc = 'emailDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
}

export type ChannelFilter = {
  limit: Scalars['Int']['input'];
  projectEnvId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortBy?: InputMaybe<ChannelSortBy>;
  type: ChannelType;
};

export type ChannelMemberFilter = {
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortBy?: InputMaybe<ChannelEmailUserSortBy>;
};

export enum ChannelSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  NameAsc = 'nameASC',
  NameDesc = 'nameDESC',
}

export enum ChannelType {
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
  Sms = 'SMS',
}

export type ChannelsResponse = {
  __typename?: 'ChannelsResponse';
  channels?: Maybe<Array<Maybe<Channel>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type CopyTemplatesData = {
  projectEnvId: Scalars['ID']['input'];
  templateIds: Array<Scalars['ID']['input']>;
};

export type CreateChannelData = {
  name: Scalars['String']['input'];
  projectEnvId: Scalars['ID']['input'];
  type: ChannelType;
};

export type CreateEmailResponse = {
  __typename?: 'CreateEmailResponse';
  data?: Maybe<MessageLogs>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateProjectEnvironmentInput = {
  environment: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateProjectEnvironmentResponse = {
  __typename?: 'CreateProjectEnvironmentResponse';
  data?: Maybe<ProjectEnvironment>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateProjectMemberData = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  permission: Permission;
  projectEnvId: Scalars['ID']['input'];
};

export type CreateProjectResponse = {
  __typename?: 'CreateProjectResponse';
  data?: Maybe<CreatedProjectWithEnvironmentResponse>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateUpdateChannelResponse = {
  __typename?: 'CreateUpdateChannelResponse';
  data?: Maybe<Channel>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateUpdatePlatformResponse = {
  __typename?: 'CreateUpdatePlatformResponse';
  data?: Maybe<Platform>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateUpdateSenderResponse = {
  __typename?: 'CreateUpdateSenderResponse';
  data?: Maybe<Sender>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateUpdateTemplateResponse = {
  __typename?: 'CreateUpdateTemplateResponse';
  data?: Maybe<Template>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateUpdateUserResponse = {
  __typename?: 'CreateUpdateUserResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type CreatedProjectWithEnvironmentResponse = {
  __typename?: 'CreatedProjectWithEnvironmentResponse';
  createdBy?: Maybe<Scalars['ID']['output']>;
  environment?: Maybe<ProjectEnvironment>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DeletePlatformResponse = {
  __typename?: 'DeletePlatformResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type DeleteProjectResponse = {
  __typename?: 'DeleteProjectResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  data?: Maybe<User>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

export enum MemberPermissions {
  ReadOnly = 'READ_ONLY',
  Write = 'WRITE',
}

export type MessageLogs = {
  __typename?: 'MessageLogs';
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isScheduled?: Maybe<Scalars['Boolean']['output']>;
  messageDetails?: Maybe<Scalars['JSON']['output']>;
  projectEnvId?: Maybe<Scalars['ID']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  scheduledTime?: Maybe<Scalars['DateTime']['output']>;
  sender?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatusType>;
  type?: Maybe<ProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MessageLogsFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  projectEnvId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MessageSortBy>;
  type: ProviderType;
};

export type MessageResponse = {
  __typename?: 'MessageResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum MessageSortBy {
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  RecipientAsc = 'recipientASC',
  RecipientDesc = 'recipientDESC',
  SenderAsc = 'senderASC',
  SenderDesc = 'senderDESC',
  UpdatedAtAsc = 'updatedAtASC',
  UpdatedAtDesc = 'updatedAtDESC',
}

export type MessagesLogsResponse = {
  __typename?: 'MessagesLogsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  messages?: Maybe<Array<Maybe<MessageLogs>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMemberInChannel?: Maybe<CreateUpdateChannelResponse>;
  addOrUpdateCallbackUrl?: Maybe<AddOrUpdateCallbackUrlResponse>;
  copyTemplates?: Maybe<MessageResponse>;
  createChannel?: Maybe<CreateUpdateChannelResponse>;
  createPlatform?: Maybe<CreateUpdatePlatformResponse>;
  createProject?: Maybe<CreateProjectResponse>;
  createProjectEnvironment?: Maybe<CreateProjectEnvironmentResponse>;
  createProjectMember?: Maybe<MessageResponse>;
  createSender?: Maybe<CreateUpdateSenderResponse>;
  createTemplate?: Maybe<CreateUpdateTemplateResponse>;
  deleteChannel?: Maybe<MessageResponse>;
  deletePlatform?: Maybe<DeletePlatformResponse>;
  deleteProject?: Maybe<DeleteProjectResponse>;
  deleteProjectEnvironment?: Maybe<CreateProjectEnvironmentResponse>;
  deleteProjectMember?: Maybe<MessageResponse>;
  deleteSender?: Maybe<DeleteSenderResponse>;
  deleteTemplate?: Maybe<MessageResponse>;
  getSignedUrl?: Maybe<SignedUrlResponse>;
  logout?: Maybe<CreateUpdateUserResponse>;
  refreshToken?: Maybe<LoginResponse>;
  removeMemberFromChannel?: Maybe<CreateUpdateChannelResponse>;
  resendEmail?: Maybe<MessageResponse>;
  sendMailInChannel?: Maybe<CreateUpdateChannelResponse>;
  sendOtp?: Maybe<CreateUpdateUserResponse>;
  sendRawEmail?: Maybe<CreateEmailResponse>;
  transferOwnership?: Maybe<TransferOwnershipResponse>;
  updateChannel?: Maybe<CreateUpdateChannelResponse>;
  updatePlatform?: Maybe<CreateUpdatePlatformResponse>;
  updateProject?: Maybe<UpdatedProjectResponse>;
  updateProjectMember?: Maybe<UpdateProjectMemberResponse>;
  updateSender?: Maybe<CreateUpdateSenderResponse>;
  updateTemplate?: Maybe<CreateUpdateTemplateResponse>;
  updateUser?: Maybe<UpdateResponse>;
  verifyOtp?: Maybe<LoginResponse>;
  verifySender?: Maybe<VerifyResponse>;
};

export type MutationAddMemberInChannelArgs = {
  channelId: Scalars['ID']['input'];
  data: AddMemberData;
};

export type MutationAddOrUpdateCallbackUrlArgs = {
  data?: InputMaybe<AddOrUpdateCallbackUrlData>;
  where?: InputMaybe<ProjectEnvironmentUniqueInput>;
};

export type MutationCopyTemplatesArgs = {
  data: CopyTemplatesData;
};

export type MutationCreateChannelArgs = {
  data: CreateChannelData;
};

export type MutationCreatePlatformArgs = {
  data: PlatformData;
};

export type MutationCreateProjectArgs = {
  data: ProjectData;
};

export type MutationCreateProjectEnvironmentArgs = {
  data: CreateProjectEnvironmentInput;
};

export type MutationCreateProjectMemberArgs = {
  data: CreateProjectMemberData;
};

export type MutationCreateSenderArgs = {
  data: SenderDataCreate;
};

export type MutationCreateTemplateArgs = {
  data: TemplateData;
};

export type MutationDeleteChannelArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePlatformArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteProjectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteProjectEnvironmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteProjectMemberArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSenderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationGetSignedUrlArgs = {
  data: UploadData;
};

export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationRemoveMemberFromChannelArgs = {
  channelId: Scalars['ID']['input'];
  channelMemberId: Scalars['ID']['input'];
};

export type MutationResendEmailArgs = {
  projectEnvId: Scalars['ID']['input'];
  where: ResendEmailWhere;
};

export type MutationSendMailInChannelArgs = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  data: SendMailInChannelData;
};

export type MutationSendOtpArgs = {
  email: Scalars['String']['input'];
};

export type MutationSendRawEmailArgs = {
  data: SendRawEmailData;
  projectEnvId: Scalars['ID']['input'];
};

export type MutationTransferOwnershipArgs = {
  input: TransferOwnershipInput;
};

export type MutationUpdateChannelArgs = {
  data: UpdateChannelData;
  id: Scalars['ID']['input'];
};

export type MutationUpdatePlatformArgs = {
  data: PlatformUpdateData;
  id: Scalars['ID']['input'];
};

export type MutationUpdateProjectArgs = {
  data: ProjectData;
  id: Scalars['ID']['input'];
};

export type MutationUpdateProjectMemberArgs = {
  data: UpdatedProjectMemberData;
};

export type MutationUpdateSenderArgs = {
  data: SenderDataUpdate;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTemplateArgs = {
  data: TemplateUpdateData;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
  data: UserData;
};

export type MutationVerifyOtpArgs = {
  data: LoginData;
};

export type MutationVerifySenderArgs = {
  id: Scalars['ID']['input'];
  reVerify: Scalars['Boolean']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export enum Permission {
  Owner = 'OWNER',
  ReadOnly = 'READ_ONLY',
  Write = 'WRITE',
}

export type PhoneNumberData = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Platform = {
  __typename?: 'Platform';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  platformConfigs?: Maybe<Scalars['JSON']['output']>;
  projectEnvId?: Maybe<Scalars['ID']['output']>;
  provider?: Maybe<Provider>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlatformConfigsResponse = {
  __typename?: 'PlatformConfigsResponse';
  platformConfigs?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PlatformData = {
  name?: InputMaybe<Scalars['String']['input']>;
  platformConfigs?: InputMaybe<Scalars['JSON']['input']>;
  projectEnvId?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PlatformType>;
};

export type PlatformFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PlatformSortBy>;
};

export enum PlatformSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  NameAsc = 'nameASC',
  NameDesc = 'nameDESC',
}

export enum PlatformType {
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
  Sms = 'SMS',
}

export type PlatformUpdateData = {
  name?: InputMaybe<Scalars['String']['input']>;
  platformConfigs?: InputMaybe<Scalars['JSON']['input']>;
  projectEnvId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PlatformType>;
};

export type PlatformsResponse = {
  __typename?: 'PlatformsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
};

export type ProjectData = {
  description?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permission?: InputMaybe<MemberPermissions>;
};

export type ProjectEnvironment = {
  __typename?: 'ProjectEnvironment';
  createdBy?: Maybe<Scalars['ID']['output']>;
  environment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  permission?: Maybe<Permission>;
  projectId?: Maybe<Scalars['String']['output']>;
};

export type ProjectEnvironmentResponse = {
  __typename?: 'ProjectEnvironmentResponse';
  count?: Maybe<Scalars['Int']['output']>;
  environments?: Maybe<Array<Maybe<ProjectEnvironment>>>;
};

export type ProjectEnvironmentUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ProjectFilter = {
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortBy?: InputMaybe<ProjectSortBy>;
};

export type ProjectMember = {
  __typename?: 'ProjectMember';
  addedBy?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inviteStatus?: Maybe<InviteStatus>;
  permission?: Maybe<Permission>;
  projectEnvId?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectMemberFilter = {
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortBy?: InputMaybe<ProjectMemberSortBy>;
};

export enum ProjectMemberSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  NameAsc = 'nameASC',
  NameDesc = 'nameDESC',
}

export type ProjectMembersResponse = {
  __typename?: 'ProjectMembersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  projectMember?: Maybe<Array<Maybe<ProjectMember>>>;
};

export enum ProjectSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  NameAsc = 'nameASC',
  NameDesc = 'nameDESC',
}

export type ProjectWithEnvironment = {
  __typename?: 'ProjectWithEnvironment';
  createdBy?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environment?: Maybe<Array<Maybe<ProjectEnvironment>>>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectsMember?: Maybe<Array<Maybe<ProjectMember>>>;
};

export type ProjectWithoutEnvironment = {
  __typename?: 'ProjectWithoutEnvironment';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectsResponse = {
  __typename?: 'ProjectsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  projects?: Maybe<Array<Maybe<ProjectWithoutEnvironment>>>;
};

export type Provider = {
  __typename?: 'Provider';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  providerConfigs?: Maybe<Scalars['JSON']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProviderFilter = {
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortBy: ProviderSortBy;
  type: ProviderType;
};

export enum ProviderSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  NameAsc = 'nameASC',
  NameDesc = 'nameDESC',
}

export enum ProviderType {
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
  Sms = 'SMS',
}

export type ProvidersResponse = {
  __typename?: 'ProvidersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  providers?: Maybe<Array<Maybe<Provider>>>;
};

export type Query = {
  __typename?: 'Query';
  channel?: Maybe<Channel>;
  channelEmailUsers?: Maybe<ChannelMemberResponse>;
  channels?: Maybe<ChannelsResponse>;
  getPlatformConfigs?: Maybe<PlatformConfigsResponse>;
  messagesLogs?: Maybe<MessagesLogsResponse>;
  platform?: Maybe<Platform>;
  platforms?: Maybe<PlatformsResponse>;
  project?: Maybe<ProjectWithEnvironment>;
  projectEnvAuthDetails?: Maybe<AuthDetailsResponse>;
  projectEnvironment?: Maybe<ProjectEnvironmentResponse>;
  projectMembers?: Maybe<ProjectMembersResponse>;
  projects?: Maybe<ProjectsResponse>;
  provider?: Maybe<Provider>;
  providers?: Maybe<ProvidersResponse>;
  sender?: Maybe<Sender>;
  senders?: Maybe<SendersResponse>;
  template?: Maybe<Template>;
  templates?: Maybe<TemplatesResponse>;
  user?: Maybe<User>;
};

export type QueryChannelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChannelEmailUsersArgs = {
  channelId: Scalars['ID']['input'];
  filter: ChannelMemberFilter;
};

export type QueryChannelsArgs = {
  filter: ChannelFilter;
};

export type QueryGetPlatformConfigsArgs = {
  id: Scalars['ID']['input'];
  type: PlatformType;
};

export type QueryMessagesLogsArgs = {
  filter: MessageLogsFilter;
};

export type QueryPlatformArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPlatformsArgs = {
  filter: PlatformFilter;
  projectEnvId: Scalars['ID']['input'];
};

export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProjectEnvAuthDetailsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProjectEnvironmentArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryProjectMembersArgs = {
  filter: ProjectMemberFilter;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectEnvId: Scalars['ID']['input'];
};

export type QueryProjectsArgs = {
  filter: ProjectFilter;
};

export type QueryProviderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProvidersArgs = {
  filter: ProviderFilter;
};

export type QuerySenderArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySendersArgs = {
  filter: SenderFilter;
  projectEnvId: Scalars['ID']['input'];
  type: ProviderType;
};

export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTemplatesArgs = {
  filter: TemplateFilter;
};

export type ResendEmailWhere = {
  messageLogId: Scalars['ID']['input'];
};

export type SendMailInChannelData = {
  ccAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  isTemplateUsed: Scalars['Boolean']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  replyTo?: InputMaybe<Scalars['String']['input']>;
  senderId: Scalars['ID']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  templateKey?: InputMaybe<Scalars['String']['input']>;
};

export type SendRawEmailData = {
  body?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  isTemplateUsed: Scalars['Boolean']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Array<Scalars['String']['input']>>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  templateKey?: InputMaybe<Scalars['String']['input']>;
};

export type Sender = {
  __typename?: 'Sender';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  platformSlug?: Maybe<Scalars['String']['output']>;
  projectEnvId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SenderDataCreate = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNo?: InputMaybe<Scalars['String']['input']>;
  projectEnvId: Scalars['ID']['input'];
};

export type SenderDataUpdate = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SenderFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SenderSortBy>;
};

export enum SenderSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  EmailAsc = 'emailASC',
  EmailDesc = 'emailDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  NameAsc = 'nameASC',
  NameDesc = 'nameDESC',
}

export type SendersResponse = {
  __typename?: 'SendersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  senders?: Maybe<Array<Maybe<Sender>>>;
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key?: Maybe<Scalars['String']['output']>;
  signedRequest?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum StatusType {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS',
}

export type Template = {
  __typename?: 'Template';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  design?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  projectEnvId?: Maybe<Scalars['ID']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProviderType>;
};

export type TemplateData = {
  body?: InputMaybe<Scalars['String']['input']>;
  design?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  projectEnvId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: ProviderType;
};

export type TemplateFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  projectEnvId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TemplateSortBy>;
  type?: InputMaybe<ProviderType>;
};

export enum TemplateSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  KeyAsc = 'keyASC',
  KeyDesc = 'keyDESC',
}

export type TemplateUpdateData = {
  body?: InputMaybe<Scalars['String']['input']>;
  design?: InputMaybe<Scalars['String']['input']>;
  projectEnvId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: ProviderType;
};

export type TemplatesResponse = {
  __typename?: 'TemplatesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  templates?: Maybe<Array<Maybe<Template>>>;
};

export type TransferOwnershipInput = {
  projectEnvId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  projectMemberId: Scalars['ID']['input'];
};

export type TransferOwnershipResponse = {
  __typename?: 'TransferOwnershipResponse';
  data?: Maybe<ProjectMember>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type UpdateChannelData = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectMemberResponse = {
  __typename?: 'UpdateProjectMemberResponse';
  data?: Maybe<ProjectMember>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  data?: Maybe<User>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type UpdatedProjectMemberData = {
  id: Scalars['ID']['input'];
  permission?: InputMaybe<MemberPermissions>;
  projectEnvId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type UpdatedProjectResponse = {
  __typename?: 'UpdatedProjectResponse';
  data?: Maybe<ProjectWithoutEnvironment>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UploadData = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  extension: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type UserData = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
};

export type UserFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortBy>;
};

export enum UserSortBy {
  CreatedAtAsc = 'createdAtASC',
  CreatedAtDesc = 'createdAtDESC',
  FirstNameAsc = 'firstNameASC',
  FirstNameDesc = 'firstNameDESC',
  IdAsc = 'idASC',
  IdDesc = 'idDESC',
  LastNameAsc = 'lastNameASC',
  LastNameDesc = 'lastNameDESC',
}

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type VerifyResponse = {
  __typename?: 'VerifyResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ChannelMemberResponse = {
  __typename?: 'channelMemberResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<ChannelMemberResponseData>>>;
};

export type ChannelMemberResponseData = {
  __typename?: 'channelMemberResponseData';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteSenderResponse = {
  __typename?: 'deleteSenderResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type LoginData = {
  email: Scalars['String']['input'];
  otp: Scalars['String']['input'];
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    createdAt?: any | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    profileImage?: string | null;
    message?: string | null;
    refreshToken?: string | null;
  } | null;
};

export const UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'User' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshToken' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
