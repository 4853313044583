import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Email from './Email';
type EmailWrapperPropType = {
  projectId: string;
  // eslint-disable-next-line no-unused-vars
  setActiveKey: (value: string | ((prevState: string) => string)) => void;
  activeKey: string;
};
const EmailWrapper = ({ setActiveKey, activeKey }: EmailWrapperPropType) => {
  return (
    <Routes>
      <Route
        path={ROUTES.MAIN}
        element={<Email setActiveKey={setActiveKey} activeKey={activeKey} />}
      />
    </Routes>
  );
};

export default EmailWrapper;
