import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Select, Space, Tag } from 'antd';
import { Rule } from 'antd/es/form';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { CloseCircle } from '../../../assets/svg';
import { FORM_KEYS, REGEX } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import SelectComponent from '../../../components/SelectComponent';
import {
  ProviderType,
  Sender,
  SenderSortBy,
  TemplateSortBy,
  TemplatesResponse,
} from '../../../gql/graphql';
import { AppContextType } from '../../../types/appContext.type';
import { CommonModalProps } from '../../../types/common.type';
import { SENDERS } from '../../senders/graphql/Queries';
import { TEMPLATES } from '../../template/graphql/Queries';
import { SEND_MAIL_IN_CHANNEL } from '../graphql/Mutation';

const { Option } = Select;
type SendEmailModalPropType = CommonModalProps & {
  formTitle: string;
  submitButton: string;
  channelId: string;
  // eslint-disable-next-line no-unused-vars
  setIsMailSend: (value: boolean) => void;
};
const SendEmailModal = ({
  isModalOpen,
  setIsModalOpen,
  formTitle,
  submitButton,
  channelId,
  setIsMailSend,
}: SendEmailModalPropType) => {
  const [form] = Form.useForm();
  const {
    state: { projectEnvId },
  } = useContext(AppContext) as AppContextType;
  const { email } = formValidatorRules;
  const [selectedMailType, setSelectedMailType] = useState<string>('');
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [isValidateTrigger, setIsValidateTrigger] = useState<boolean>(false);

  const [templateList, setTemplateList] = useState<
    TemplatesResponse['templates']
  >([]);
  const [templateSearchTerm, setTemplateSearchTerm] = useState<string>('');
  const [isAllTemplatesFetched, setIsAllTemplatesFetched] =
    useState<boolean>(false);
  const [isTemplateAllowClear, setIsTemplateAllowClear] =
    useState<boolean>(false);
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] =
    useState<boolean>(false);
  const [isFetchMoreTemplatesLoading, setIsFetchMoreTemplatesLoading] =
    useState<boolean>(false);

  const [senderList, setSenderList] = useState<(Sender | null)[] | null>([]);
  const [senderSearchTerm, setSenderSearchTerm] = useState<string>('');
  const [isAllSendersFetched, setIsAllSendersFetched] =
    useState<boolean>(false);
  const [isSenderAllowClear, setIsSenderAllowClear] = useState<boolean>(false);
  const [isSenderDropdownVisible, setIsSenderDropdownVisible] =
    useState<boolean>(false);
  const [isSendMailBtnLoading, setIsSendMailBtnLoading] =
    useState<boolean>(false);
  const [isFetchMoreSendersLoading, setIsFetchMoreSendersLoading] =
    useState<boolean>(false);

  const limit = 10;

  const [executeTemplateList, { loading: isTemplatesLoading }] = useLazyQuery(
    TEMPLATES,
    {
      onCompleted: (response) => {
        if (
          response?.templates?.templates &&
          response?.templates?.templates?.length < limit
        ) {
          setIsAllTemplatesFetched(true);
        }
        setIsFetchMoreTemplatesLoading(false);
        setTemplateList([
          ...(templateList || []),
          ...(response?.templates?.templates || []),
        ]);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    },
  );

  const [executeSenderList, { loading: isSendersLoading }] = useLazyQuery(
    SENDERS,
    {
      onCompleted: (response) => {
        if (
          response?.senders?.senders &&
          response?.senders?.senders?.length < limit
        ) {
          setIsAllSendersFetched(true);
        }
        setIsFetchMoreSendersLoading(false);
        setSenderList([
          ...(senderList || []),
          ...(response?.senders?.senders || []),
        ]);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    },
  );

  const [executeSendMail] = useMutation(SEND_MAIL_IN_CHANNEL, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  useEffect(() => {
    if (
      projectEnvId &&
      selectedMailType === FORM_KEYS?.TEMPLATE &&
      !isTemplateDropdownVisible &&
      isModalOpen
    ) {
      setTemplateSearchTerm('');
      setTemplateList([]);
      executeTemplateList({
        variables: {
          filter: {
            skip: 0,
            limit,
            projectEnvId: projectEnvId,
            sortBy: TemplateSortBy.CreatedAtDesc,
          },
        },
      });
      setIsAllTemplatesFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, selectedMailType, isTemplateDropdownVisible, isModalOpen]);

  useEffect(() => {
    if (projectEnvId && !isSenderDropdownVisible && isModalOpen) {
      setSenderSearchTerm('');
      setSenderList([]);
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId,
          type: ProviderType.Email,
          filter: {
            skip: 0,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
      setIsAllSendersFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, isSenderDropdownVisible, isModalOpen]);

  const handleTemplateScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!isAllTemplatesFetched && !isTemplatesLoading) {
      const target = event?.currentTarget;
      const currentLength = templateList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreTemplatesLoading(true);
        executeTemplateList({
          variables: {
            filter: {
              skip: currentLength,
              limit,
              projectEnvId: projectEnvId as string,
              search: templateSearchTerm,
              sortBy: TemplateSortBy.CreatedAtDesc,
            },
          },
        });
      }
    }
  };

  const handleSenderScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!isAllSendersFetched && !isSendersLoading) {
      const target = event?.currentTarget;
      const currentLength = senderList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreSendersLoading(true);
        executeSenderList({
          variables: {
            projectEnvId: projectEnvId as string,
            type: ProviderType.Email,
            filter: {
              skip: currentLength,
              limit,
              search: senderSearchTerm,
              sortBy: SenderSortBy.CreatedAtDesc,
            },
          },
        });
      }
    }
  };

  const handleTemplateSearch = (searchTerm: string) => {
    const trimSearch = searchTerm?.trim();
    setIsAllTemplatesFetched(false);
    setTemplateList([]);
    setTemplateSearchTerm(trimSearch);
    executeTemplateList({
      variables: {
        filter: {
          skip: 0,
          limit,
          projectEnvId: projectEnvId as string,
          search: trimSearch,
          sortBy: TemplateSortBy.CreatedAtAsc,
        },
      },
    });
  };

  const handleSenderSearch = (searchTerm: string) => {
    const trimSearch = searchTerm?.trim();
    setIsAllSendersFetched(false);
    setSenderList([]);
    setSenderSearchTerm(trimSearch);
    executeSenderList({
      variables: {
        projectEnvId: projectEnvId as string,
        type: ProviderType.Email,
        filter: {
          skip: 0,
          limit,
          search: trimSearch,
          sortBy: SenderSortBy.CreatedAtDesc,
        },
      },
    });
  };

  const handleTemplateScrollDebounce = debounce(handleTemplateScroll, 500);
  const handleSearchTemplateDebounce = debounce(handleTemplateSearch, 500);

  const handleSenderScrollDebounce = debounce(handleSenderScroll, 500);
  const handleSearchSenderDebounce = debounce(handleSenderSearch, 500);

  useEffect(() => {
    return () => {
      handleTemplateScrollDebounce?.cancel();
    };
  }, [handleTemplateScrollDebounce]);

  useEffect(() => {
    return () => {
      handleSearchTemplateDebounce?.cancel();
    };
  }, [handleSearchTemplateDebounce]);

  useEffect(() => {
    return () => {
      handleSenderScrollDebounce?.cancel();
    };
  }, [handleSenderScrollDebounce]);

  useEffect(() => {
    return () => {
      handleSearchSenderDebounce?.cancel();
    };
  }, [handleSearchSenderDebounce]);

  const sendMailFun = async (values: {
    select_sender: string;
    select_template: string;
    data_variables: string;
    reply_to: string;
    cc_addresses?: string[];
    email_subject?: string;
  }) => {
    setIsSendMailBtnLoading(true);
    const response = await executeSendMail({
      variables: {
        channelId,
        data: {
          isTemplateUsed: selectedMailType === FORM_KEYS?.TEMPLATE,
          senderId: values?.select_sender,
          templateKey: values?.select_template,
          data: values?.data_variables
            ? JSON.parse(values?.data_variables)
            : undefined,
          replyTo: values?.reply_to,
          ccAddresses: values?.cc_addresses,
          subject: values?.email_subject,
        },
      },
    });
    if (response?.data) {
      form?.resetFields();
      setIsModalOpen(false);
      setSelectedMailType('');
      setIsMailSend(true);
    }
    setIsSendMailBtnLoading(false);
  };

  useEffect(() => {
    if (isTemplatesLoading) {
      setIsTemplateAllowClear(false);
    } else {
      setIsTemplateAllowClear(true);
    }
  }, [isTemplatesLoading]);

  useEffect(() => {
    if (isSendersLoading) {
      setIsSenderAllowClear(false);
    } else {
      setIsSenderAllowClear(true);
    }
  }, [isSendersLoading]);

  const emailValidator = (rule: Rule, values: string[]) => {
    const invalidInputs = values?.filter(
      (value) => !value?.match(REGEX?.EMAIL),
    );
    setInvalidEmails(invalidInputs);
    if (invalidInputs?.length === 1) {
      return Promise?.reject(
        new Error(`${invalidInputs?.join('')} is not a valid email`),
      );
    }
    if (invalidInputs?.length > 1) {
      return Promise?.reject(
        new Error(
          `${invalidInputs?.slice(0, -1).join(', ')} and ${invalidInputs?.slice(
            -1,
          )} are not valid emails`,
        ),
      );
    }
    return Promise?.resolve();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // eslint-disable-next-line no-undef
    setTimeout(setSelectedMailType, 500);
    form?.resetFields();
  };

  const onPreventMouseDown = (e: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <>
      <Modal
        centered
        closeIcon={<CloseCircle />}
        wrapClassName="environment-modal channel-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        onOk={form?.submit}
        forceRender
      >
        <Form
          form={form}
          onFinish={sendMailFun}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <div className="send-email add-container">
            <div className="d-flex flex-horizontal justify-between">
              <p className="add-title">{formTitle}</p>
            </div>
            <div className="d-flex flex-vertical justify-between ">
              <Form.Item
                label="Select Sender"
                name="select_sender"
                rules={[
                  {
                    required: true,
                    message: 'Please select template',
                  },
                ]}
              >
                <SelectComponent
                  showSearch
                  loading={
                    (isSendersLoading && isSenderDropdownVisible) ||
                    isFetchMoreSendersLoading
                  }
                  placeholder="Select sender"
                  optionFilterProp="children"
                  allowClear={isSenderAllowClear}
                  onPopupScroll={handleSenderScrollDebounce}
                  onSearch={handleSearchSenderDebounce}
                  onDropdownVisibleChange={(visible) => {
                    setIsSenderDropdownVisible(visible);
                  }}
                  filterOption={false}
                  notFoundContent={
                    !isFetchMoreSendersLoading && isSendersLoading ? (
                      <span>Loading...</span>
                    ) : (
                      <span>No user available</span>
                    )
                  }
                >
                  {senderList && senderList?.length > 0 && (
                    <>
                      {senderList?.map((sender) => {
                        return (
                          <Option key={sender?.id} value={sender?.id}>
                            {sender?.email}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </SelectComponent>
              </Form.Item>
              <Form.Item
                label="Mail Type"
                name="mail_type"
                rules={[
                  { required: true, message: 'Please select mail type!' },
                ]}
              >
                <SelectComponent
                  showSearch={false}
                  placeholder="Select mail type"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedMailType(e)}
                >
                  <Option key={FORM_KEYS?.TEMPLATE}>Select template</Option>
                  <Option key={FORM_KEYS?.EMAIL}>Create custom email</Option>
                </SelectComponent>
              </Form.Item>
              {selectedMailType === FORM_KEYS?.TEMPLATE && (
                <Form.Item
                  label="Select Template"
                  name="select_template"
                  rules={[
                    {
                      required: true,
                      message: 'Please select template',
                    },
                  ]}
                >
                  <SelectComponent
                    showSearch
                    loading={
                      (isTemplatesLoading && isTemplateDropdownVisible) ||
                      isFetchMoreTemplatesLoading
                    }
                    placeholder="Select template"
                    optionFilterProp="children"
                    allowClear={isTemplateAllowClear}
                    onPopupScroll={handleTemplateScrollDebounce}
                    onSearch={handleSearchTemplateDebounce}
                    onDropdownVisibleChange={(visible) => {
                      setIsTemplateDropdownVisible(visible);
                    }}
                    filterOption={false}
                    notFoundContent={
                      !isFetchMoreTemplatesLoading && isTemplatesLoading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>No user available</span>
                      )
                    }
                  >
                    {templateList && templateList?.length > 0 && (
                      <>
                        {templateList?.map((template) => {
                          return (
                            <Option key={template?.id} value={template?.key}>
                              {template?.title}
                            </Option>
                          );
                        })}
                      </>
                    )}
                  </SelectComponent>
                </Form.Item>
              )}
              {selectedMailType === FORM_KEYS?.EMAIL && (
                <Form.Item
                  label="Email Subject"
                  name="email_subject"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email subject!',
                    },
                  ]}
                >
                  <Input
                    className="input-box"
                    placeholder="Enter email subject"
                  />
                </Form.Item>
              )}
              {selectedMailType === FORM_KEYS?.EMAIL && (
                <Form.Item
                  label="Email Body"
                  name="email_body"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email body!',
                    },
                  ]}
                >
                  <Input.TextArea
                    className="input-box"
                    placeholder="Enter email body"
                  />
                </Form.Item>
              )}
              {selectedMailType === FORM_KEYS?.TEMPLATE && (
                <Form.Item
                  label="Data variables"
                  name="data_variables"
                  className="text-area"
                  help='Hint: {"firstname":"John", "lastname":"Doe"}'
                >
                  <Input.TextArea
                    className="input-box"
                    placeholder="Enter data variables (if any) in JSON format"
                  />
                </Form.Item>
              )}
              {(selectedMailType === FORM_KEYS?.TEMPLATE ||
                selectedMailType === FORM_KEYS?.EMAIL) && (
                <Form.Item name="reply_to" label="Reply to" rules={[email]}>
                  <Input
                    className="input-box"
                    placeholder="Enter reply to email"
                  />
                </Form.Item>
              )}
              {(selectedMailType === FORM_KEYS?.TEMPLATE ||
                selectedMailType === FORM_KEYS?.EMAIL) && (
                <Form.Item
                  name="cc_addresses"
                  label="CC"
                  rules={[
                    {
                      validator: emailValidator,
                    },
                  ]}
                >
                  <SelectComponent
                    mode="tags"
                    className="input-box"
                    placeholder="Enter cc emails"
                    popupClassName="select_dropdown"
                    tokenSeparators={[',', ' ']}
                    tagRender={({ label, value, closable, onClose }) => (
                      <Tag
                        closable={closable}
                        onClose={(e) => {
                          e?.preventDefault();
                          onClose();
                        }}
                        onMouseDown={onPreventMouseDown}
                        color={invalidEmails?.includes(value) ? 'red' : ''}
                      >
                        {label}
                      </Tag>
                    )}
                  />
                </Form.Item>
              )}
            </div>
            <Space size="middle" className="mt-20">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={isSendMailBtnLoading}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SendEmailModal;
