const useDebounce = (
  // eslint-disable-next-line no-unused-vars
  func: (event: React.UIEvent<HTMLDivElement>) => void,
  wait: number,
  immediate: boolean,
) => {
  let timeout: NodeJS.Timeout | null = null;

  return function (this: unknown, ...args: unknown[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate)
        func.apply(context, args as [React.UIEvent<HTMLDivElement>]);
    };

    const callNow = immediate && !timeout;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args as [React.UIEvent<HTMLDivElement>]);
  };
};

export default useDebounce;
