import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Sms from './Sms';

const SmsWrapper = () => {
  return (
    <Routes>
      <Route path={ROUTES.MAIN} element={<Sms />} />
    </Routes>
  );
};

export default SmsWrapper;
