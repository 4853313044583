import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Popconfirm,
  TableColumnsType,
  TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES, SORT, USERS_ROLE } from '../../common/constants';
import { formatDate, formValidatorRules } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import { ProviderType, Sender, SenderSortBy } from '../../gql/graphql';
import { AppContextType } from '../../types/appContext.type';
import { FormFieldConfig } from '../../types/common.type';
import CreateSenderModal from './components/CreateSenderModal';
import {
  CREATE_SENDER,
  DELETE_SENDER,
  UPDATE_SENDER,
  VERIFY_SENDER,
} from './graphql/Mutation';
import { SENDERS } from './graphql/Queries';
import './senders.less';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const Senders = ({ activeKey }: { activeKey: string }) => {
  const [form] = Form.useForm();
  const {
    state: { projectEnvId },
    getCurrentRole,
  } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const { email } = formValidatorRules;
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Sender>>({});
  const [isEmptySenderList, setIsEmptySenderList] = useState<boolean>(false);
  const [paginationProp, setPaginationProp] = useState<TablePaginationConfig>(
    initialPaginationValue,
  );
  const [senderList, setSenderList] = useState<(Sender | null)[] | null>([]);
  const [senderSearchTerm, setSenderSearchTerm] = useState<string>('');
  const [isSenderLoading, setIsSenderLoading] = useState<boolean>(true);
  const [isEditSenderModal, setIsEditSenderModal] = useState<boolean>(false);
  const [isEditSenderRowData, setIsEditSenderRowData] = useState<Sender | null>(
    null,
  );
  const [isCreateSenderModalOpen, setCreateSenderModalOpen] =
    useState<boolean>(false);
  const [isCreateSenderBtnLoading, setIsCreateSenderBtnLoading] =
    useState<boolean>(false);
  const [isAllowClear, setIsAllowClear] = useState<boolean>(false);
  const [isSenderSearchLoading, setIsSenderSearchLoading] =
    useState<boolean>(false);

  const limit = 10;

  const [executeCreateSender] = useMutation(CREATE_SENDER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeUpdateSender] = useMutation(UPDATE_SENDER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeDeleteSender] = useMutation(DELETE_SENDER, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  const [executeSenderList] = useLazyQuery(SENDERS, {
    onCompleted: (response) => {
      setSenderList([...(response?.senders?.senders || [])]);
      if (
        response?.senders?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptySenderList(true);
      }
      const pagination: TablePaginationConfig = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.senders?.count || 0,
      };
      setPaginationProp(pagination);
      setIsSenderLoading(false);
      setIsSenderSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsSenderLoading(false);
    },
  });

  const [executeVerifySender] = useMutation(VERIFY_SENDER, {
    onCompleted: (response) => {
      if (response?.verifySender?.status === 'SUCCESS') {
        setIsSenderLoading(true);
        executeSenderList({
          variables: {
            projectEnvId: projectEnvId as string,
            type: ProviderType.Email,
            filter: {
              skip: 0,
              limit,
              sortBy: SenderSortBy.CreatedAtDesc,
            },
          },
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  useEffect(() => {
    if (projectEnvId && activeKey === ROUTES?.SENDERS) {
      setIsSenderLoading(true);
      setSenderList([]);
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId,
          type: ProviderType.Email,
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, activeKey]);

  const handleReset = () => {
    form?.resetFields();
  };

  const createSenderFun = async (senderDetail: {
    name: string;
    email: string;
  }) => {
    setIsCreateSenderBtnLoading(true);
    const response = await executeCreateSender({
      variables: {
        data: {
          name: senderDetail?.name,
          email: senderDetail?.email?.trim(),
          projectEnvId: projectEnvId as string,
        },
      },
    });
    if (response?.data) {
      setCreateSenderModalOpen(false);
      setIsSenderLoading(true);
      setSenderList([]);
      setPaginationProp({ ...paginationProp, current: 1 });
      setSenderSearchTerm('');
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId as string,
          type: ProviderType.Email,
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
      setTimeout(handleReset, 500);
    }
    setIsCreateSenderBtnLoading(false);
  };

  const updateSenderFun = async (senderDetail: { name: string }) => {
    setIsCreateSenderBtnLoading(true);
    const response = await executeUpdateSender({
      variables: {
        data: {
          name: senderDetail?.name,
        },
        id: isEditSenderRowData?.id as string,
      },
    });
    if (response?.data) {
      setCreateSenderModalOpen(false);
      setIsSenderLoading(true);
      setSenderList([]);
      setPaginationProp({ ...paginationProp, current: 1 });
      setSenderSearchTerm('');
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId as string,
          type: ProviderType.Email,
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
      setTimeout(handleReset, 500);
    }
    setIsEditSenderRowData(null);
    setIsCreateSenderBtnLoading(false);
  };

  const deleteSenderFun = async (id: string) => {
    const response = await executeDeleteSender({
      variables: {
        id: id,
      },
    });
    if (response?.data) {
      setIsSenderLoading(true);
      setSenderList([]);
      const lastPageEle =
        (paginationProp?.total || 0) % (paginationProp?.pageSize || 0) === 1 &&
        (paginationProp?.total || 0) > 10;
      if (lastPageEle) {
        setPaginationProp({
          ...paginationProp,
          current: (paginationProp?.current || 1) - 1,
        });
      }
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId as string,
          type: ProviderType.Email,
          filter: {
            skip: lastPageEle
              ? ((paginationProp?.current || 1) - 1) *
                  (paginationProp?.pageSize || 0) -
                (paginationProp?.pageSize || 0)
              : ((paginationProp?.current || 1) - 1) *
                (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: senderSearchTerm,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
    }
  };

  const handleVerification = (record: Sender) => {
    executeVerifySender({
      variables: {
        id: record?.id,
        reVerify: false,
      },
    });
  };

  const handleReVerify = async (record: Sender) => {
    await executeVerifySender({
      variables: {
        id: record?.id,
        reVerify: true,
      },
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    tablefilter: Record<string, FilterValue | null>,
    sorter: SorterResult<Sender>,
  ) => {
    const { current } = pagination;
    const skip = ((current || 1) - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsSenderLoading(true);
    setSenderList([]);
    if (sorter?.column) {
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId as string,
          type: ProviderType.Email,
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: senderSearchTerm,
            sortBy:
              sorter?.order === 'ascend'
                ? (`${sorter?.field}${SORT.ASC}` as SenderSortBy)
                : (`${sorter?.field}${SORT.DESC}` as SenderSortBy),
          },
        },
      });
    } else {
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId as string,
          type: ProviderType.Email,
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: senderSearchTerm,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
    }
  };

  const handleSearch = (value: string) => {
    setIsSenderSearchLoading(true);
    const trimValue = value?.trim();
    setSenderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1 });
    setIsSenderLoading(true);
    setSenderList([]);
    executeSenderList({
      variables: {
        projectEnvId: projectEnvId as string,
        type: ProviderType.Email,
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          sortBy: SenderSortBy.CreatedAtDesc,
        },
      },
    });
    setIsAllowClear(false);
  };

  const AddSenderFormsItems: FormFieldConfig[] = [
    {
      title: 'Email',
      name: 'email',
      placeholder: 'Enter email address',
      rules: [{ required: true, message: 'Please input email!' }, email],
    },
    {
      title: 'Name',
      name: 'name',
      placeholder: 'Enter name',
    },
  ];

  const columns: TableColumnsType<Sender> = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: Sender, index: number) => {
        return (
          <span>
            {(paginationProp?.pageSize || 0) * (paginationProp?.current || 1) -
              (paginationProp?.pageSize || 0) +
              index +
              1}
          </span>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: Sender) => {
        return record?.name ? record?.name : '-';
      },
    },
    {
      title: 'EMAIl',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
      width: 250,
      align: 'left' as const,

      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' ? sortedInfo?.order : null,
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      width: 250,
      align: 'left' as const,

      className: 'max-width-column',
      sortOrder:
        sortedInfo?.columnKey === 'createdAt' ? sortedInfo?.order : null,
      render: (_: string, record: Sender) => {
        return formatDate(record?.createdAt, 'MMM DD, YYYY hh:mm A');
      },
    },
    {
      title: 'VERIFICATION STATUS',
      dataIndex: 'verification_status',
      key: 'verification_status',
      hidden: false,
      render: (_: string, record: Sender) => {
        return (
          <>
            {record?.isVerified === true ? (
              <Button
                type="primary"
                className="primary-button verify-status verified-success pointer-none"
                disabled
              >
                Verified
              </Button>
            ) : (
              <>
                <Button
                  type="primary"
                  className="primary-button verify-status verification"
                  onClick={() => handleVerification(record)}
                >
                  Check Status
                </Button>
                <Button
                  type="primary"
                  className="secondary-button verify-status"
                  onClick={() => handleReVerify(record)}
                >
                  Re-verifiy
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_: string, record: Sender) => {
        return (
          <>
            <div className="action-button">
              <Button
                type="link"
                onClick={() => {
                  setIsEditSenderRowData(record);
                  setCreateSenderModalOpen(true);
                  setIsEditSenderModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${record?.email}?`}
                onConfirm={() => deleteSenderFun(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ]?.filter((item) => {
    if (
      (userRole === USERS_ROLE?.READ_ONLY && item?.dataIndex === 'actions') ||
      (userRole === USERS_ROLE?.READ_ONLY &&
        item?.dataIndex === 'verification_status')
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptySenderList ? '' : <span />,
  };

  return (
    <>
      <CreateSenderModal
        initialValues={isEditSenderRowData}
        isModalOpen={isCreateSenderModalOpen}
        setIsModalOpen={setCreateSenderModalOpen}
        formTitle={isEditSenderModal ? 'Update Sender' : 'Add Sender'}
        onFinish={createSenderFun}
        onUpdate={updateSenderFun}
        isEdit={isEditSenderModal}
        setIsEdit={setIsEditSenderModal}
        setIsEditSenderRowData={setIsEditSenderRowData}
        formItems={AddSenderFormsItems}
        submitButton={isEditSenderModal ? 'Update' : 'Add'}
        loadings={isCreateSenderBtnLoading}
        form={form}
        handleReset={handleReset}
      />
      <div className="sender">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            {(senderList && senderList?.length > 0) ||
            senderSearchTerm ||
            isSenderSearchLoading ||
            isAllowClear ? (
              <SearchWithLoading
                setIsAllowClear={setIsAllowClear}
                query={senderSearchTerm}
                setQuery={setSenderSearchTerm}
                getData={handleSearch}
              />
            ) : null}
          </div>
          {(senderList && senderList?.length > 0) ||
          senderSearchTerm ||
          isSenderSearchLoading ||
          isAllowClear ? (
            <>
              {(userRole === USERS_ROLE?.OWNER ||
                userRole === USERS_ROLE?.WRITE) && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="primary-button"
                  onClick={() => {
                    setCreateSenderModalOpen(true);
                  }}
                >
                  Create New Sender
                </Button>
              )}
            </>
          ) : null}
        </div>
        <div className="mt-30 flex-vertical">
          {isSenderLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isSenderLoading}
            />
          ) : (
            <>
              {(senderList && senderList?.length > 0) ||
              senderSearchTerm ||
              isSenderSearchLoading ||
              isAllowClear ? (
                <CommonTable
                  locale={locale}
                  columns={columns}
                  data={
                    senderList?.filter(
                      (sender): sender is Sender => !!sender,
                    ) || []
                  }
                  loadingData={isSenderLoading}
                  onChange={handleTableChange}
                  paginationConfig={paginationProp}
                  rowKey="id"
                />
              ) : (
                <div className="analytics-illustration">
                  <Nodata />
                  <br />
                  {(userRole === USERS_ROLE?.OWNER ||
                    userRole === USERS_ROLE?.WRITE) && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="primary-button"
                      onClick={() => {
                        setCreateSenderModalOpen(true);
                      }}
                    >
                      Create New Sender
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Senders;
