import { Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { USERS_ROLE } from '../../../common/constants';
import { AppContextType } from '../../../types/appContext.type';
import CreateEditPlatformForm from './CreateEditPlatformForm';
import PlatformList from './PlatformList';

const { Step } = Steps;

const PlatformSteps = ({ activeKey }: { activeKey: string }) => {
  const [current, setCurrent] = useState<number>(0);
  const [providerId, setProviderId] = useState<string>('');
  const [providerValue, setProviderValue] = useState<string>('');
  const [providerSlug, setProviderSlug] = useState<string>('');
  const { getCurrentRole } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();

  const steps = [
    {
      title: 'Select Platform Provider',
      content: (
        <PlatformList
          providerId={providerId}
          setProviderId={setProviderId}
          setProviderValue={setProviderValue}
          setProviderSlug={setProviderSlug}
          activeKey={activeKey}
        />
      ),
    },
    {
      title: 'Add Configuration',
      content: (
        <CreateEditPlatformForm
          current={current}
          providerId={providerId}
          providerValue={providerValue}
          setCurrent={setCurrent}
          setProviderId={setProviderId}
          providerSlug={providerSlug}
        />
      ),
    },
  ];

  useEffect(() => {
    if (providerId && current < steps?.length - 1) {
      if (userRole === USERS_ROLE?.OWNER || userRole === USERS_ROLE?.WRITE) {
        const nextCurrent = current + 1;
        setCurrent(nextCurrent);
      }
    }
  }, [current, providerId, steps.length, userRole]);

  const onChange = (value: number) => {
    setProviderId('');
    setCurrent(value);
  };

  return (
    <div>
      {current !== 0 && (
        <div>
          <Steps current={current} onChange={onChange} className="sb-stepper">
            {steps?.map((item) => (
              <Step key={item?.title} title={item?.title} />
            ))}
          </Steps>
        </div>
      )}
      <div className="steps-content">{steps?.[current]?.content}</div>
    </div>
  );
};

export default PlatformSteps;
