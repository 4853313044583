import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PROJECT_ENV } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { ProjectEnvironment } from '../../../gql/graphql';
import { PROJECT_ENVIRONMENT } from '../../projects/graphql/Queries';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProjectEnvSelect = (props: any) => {
  const { projectId } = useParams();
  const { ...rest } = props;
  const [projectEnvList, setProjectEnvList] = useState<
    (ProjectEnvironment | null)[] | null
  >([]);
  const [envValue, setEnvValue] = useState<string | null>(null);

  const [executeProjectEnvironment, { loading: isProjectEnvLoading }] =
    useLazyQuery(PROJECT_ENVIRONMENT, {
      onCompleted: (response) => {
        setProjectEnvList([
          ...(projectEnvList || []),
          ...(response?.projectEnvironment?.environments || []),
        ]);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    });

  useEffect(() => {
    setProjectEnvList([]);
    executeProjectEnvironment({
      variables: {
        projectId: projectId as string,
      },
    });
    // eslint-disable-next-line no-undef
    setEnvValue(localStorage.getItem(PROJECT_ENV));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOption = (input: string, option: { children: string }) =>
    (option?.children ?? '')?.toLowerCase()?.includes(input?.toLowerCase());

  return (
    <SelectComponent
      showSearch
      loading={isProjectEnvLoading}
      placeholder="Select Project Environment"
      optionFilterProp="children"
      filterOption={filterOption}
      notFoundContent={
        isProjectEnvLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {projectEnvList
        ?.filter((projectEnv) => projectEnv?.id !== envValue)
        ?.map((projectEnv) => (
          <Option key={projectEnv?.id} value={projectEnv?.id}>
            {projectEnv?.environment}
          </Option>
        ))}
    </SelectComponent>
  );
};

export default ProjectEnvSelect;
