import { gql } from '../../../__generated__';

export const SEND_OTP = gql(`
  mutation sendOtp($email: String!) {
    sendOtp(email: $email) {
      message
    }
  }
`);

export const VERIFY_OTP = gql(`
  mutation verifyOtp($data: loginData!) {
    verifyOtp(data: $data) {
      data {
        id
        firstName
        lastName
        email
        profileImage
        message
      }
      token
      refreshToken
    }
  }
`);

export const LOGOUT_USER = gql(`
  mutation logout {
    logout {
      message
    }
  }
`);

export const REFRESH_TOKEN = gql(`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      token
    }
  }
`);
