import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, message, Popconfirm, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TemplatesResponse } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { ROUTES, SORT, USERS_ROLE } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import { ProviderType, Template, TemplateSortBy } from '../../gql/graphql';
import { AppContextType } from '../../types/appContext.type';
import { FormFieldConfig } from '../../types/common.type';
import CopyTemplateModal from './components/CopyTemplateModal';
import EditBodyModal from './components/EditBodyModal';
import PreviewModal from './components/PreviewModal';
import TemplateModal from './components/TemplateModal';
import {
  COPY_TEMPLATES,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
} from './graphql/Mutation';
import { TEMPLATES } from './graphql/Queries';
import './template.less';

const { textSpecialChar } = formValidatorRules;

const initialPaginationValue: TablePaginationConfig = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const Templates = ({ activeKey }: { activeKey: string }) => {
  const [form] = Form.useForm();
  const [copyTemplateForm] = Form.useForm();
  const { id } = useParams();
  const [isCopyTemplateLoading, setCopyTemplateLoading] =
    useState<boolean>(false);

  const {
    state: { projectEnvId },
    getCurrentRole,
  } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const { pathname } = useLocation();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Template>>({});
  const [isEmptyTemplateList, setIsEmptyTemplateList] =
    useState<boolean>(false);
  const [paginationProp, setPaginationProp] = useState<TablePaginationConfig>(
    initialPaginationValue,
  );

  let emailKey: ProviderType | null = null;
  const [isEditBody, setIsEditBody] = useState<boolean>(false);
  const [templateSearchTerm, setTemplateSearchTerm] = useState<string>('');
  const [isAddTemplateModalOpen, setAddTemplateModalOpen] =
    useState<boolean>(false);
  const [isTemplateBody, setIsTemplateBody] = useState<string | null>();
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [editRowData, setEditRowData] = useState<Template | null>(null);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [templateList, setTemplateList] = useState<
    TemplatesResponse['templates']
  >([]);
  const [isCreateEditTemplateBtnLoading, setIsCreateEditTemplateBtnLoading] =
    useState<boolean>(false);
  const [isEditBodyModalOpen, setIsEditBodyModalOpen] =
    useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const [isTemplateSearchLoading, setIsTemplateSearchLoading] =
    useState<boolean>(false);

  const [isAllowClear, setIsAllowClear] = useState<boolean>(false);
  const [isCopyTemplateModalOpen, setCopyTemplateModalOpen] =
    useState<boolean>(false);

  const limit = 10;

  const [executeTemplate] = useLazyQuery(TEMPLATES, {
    onCompleted: (response) => {
      setTemplateList(response?.templates?.templates);

      if (
        response?.templates?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyTemplateList(true);
      }
      const pagination: TablePaginationConfig = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.templates?.count || 0,
      };
      setPaginationProp(pagination);
      setLoadings(false);
      setIsTemplateSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setLoadings(false);
    },
  });

  const [addTemplate] = useMutation(CREATE_TEMPLATE, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeDeleteTemplate] = useMutation(DELETE_TEMPLATE, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });
  const [executeCopyTemplate] = useMutation(COPY_TEMPLATES, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  useEffect(() => {
    setLoadings(true);
    setTemplateList([]);
    if (activeKey === ROUTES?.TEMPLATES) {
      executeTemplate({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            projectEnvId: projectEnvId as string,
            sortBy: TemplateSortBy.CreatedAtDesc,
            type: ProviderType.Email,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, activeKey]);

  useEffect(() => {
    if (isEditBody) {
      setLoadings(true);
      executeTemplate({
        variables: {
          filter: {
            skip:
              ((paginationProp?.current || 1) - 1) *
              (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            projectEnvId: projectEnvId as string,
            sortBy: TemplateSortBy.CreatedAtDesc,
            type: ProviderType.Email,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditBody]);

  useEffect(() => {
    setIsEditBody(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleReset = () => {
    setIsEditModal(false);
    form?.resetFields();
    setEditRowData(null);
  };

  const onFinish = (values: {
    title: string;
    subject: string;
    key: string;
  }) => {
    if (`/${pathname?.split('/')[3]}` === ROUTES?.EMAIL) {
      emailKey = ProviderType.Email;
    }
    if (isEditModal && editRowData?.id) {
      setIsCreateEditTemplateBtnLoading(true);
      updateTemplate({
        variables: {
          data: {
            title: values?.title?.trim(),
            subject: values?.subject?.trim(),
            projectEnvId: projectEnvId as string,
            type: emailKey as ProviderType,
          },
          id: editRowData?.id,
        },
      })
        .then(() => {
          setLoadings(true);
          setAddTemplateModalOpen(false);
          setIsCreateEditTemplateBtnLoading(false);
          setEditRowData(null);
          setIsEditModal(false);
          executeTemplate({
            variables: {
              filter: {
                skip:
                  ((paginationProp?.current || 1) - 1) *
                  (paginationProp?.pageSize || 0),
                limit: paginationProp?.pageSize || limit,
                search: templateSearchTerm,
                projectEnvId: projectEnvId as string,
                sortBy: TemplateSortBy.CreatedAtDesc,
                type: ProviderType.Email,
              },
            },
          });
          // eslint-disable-next-line no-undef
          setTimeout(handleReset, 500);
        })
        .catch((error) => {
          setLoadings(false);
          return error;
        });
    } else {
      setIsCreateEditTemplateBtnLoading(true);
      addTemplate({
        variables: {
          data: {
            key: values?.key?.trim(),
            title: values?.title?.trim(),
            subject: values?.subject?.trim(),
            projectEnvId: projectEnvId as string,
            type: emailKey as ProviderType,
          },
        },
      })
        .then(() => {
          setAddTemplateModalOpen(false);
          setIsCreateEditTemplateBtnLoading(false);
          setLoadings(true);
          setEditRowData(null);
          setIsEditModal(false);
          setPaginationProp({ ...paginationProp, current: 1 });
          setTemplateSearchTerm('');
          executeTemplate({
            variables: {
              filter: {
                skip: 0,
                limit: paginationProp?.pageSize || limit,
                projectEnvId: projectEnvId as string,
                sortBy: TemplateSortBy.CreatedAtDesc,
                type: ProviderType.Email,
              },
            },
          });
          // eslint-disable-next-line no-undef
          setTimeout(handleReset, 500);
        })
        .catch((error) => {
          setLoadings(false);
          return error;
        });
    }
  };

  const saveTemplateBody = (values: {
    body?: string | null;
    design?: string | null;
  }) => {
    if (`/${pathname?.split('/')[3]}` === ROUTES?.EMAIL) {
      emailKey = ProviderType.Email;
    }
    setLoadings(true);
    updateTemplate({
      variables: {
        data: {
          title: editRowData?.title?.trim() || '',
          subject: editRowData?.subject?.trim() || '',
          projectEnvId: projectEnvId as string,
          type: emailKey as ProviderType,
          body: values?.body,
          design: values?.design,
        },
        id: editRowData?.id as string,
      },
    })
      .then(() => {
        setLoadings(false);
        setEditRowData(null);
        setAddTemplateModalOpen(false);
        setIsEditModal(false);
        executeTemplate({
          variables: {
            filter: {
              skip:
                ((paginationProp?.current || 1) - 1) *
                (paginationProp?.pageSize || 0),
              limit: paginationProp?.pageSize || limit,
              projectEnvId: projectEnvId as string,
              sortBy: TemplateSortBy.CreatedAtDesc,
              type: ProviderType.Email,
            },
          },
        });
        handleReset();
      })
      .catch((error) => {
        setLoadings(false);
        return error;
      });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    tablefilter: Record<string, FilterValue | null>,
    sorter: SorterResult<Template>,
  ) => {
    const { current } = pagination;
    const skip = ((current || 1) - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setLoadings(true);
    setTemplateList([]);
    if (sorter?.column) {
      executeTemplate({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize || limit,
            search: templateSearchTerm,
            projectEnvId: projectEnvId as string,
            sortBy:
              sorter?.order === 'ascend'
                ? (`${sorter?.field}${SORT.ASC}` as TemplateSortBy)
                : (`${sorter?.field}${SORT.DESC}` as TemplateSortBy),
            type: ProviderType.Email,
          },
        },
      });
    } else {
      executeTemplate({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize || limit,
            search: templateSearchTerm,
            projectEnvId: projectEnvId as string,
            sortBy: TemplateSortBy?.CreatedAtDesc,
            type: ProviderType.Email,
          },
        },
      });
    }
  };

  const onDeleteData = async (record: Template | null) => {
    if (record?.id) {
      const response = await executeDeleteTemplate({
        variables: {
          id: record?.id,
        },
      });
      if (response?.data) {
        setLoadings(true);
        setEditRowData(null);
        setAddTemplateModalOpen(false);
        setIsEditModal(false);
        const lastPageEle =
          (paginationProp?.total || 0) % (paginationProp?.pageSize || 1) ===
            1 && (paginationProp?.total || 0) > 10;
        if (lastPageEle) {
          setPaginationProp({
            ...paginationProp,
            current: (paginationProp?.current || 1) - 1,
          });
        }
        executeTemplate({
          variables: {
            filter: {
              skip: lastPageEle
                ? ((paginationProp?.current || 1) - 1) *
                    (paginationProp?.pageSize || 0) -
                  (paginationProp?.pageSize || 0)
                : ((paginationProp?.current || 1) - 1) *
                  (paginationProp?.pageSize || 0),
              limit: paginationProp?.pageSize || limit,
              search: templateSearchTerm,
              projectEnvId: projectEnvId as string,
              sortBy: TemplateSortBy?.CreatedAtDesc,
              type: ProviderType.Email,
            },
          },
        });
      }
    } else {
      message.error('Data to be deleted not found');
    }
  };

  const handleSearch = (value: string) => {
    setIsTemplateSearchLoading(true);
    const trimValue = value?.trim() || '';
    setTemplateSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1 });
    setLoadings(true);
    setTemplateList([]);
    executeTemplate({
      variables: {
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          projectEnvId: projectEnvId as string,
          sortBy: TemplateSortBy?.CreatedAtDesc,
          type: ProviderType.Email,
        },
      },
    });
    setIsAllowClear(false);
  };

  const AddTemplateFormsItems: FormFieldConfig[] = [
    {
      title: 'Key',
      name: 'key',
      placeholder: 'Enter template key',
      rules: [{ required: true, message: 'Please input template key!' }],
    },
    {
      title: 'Title',
      name: 'title',
      placeholder: 'Enter template title',
      rules: [
        { required: true, message: 'Please input template title!' },
        textSpecialChar,
      ],
    },
    {
      title: 'Subject',
      name: 'subject',
      placeholder: 'Enter template subject',
      rules: [{ required: true, message: 'Please input template subject!' }],
    },
  ];

  const columns: ColumnType<Template>[] = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: Template | null, index: number) => {
        return (
          <span>
            {(paginationProp?.pageSize || 0) * (paginationProp?.current || 1) -
              (paginationProp?.pageSize || 0) +
              index +
              1}
          </span>
        );
      },
    },
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      sorter: true,
      ellipsis: true,
      width: 200,
      align: 'left' as const,

      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'key' ? sortedInfo?.order : null,
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: 200,
      align: 'left' as const,

      className: 'max-width-column',
    },
    {
      title: 'SUBJECT',
      dataIndex: 'subject',
      key: 'subject',
      ellipsis: true,
      width: 200,
      align: 'left' as const,

      className: 'max-width-column',
    },
    {
      title: 'BODY',
      dataIndex: 'body',
      key: 'body',
      hidden: false,
      render: (_: string, record: Template | null) => {
        return (
          <>
            {!record?.body ? (
              <Button
                type="primary"
                className="secondary-button edit-body"
                onClick={() => {
                  setIsEditBodyModalOpen(true);
                  setEditRowData(record);
                  setIsTemplateBody(record?.body);
                  setIsEditBody(true);
                }}
              >
                Add Body
              </Button>
            ) : (
              <div className="d-flex gap-16 justify-center align-center">
                <Button
                  className="primary-button edit-body"
                  onClick={() => {
                    setIsEditBodyModalOpen(true);
                    setEditRowData(record);
                    setIsTemplateBody(record?.body);
                    setIsEditBody(true);
                  }}
                >
                  Edit Body
                </Button>
                {record?.body && (
                  <Button
                    className="primary-button edit-body"
                    onClick={() => {
                      setIsPreviewModalOpen(true);
                      setIsTemplateBody(record?.body);
                    }}
                  >
                    Preview
                  </Button>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      hidden: false,
      render: (_: string, record: Template | null) => {
        return (
          <>
            <div className="action-button">
              <Button
                type="link"
                onClick={() => {
                  setEditRowData(record);
                  setAddTemplateModalOpen(true);
                  setIsEditModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${record?.title}?`}
                onConfirm={() => onDeleteData(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ]?.filter((item) => {
    if (
      userRole === USERS_ROLE?.READ_ONLY &&
      (item?.dataIndex === 'actions' || item?.dataIndex === 'body')
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyTemplateList ? '' : <span />,
  };

  const handleCopyTemplate = async (values: {
    projectEnv: string;
    template: string[];
  }) => {
    setCopyTemplateLoading(true);
    const res = await executeCopyTemplate({
      variables: {
        data: {
          projectEnvId: values?.projectEnv,
          templateIds: values?.template,
        },
      },
    });
    if (res?.data) {
      copyTemplateForm.resetFields();
      setCopyTemplateModalOpen(false);
    }
    setCopyTemplateLoading(false);
  };

  const handleCancel = () => {
    copyTemplateForm.resetFields();
    setCopyTemplateModalOpen(false);
  };

  return (
    <>
      <CopyTemplateModal
        form={copyTemplateForm}
        isModalOpen={isCopyTemplateModalOpen}
        onFinish={handleCopyTemplate}
        isLoading={isCopyTemplateLoading}
        handleCancel={handleCancel}
      />
      <TemplateModal
        initialValues={editRowData}
        isModalOpen={isAddTemplateModalOpen}
        setIsModalOpen={setAddTemplateModalOpen}
        formTitle={isEditModal ? 'Edit Template' : 'Add Template'}
        onFinish={onFinish}
        formItems={AddTemplateFormsItems}
        submitButton={isEditModal ? 'Update' : 'Add'}
        loadings={isCreateEditTemplateBtnLoading}
        form={form}
        handleReset={handleReset}
      />
      <div className="template">
        {isEditBody ? (
          <EditBodyModal
            isModalOpen={isEditBodyModalOpen}
            setIsModalOpen={setIsEditBodyModalOpen}
            setIsEditBody={setIsEditBody}
            isTemplateBody={isTemplateBody}
            saveTemplateBody={saveTemplateBody}
            handleReset={handleReset}
            editRowData={editRowData}
          />
        ) : (
          <>
            <div className="d-flex justify-between">
              <div className="width-percent-40">
                {(templateList && templateList?.length > 0) ||
                templateSearchTerm ||
                isTemplateSearchLoading ||
                isAllowClear ? (
                  <SearchWithLoading
                    setIsAllowClear={setIsAllowClear}
                    query={templateSearchTerm}
                    setQuery={setTemplateSearchTerm}
                    getData={handleSearch}
                  />
                ) : null}
              </div>
              <div>
                {templateList && templateList?.length > 0 ? (
                  <>
                    {(userRole === USERS_ROLE?.OWNER ||
                      userRole === USERS_ROLE?.WRITE) && (
                      <Button
                        type="primary"
                        className="primary-button"
                        onClick={() => setCopyTemplateModalOpen(true)}
                      >
                        Copy Templates
                      </Button>
                    )}
                  </>
                ) : null}
                {(templateList && templateList?.length > 0) ||
                templateSearchTerm ||
                isTemplateSearchLoading ||
                isAllowClear ? (
                  <>
                    {(userRole === USERS_ROLE?.OWNER ||
                      userRole === USERS_ROLE?.WRITE) && (
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        className="primary-button ml-10"
                        onClick={() => setAddTemplateModalOpen(true)}
                      >
                        Create New Templateddsd
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div className="mt-30 flex-vertical">
              {loadings ? (
                <LoaderComponent
                  size="large"
                  setHeight="60"
                  spinning={loadings}
                />
              ) : (
                <>
                  {(templateList && templateList?.length > 0) ||
                  templateSearchTerm ||
                  isTemplateSearchLoading ||
                  isAllowClear ? (
                    <CommonTable<Template>
                      locale={locale}
                      loadingData={loadings}
                      columns={columns}
                      data={
                        templateList?.filter(
                          (template): template is Template => !!template,
                        ) || []
                      }
                      onChange={(
                        pagination: TablePaginationConfig,
                        tablefilter: Record<string, FilterValue | null>,
                        sorter: SorterResult<Template>,
                      ) => handleTableChange(pagination, tablefilter, sorter)}
                      paginationConfig={paginationProp}
                      rowKey="id"
                    />
                  ) : (
                    <div className="analytics-illustration">
                      <Nodata />
                      <br />
                      {templateList && templateList.length > 0 ? null : (
                        <>
                          {(userRole === USERS_ROLE?.OWNER ||
                            userRole === USERS_ROLE?.WRITE) && (
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              className="primary-button"
                              onClick={() => setAddTemplateModalOpen(true)}
                            >
                              Create New Template
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {isPreviewModalOpen && (
              <PreviewModal
                isModalOpen={isPreviewModalOpen}
                setIsModalOpen={setIsPreviewModalOpen}
                isTemplateBody={isTemplateBody}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Templates;
