import { useLazyQuery } from '@apollo/client';
import { Button, Form, Input, Modal, Radio, Select, Space, Tag } from 'antd';
import { Rule } from 'antd/es/form';
import { debounce } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { CloseCircle } from '../../../assets/svg';
import { REGEX } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import {
  MessageLogs,
  ProviderType,
  Sender,
  SenderSortBy,
  TemplateSortBy,
  TemplatesResponse,
} from '../../../gql/graphql';
import { AppContextType } from '../../../types/appContext.type';
import {
  CommonModalProps,
  FormFieldConfig,
  SendMailFormValues,
} from '../../../types/common.type';
import { SENDERS } from '../../senders/graphql/Queries';
import { TEMPLATES } from '../../template/graphql/Queries';

const { Option } = Select;

type SendRawEmailModalPropTypes = CommonModalProps & {
  initialValues?: MessageLogs | null;
  formTitle: string;
  formItems: FormFieldConfig[];
  // eslint-disable-next-line no-unused-vars
  onFinish: (values: SendMailFormValues) => void;
  submitButton: string;
  loadings: boolean;
};
const SendRawEmailModal = ({
  isModalOpen,
  setIsModalOpen,
  initialValues,
  formTitle,
  formItems,
  onFinish,
  submitButton,
  loadings,
}: SendRawEmailModalPropTypes) => {
  const [form] = Form.useForm();
  const {
    state: { projectEnvId },
  } = useContext(AppContext) as AppContextType;
  const [radioButtonValue, setRadioButtonValue] = useState<number>(1);
  const [templateList, setTemplateList] =
    useState<TemplatesResponse['templates']>();
  const [senderList, setSenderList] = useState<(Sender | null)[] | null>([]);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [templateSearchTerm, setTemplateSearchTerm] = useState<string>('');
  const [isTemplateAllowClear, setIsTemplateAllowClear] =
    useState<boolean>(false);
  const [isAllTemplatesFetched, setIsAllTemplatesFetched] =
    useState<boolean>(false);
  const [isFetchMoreTemplatesLoading, setIsFetchMoreTemplatesLoading] =
    useState<boolean>(false);
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] =
    useState<boolean>(false);
  const [isValidateTrigger, setIsValidateTrigger] = useState<boolean>(false);

  const [senderSearchTerm, setSenderSearchTerm] = useState<string>('');
  const [isSenderDropdownVisible, setIsSenderDropdownVisible] =
    useState<boolean>(false);
  const [isAllSendersFetched, setIsAllSendersFetched] =
    useState<boolean>(false);
  const [isSenderAllowClear, setIsSenderAllowClear] = useState<boolean>(false);
  const [isFetchMoreSendersLoading, setIsFetchMoreSendersLoading] =
    useState<boolean>(false);

  const limit = 10;

  const [executeTemplateList, { loading: isTemplatesLoading }] = useLazyQuery(
    TEMPLATES,
    {
      onCompleted: (response) => {
        if (
          response?.templates?.templates &&
          response?.templates?.templates?.length < limit
        ) {
          setIsAllTemplatesFetched(true);
        }
        setIsFetchMoreTemplatesLoading(false);
        setTemplateList([
          ...(templateList || []),
          ...(response?.templates?.templates || []),
        ]);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    },
  );

  const [executeSenderList, { loading: isSendersLoading }] = useLazyQuery(
    SENDERS,
    {
      onCompleted: (response) => {
        if (
          response?.senders?.senders &&
          response?.senders?.senders?.length < limit
        ) {
          setIsAllSendersFetched(true);
        }
        setIsFetchMoreSendersLoading(false);
        setSenderList([
          ...(senderList || []),
          ...(response?.senders?.senders || []),
        ]);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    },
  );

  useEffect(() => {
    if (projectEnvId && !isTemplateDropdownVisible && isModalOpen) {
      form?.setFieldsValue(initialValues);
      setTemplateSearchTerm('');
      setTemplateList([]);
      executeTemplateList({
        variables: {
          filter: {
            skip: 0,
            limit,
            projectEnvId: projectEnvId,
            sortBy: TemplateSortBy.CreatedAtDesc,
          },
        },
      });
      setIsAllTemplatesFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, isTemplateDropdownVisible, isModalOpen]);

  useEffect(() => {
    if (projectEnvId && !isSenderDropdownVisible && isModalOpen) {
      setSenderSearchTerm('');
      setSenderList([]);
      executeSenderList({
        variables: {
          projectEnvId: projectEnvId,
          type: ProviderType.Email,
          filter: {
            skip: 0,
            sortBy: SenderSortBy.CreatedAtDesc,
          },
        },
      });
      setIsAllSendersFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, isSenderDropdownVisible, isModalOpen]);

  const handleTemplateScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!isAllTemplatesFetched && !isTemplatesLoading) {
      const target = event?.currentTarget;
      const currentLength = templateList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreTemplatesLoading(true);
        executeTemplateList({
          variables: {
            filter: {
              skip: currentLength,
              limit,
              projectEnvId: projectEnvId as string,
              search: templateSearchTerm,
              sortBy: TemplateSortBy.CreatedAtDesc,
            },
          },
        });
      }
    }
  };

  const handleSenderScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!isAllSendersFetched && !isSendersLoading) {
      const target = event?.currentTarget;
      const currentLength = senderList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreSendersLoading(true);
        executeSenderList({
          variables: {
            projectEnvId: projectEnvId as string,
            type: ProviderType.Email,
            filter: {
              skip: currentLength,
              limit,
              search: senderSearchTerm,
              sortBy: SenderSortBy.CreatedAtDesc,
            },
          },
        });
      }
    }
  };

  const handleTemplateSearch = (searchTerm: string) => {
    const trimSearch = searchTerm?.trim();
    setIsAllTemplatesFetched(false);
    setTemplateList([]);
    setTemplateSearchTerm(trimSearch);
    executeTemplateList({
      variables: {
        filter: {
          skip: 0,
          limit,
          projectEnvId: projectEnvId as string,
          search: templateSearchTerm,
          sortBy: TemplateSortBy.CreatedAtDesc,
        },
      },
    });
  };

  const handleSenderSearch = (searchTerm: string) => {
    const trimSearch = searchTerm?.trim();
    setIsAllSendersFetched(false);
    setSenderList([]);
    setSenderSearchTerm(trimSearch);
    executeSenderList({
      variables: {
        projectEnvId: projectEnvId as string,
        type: ProviderType.Email,
        filter: {
          skip: 0,
          limit,
          search: trimSearch,
          sortBy: SenderSortBy.CreatedAtDesc,
        },
      },
    });
  };

  const handleTemplateScrollDebounce = debounce(handleTemplateScroll, 500);
  const handleSearchTemplateDebounce = debounce(handleTemplateSearch, 500);

  const handleSenderScrollDebounce = debounce(handleSenderScroll, 500);
  const handleSearchSenderDebounce = debounce(handleSenderSearch, 500);

  useEffect(() => {
    return () => {
      handleTemplateScrollDebounce?.cancel();
    };
  }, [handleTemplateScrollDebounce]);

  useEffect(() => {
    return () => {
      handleSearchTemplateDebounce?.cancel();
    };
  }, [handleSearchTemplateDebounce]);

  useEffect(() => {
    return () => {
      handleSenderScrollDebounce?.cancel();
    };
  }, [handleSenderScrollDebounce]);

  useEffect(() => {
    return () => {
      handleSearchSenderDebounce?.cancel();
    };
  }, [handleSearchSenderDebounce]);

  useEffect(() => {
    if (isTemplatesLoading) {
      setIsTemplateAllowClear(false);
    } else {
      setIsTemplateAllowClear(true);
    }
  }, [isTemplatesLoading]);

  useEffect(() => {
    if (isSendersLoading) {
      setIsSenderAllowClear(false);
    } else {
      setIsSenderAllowClear(true);
    }
  }, [isSendersLoading]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  const onRadioChange = (e: { target: { value?: number } }) => {
    setRadioButtonValue(e.target.value || 0);
  };

  useEffect(() => {
    form.setFieldsValue({
      select_existing_template: radioButtonValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailValidator = (rule: Rule, values: string[]) => {
    const invalidInputs = values?.filter(
      (value) => !value?.match(REGEX?.EMAIL),
    );
    setInvalidEmails(invalidInputs);
    if (!values || values?.length === 0) {
      return Promise?.reject(new Error(`Please enter recipient!`));
    }
    if (invalidInputs?.length === 1) {
      return Promise?.reject(
        new Error(`${invalidInputs?.join('')} is not a valid email`),
      );
    }
    if (invalidInputs?.length > 1) {
      return Promise?.reject(
        new Error(
          `${invalidInputs?.slice(0, -1).join(', ')} and ${invalidInputs?.slice(
            -1,
          )} are not valid emails`,
        ),
      );
    }
    return Promise?.resolve();
  };

  const onPreventMouseDown = (e: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <>
      <Modal
        centered
        closeIcon={<CloseCircle />}
        wrapClassName="environment-modal channel-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        onOk={form?.submit}
        forceRender
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <div className="send-raw-email add-container">
            <div className="d-flex flex-horizontal justify-between">
              <p className="add-title">{formTitle}</p>
            </div>
            <div className="d-flex flex-vertical justify-between ">
              <Form.Item
                label="Select Sender"
                name="senders_email"
                rules={[
                  {
                    required: true,
                    message: 'Please select sender!',
                  },
                ]}
              >
                <SelectComponent
                  showSearch
                  loading={
                    (isSendersLoading && isSenderDropdownVisible) ||
                    isFetchMoreSendersLoading
                  }
                  placeholder="Select sender"
                  optionFilterProp="children"
                  allowClear={isSenderAllowClear}
                  onPopupScroll={handleSenderScrollDebounce}
                  onSearch={handleSearchSenderDebounce}
                  onDropdownVisibleChange={(visible) => {
                    setIsSenderDropdownVisible(visible);
                  }}
                  filterOption={false}
                  notFoundContent={
                    !isFetchMoreSendersLoading && isSendersLoading ? (
                      <span>Loading...</span>
                    ) : (
                      <span>No user available</span>
                    )
                  }
                >
                  {senderList && senderList?.length > 0 && (
                    <>
                      {senderList?.map((sender) => {
                        return (
                          <Option key={sender?.id} value={sender?.id}>
                            {sender?.email}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </SelectComponent>
              </Form.Item>
              <Form.Item
                label="Select Existing Template or Send New Email"
                name="select_existing_template"
                rules={[
                  {
                    required: true,
                    message: 'Please select any one!',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => onRadioChange(e)}
                  value={radioButtonValue}
                >
                  <Radio value={1}>Existing Template</Radio>
                  <Radio value={2}>Send New Email</Radio>
                </Radio.Group>
              </Form.Item>
              {radioButtonValue === 1 ? (
                <Form.Item
                  label="Select Template"
                  name="select_template"
                  rules={[
                    {
                      required: true,
                      message: 'Please select template!',
                    },
                  ]}
                >
                  <SelectComponent
                    showSearch
                    loading={
                      (isTemplatesLoading && isTemplateDropdownVisible) ||
                      isFetchMoreTemplatesLoading
                    }
                    placeholder="Select template"
                    optionFilterProp="children"
                    allowClear={isTemplateAllowClear}
                    onPopupScroll={handleTemplateScrollDebounce}
                    onSearch={handleSearchTemplateDebounce}
                    onDropdownVisibleChange={(visible) => {
                      setIsTemplateDropdownVisible(visible);
                    }}
                    filterOption={false}
                    notFoundContent={
                      !isFetchMoreTemplatesLoading && isTemplatesLoading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>No user available</span>
                      )
                    }
                  >
                    {templateList && templateList?.length > 0 && (
                      <>
                        {templateList?.map((template) => {
                          return (
                            <Option key={template?.id} value={template?.key}>
                              {template?.title}
                            </Option>
                          );
                        })}
                      </>
                    )}
                  </SelectComponent>
                </Form.Item>
              ) : (
                <>
                  {formItems?.length > 0 &&
                    formItems?.map((formItem) => {
                      return (
                        <Form.Item
                          key={formItem?.name}
                          label={formItem?.title}
                          name={formItem?.name}
                          rules={formItem?.rules}
                        >
                          <Input
                            className="input-box"
                            placeholder={formItem?.placeholder}
                          />
                        </Form.Item>
                      );
                    })}
                  <Form.Item
                    label="Email Body"
                    name="email_body"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter email body!',
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="input-box"
                      placeholder="Enter email body"
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                label="Recipient"
                name="recipient"
                rules={[
                  {
                    required: true,
                    validator: emailValidator,
                  },
                ]}
              >
                <SelectComponent
                  mode="tags"
                  className="input-box"
                  placeholder="Enter recipient"
                  popupClassName="select_dropdown"
                  tokenSeparators={[',', ' ']}
                  tagRender={({ label, value, closable, onClose }) => (
                    <Tag
                      closable={closable}
                      onClose={(e) => {
                        e?.preventDefault();
                        onClose();
                      }}
                      onMouseDown={onPreventMouseDown}
                      color={invalidEmails?.includes(value) ? 'red' : ''}
                    >
                      {label}
                    </Tag>
                  )}
                />
              </Form.Item>
              {radioButtonValue === 1 && (
                <Form.Item
                  label="Data variables"
                  name="data_variables"
                  className="text-area"
                  help='Hint: {"firstname":"John", "lastname":"Doe"}'
                >
                  <Input.TextArea
                    className="input-box"
                    placeholder="Enter data variables (if any) in JSON format"
                  />
                </Form.Item>
              )}
            </div>
            <Space size="middle" className="mt-20">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={loadings}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SendRawEmailModal;
