import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import App from './App';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import OTP from './modules/auth/OTP';
import RefreshToken from './modules/auth/RefreshToken';
import { GET_PROFILE } from './modules/auth/graphql/Queries';
import DashboardWrapper from './modules/dashboard';
import ProjectsWrapper from './modules/projects';
import { AppContextType } from './types/appContext.type';

type FallbackProps = {
  error: unknown;
  componentStack: string | null;
};

const { Paragraph } = Typography;
const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => {
  const errors = error as Error;

  return (
    <Result
      status="error"
      title={ERROR_PAGE_TITLE}
      subTitle={ERROR_PAGE_SUBTITLE}
    >
      <div className="desc">
        <Paragraph>
          <Typography.Title level={4}> Error:</Typography.Title>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          {errors?.message?.toString()}
        </Paragraph>
        <Paragraph>
          <Typography.Title level={4}> Stacktrace:</Typography.Title>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          {componentStack}
        </Paragraph>
      </div>
    </Result>
  );
};

const RoutesCollection = () => {
  const AUTH_MODULE = [
    {
      path: ROUTES.LOGIN,
      element: <PublicRoute />,
      children: [{ path: ROUTES.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES.VERIFY,
      element: <PublicRoute />,
      children: [{ path: ROUTES.VERIFY, element: <OTP /> }],
    },
    {
      path: ROUTES.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES.AUTHENTICATION, element: <RefreshToken /> }],
    },
  ];

  const PROJECT_MODULE = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.PROJECTS,
          element: <App />,
          children: [
            {
              path: `${ROUTES.PROJECTS}/*`,
              element: <ProjectsWrapper />,
            },
          ],
        },
      ],
    },
  ];
  const DASHBOARD_MODULE = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES.DASHBOARD}`,
              element: <DashboardWrapper />,
            },
          ],
        },
      ],
    },
  ];
  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULE,
    ...DASHBOARD_MODULE,
    ...PROJECT_MODULE,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken } = useContext(AppContext) as AppContextType;
  const [loading, setLoading] = useState<boolean>(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getUserProfile] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const currentUser = res?.user;
      initializeAuth(idToken, currentUser || null, currentUser?.refreshToken);
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES?.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES.LOGOUT || idToken) {
      getUserProfile();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
