import { useMutation } from '@apollo/client';
import { Button, message, Spin } from 'antd';
import { get } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import OtpInput from 'react-otp-input';
import { AppContext } from '../../AppContext';
import { OTP_NUM_INPUT, OTP_SIZE, ROUTES } from '../../common/constants';
import { User } from '../../gql/graphql';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { SEND_OTP, VERIFY_OTP } from './graphql/Mutations';

const OTP = () => {
  const { navigate } = useRouter();
  const loginButtonRef = useRef<HTMLButtonElement | null>(null);
  const { initializeAuth, dispatch, getCurrentUser } = useContext(
    AppContext,
  ) as AppContextType;
  const [otpState, setOtpState] = useState<string>('');
  const currentUser = getCurrentUser();
  const [verifyOtpFun, { loading: verifyOtpLoading }] = useMutation(
    VERIFY_OTP,
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    },
  );
  const [sendOtpFun] = useMutation(SEND_OTP, {
    variables: {
      email: currentUser?.email as string,
    },
  });

  function successCallback(
    accessToken: string,
    userData: User,
    refreshToken: string,
  ) {
    initializeAuth(accessToken, userData, refreshToken);
    setOtpState('');
  }

  useEffect(() => {
    if (currentUser?.email) {
      navigate(ROUTES?.VERIFY);
    } else {
      navigate(ROUTES?.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.email]);

  useEffect(() => {
    if (otpState?.length === OTP_SIZE) {
      loginButtonRef?.current?.focus();
    }
  }, [otpState]);

  const handleOTPChange = (value: string) => {
    setOtpState(value);
  };

  const handleSendAgain = async () => {
    if (currentUser?.email) {
      try {
        const response = await sendOtpFun();
        const data = response?.data;
        if (data) {
          dispatch({
            type: AppActionType.setCurrentUser,
            data: { email: currentUser?.email },
          });
        }
      } catch (error) {
        return error;
      }
    } else {
      message.error('User email does not exist');
    }
  };

  const handleLogin = async () => {
    if (currentUser?.email) {
      try {
        const response = await verifyOtpFun({
          variables: {
            data: {
              email: currentUser?.email,
              otp: otpState,
            },
          },
        });
        const accessToken = get(response, 'data.verifyOtp.token');
        const userData = get(response, 'data.verifyOtp.data');
        const refreshToken = get(response, 'data.verifyOtp.refreshToken');
        if (successCallback && accessToken && userData && refreshToken) {
          successCallback(accessToken, userData, refreshToken);
        }
      } catch (error) {
        return error;
      }
    } else {
      message.error('User email does not exist');
    }
  };

  return (
    <div className="gx-login-container login">
      <Spin spinning={false} wrapperClassName="gx-login-content">
        <div className="text-center site-title mb-32">Sendbay</div>
        <div className="gx-login-header">
          <span className="gx-login-header-title white-color mb-8">
            Check your email
          </span>
          <span className="gx-login-header-subtitle white-color">
            Enter OTP sent to {currentUser?.email}
          </span>
        </div>
        <div className="mb-16 sb-otp-input">
          <OtpInput
            value={otpState}
            onChange={handleOTPChange}
            numInputs={OTP_NUM_INPUT}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div>
          <Button
            loading={verifyOtpLoading}
            type="primary"
            className="primary-button mb-16"
            onClick={handleLogin}
            ref={loginButtonRef}
          >
            Login
          </Button>
        </div>
        <div>
          <span className="white-color">Didn't receive OTP ? </span>
          <span onClick={handleSendAgain}>
            <Button
              type="link"
              disabled={verifyOtpLoading}
              className="sb-send-again"
            >
              Send Again
            </Button>
          </span>
        </div>
      </Spin>
    </div>
  );
};

export default OTP;
