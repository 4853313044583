import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { InputProps } from 'antd/lib';
import { debounce, DebouncedFunc } from 'lodash';

// eslint-disable-next-line no-unused-vars
let searchDebounce: DebouncedFunc<(value: string) => void> | null = null;

const SearchComponent = (
  props: InputProps & {
    name?: string;
    // eslint-disable-next-line no-unused-vars
    getData: (value: string) => void;
    query?: string;
    // eslint-disable-next-line no-unused-vars
    setQuery?: (value: string) => void;
  },
) => {
  const { name = '', getData, query, setQuery, ...rest } = props;

  const handleChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    if (setQuery) {
      setQuery(value);
    }
    if (getData) {
      if (searchDebounce) {
        searchDebounce?.cancel();
        searchDebounce = null;
      }
      searchDebounce = debounce(getData, 500);
      searchDebounce(value);
    }
  };

  return (
    <Input
      className="search-input"
      allowClear
      placeholder={`Search ${name}`}
      value={query}
      name={name}
      onChange={handleChange}
      prefix={<SearchOutlined />}
      {...rest}
    />
  );
};

export default SearchComponent;
