import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { injectUsingPortal, isPortalIdExists } from '../common/utils';

type PortalPropsType = {
  children: React.ReactNode;
  portalId: string;
};

const Portal = ({ children, portalId }: PortalPropsType) => {
  const [portalExist, setPortalExist] = useState<boolean>(false);
  useEffect(() => {
    if (isPortalIdExists(portalId)) {
      setPortalExist(true);
    } else {
      setPortalExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {portalExist &&
        ReactDOM.createPortal(
          children,
          injectUsingPortal(portalId) as HTMLElement,
        )}
    </>
  );
};

export default Portal;
