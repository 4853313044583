import { useLazyQuery } from '@apollo/client';
import { DatePicker, message, Select } from 'antd';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useContext, useEffect, useState } from 'react';
import { DateValueType, GraphType } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import {
  ANALYTICS_DATE_FORMAT,
  ANALYTICS_DATE_TIME_FORMAT,
  CUSTOM_ANALYTICS_OPTIONS,
  CUSTOM_DATE_FORMAT,
  DAY_MONTH_FORMAT,
  EMAIL_STATUSES,
  MONTH_NAME_FORMAT,
  ROUTES,
  STATUSES,
} from '../../common/constants';
import CardComponent from '../../components/Card';
import LineChart from '../../components/LineChart';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import { ChannelType } from '../../gql/graphql';
import { AppContextType } from '../../types/appContext.type';
import {
  DateRangeType,
  GraphDataType,
  StatusType,
} from '../../types/common.type';
import './analytics.less';
import { GET_ANALYTICS } from './graphql/Queries';

const { Option } = Select;
const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = MyDatePicker;
const currentWeekStart = moment().startOf('week').startOf('day');
const currentWeekEnd = moment().endOf('week').endOf('day');

const customDataPayload = (
  dataArray: (DateValueType | null)[],
  status: string,
  start?: Moment | null,
  end?: Moment | null,
) => {
  if (start && end) {
    const allDates = [];
    const currentDate = start.clone();

    while (currentDate <= end) {
      allDates.push(currentDate.clone());
      currentDate.add(1, 'day');
    }

    return allDates?.map((e) => {
      return {
        label: e?.format(CUSTOM_DATE_FORMAT),
        status: status,
        count:
          dataArray?.find(
            (date) => date?.label === e?.format(ANALYTICS_DATE_FORMAT),
          )?.count ?? 0,
      };
    });
  } else {
    message.error('Start and End dates not provided');
  }
};

const thisYearDataPayload = (
  dataArray: (DateValueType | null)[],
  status: string,
) => {
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  return months?.map((e) => {
    return {
      label: moment()
        .month(e - 1)
        .format(MONTH_NAME_FORMAT),
      status: status,
      count: dataArray?.find((date) => date?.label === String(e))?.count ?? 0,
    };
  });
};

const thisMonthDataPayload = (
  dataArray: (DateValueType | null)[],
  status: string,
) => {
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');
  const dates = [];

  const currentDate = startOfMonth.clone();
  while (currentDate <= endOfMonth) {
    dates.push(currentDate.format(ANALYTICS_DATE_FORMAT));
    currentDate.add(1, 'day');
  }
  return dates?.map((e) => {
    return {
      label: moment(e).format(DAY_MONTH_FORMAT),
      status: status,
      count:
        dataArray?.find((date) => date?.label === moment(e).format('D'))
          ?.count ?? 0,
    };
  });
};

const yearlyDataPayload = (
  dataArray: (DateValueType | null)[],
  status: string,
) => {
  return dataArray?.map((e) => {
    return {
      label: e?.label,
      status: status,
      count: e?.count ?? 0,
    };
  });
};

const Analytics = ({ activeKey }: { activeKey: string }) => {
  const {
    state: { projectEnvId },
  } = useContext(AppContext) as AppContextType;
  const [selectedFilter, setSelectedFilter] = useState<GraphType>(
    GraphType.Custom,
  );
  const [customDateRange, setCustomDateRange] = useState<DateRangeType>();
  const [graphData, setGraphData] = useState<GraphDataType[] | null>(null);
  const [loadings, setLoadings] = useState(true);

  const [executeGetAnalytics, { data: analyticsData }] = useLazyQuery(
    GET_ANALYTICS,
    {
      onCompleted: (response) => {
        switch (selectedFilter) {
          case 'CUSTOM': {
            const records = EMAIL_STATUSES.flatMap((status) =>
              customDataPayload(
                response?.getMessageAnalytics?.data?.[status as StatusType] ??
                  [],
                status,
                customDateRange?.[0],
                customDateRange?.[1],
              ),
            );
            setGraphData(records as GraphDataType[]);
            break;
          }
          case 'YEARLY': {
            const records = EMAIL_STATUSES.flatMap((status) =>
              yearlyDataPayload(
                response?.getMessageAnalytics?.data?.[status as StatusType] ??
                  [],
                status,
              ),
            );
            setGraphData(records as GraphDataType[]);
            break;
          }
          case 'THIS_YEAR': {
            const records = EMAIL_STATUSES.flatMap((status) =>
              thisYearDataPayload(
                response?.getMessageAnalytics?.data?.[status as StatusType] ??
                  [],
                status,
              ),
            );
            setGraphData(records);
            break;
          }
          case 'THIS_MONTH': {
            const records = EMAIL_STATUSES.flatMap((status) =>
              thisMonthDataPayload(
                response?.getMessageAnalytics?.data?.[status as StatusType] ??
                  [],
                status,
              ),
            );
            setGraphData(records);
            break;
          }
          default: {
            return [];
          }
        }
        setLoadings(false);
      },
      fetchPolicy: 'network-only',
    },
  );
  useEffect(() => {
    setCustomDateRange([currentWeekStart, currentWeekEnd]);
  }, []);

  useEffect(() => {
    if (selectedFilter === 'CUSTOM' && customDateRange === null) return;
    const payload = {
      filter: {
        endDate:
          selectedFilter === 'CUSTOM'
            ? customDateRange?.[1]?.format(ANALYTICS_DATE_TIME_FORMAT)
            : '',
        graphType: selectedFilter,
        projectEnvId: projectEnvId as string,
        startDate:
          selectedFilter === 'CUSTOM'
            ? customDateRange?.[0]?.format(ANALYTICS_DATE_TIME_FORMAT)
            : '',
        type: ChannelType.Email,
      },
    };

    executeGetAnalytics({
      variables: payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, customDateRange]);

  useEffect(() => {
    setLoadings(true);
    setSelectedFilter(GraphType.Custom);
    setGraphData([]);
    if (activeKey === ROUTES?.ANALYTICS) {
      executeGetAnalytics({
        variables: {
          filter: {
            endDate:
              selectedFilter === 'CUSTOM'
                ? customDateRange?.[1]?.format(ANALYTICS_DATE_TIME_FORMAT)
                : '',
            graphType: selectedFilter,
            projectEnvId: projectEnvId as string,
            startDate:
              selectedFilter === 'CUSTOM'
                ? customDateRange?.[0]?.format(ANALYTICS_DATE_TIME_FORMAT)
                : '',
            type: ChannelType.Email,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEnvId, activeKey]);

  const handleChange = (val: GraphType) => {
    setSelectedFilter(val);
  };

  const handleRangeChange = (val: DateRangeType) => {
    setCustomDateRange(val);
  };

  return loadings && graphData?.length === 0 ? (
    <LoaderComponent size="large" setHeight="60" spinning={loadings} />
  ) : (
    <div className="global-container analytics">
      <div className="analytics-dropdown">
        {selectedFilter === 'CUSTOM' && customDateRange && (
          <span className="analytics-range-dropdown">
            <RangePicker
              defaultValue={customDateRange}
              onChange={(e) => handleRangeChange(e as DateRangeType)}
              value={customDateRange}
              format={ANALYTICS_DATE_FORMAT}
            />
          </span>
        )}
        <Select
          style={{ width: 250 }}
          onChange={handleChange}
          value={selectedFilter}
        >
          {CUSTOM_ANALYTICS_OPTIONS?.map((e) => (
            <Option value={e.value} key={e.value}>
              {e.label}
            </Option>
          ))}
        </Select>
      </div>
      <div className="analytics-main">
        <div className="analtics-cards-section">
          <CardComponent
            total={
              analyticsData?.getMessageAnalytics?.data?.stats?.totalEmails ?? 0
            }
            title="TOTAL"
          />
          <CardComponent
            percentage={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.successEmailPercentage ?? 0
            }
            count={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.totalSuccessEmails ?? 0
            }
            title={STATUSES.SUCCESS}
          />
          <CardComponent
            percentage={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.failedEmailPercentage ?? 0
            }
            count={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.totalFailedEmails ?? 0
            }
            title={STATUSES.FAILED}
          />
          <CardComponent
            percentage={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.readEmailPercentage ?? 0
            }
            count={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.totalReadEmails ?? 0
            }
            title={STATUSES.READ}
          />
          <CardComponent
            percentage={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.unreadEmailPercentage ?? 0
            }
            count={
              analyticsData?.getMessageAnalytics?.data?.stats
                ?.totalUnreadEmails ?? 0
            }
            title={STATUSES.UNREAD}
          />
        </div>
      </div>
      {graphData && graphData?.length > 0 ? (
        <div style={{ width: 'inherit' }}>
          <div className="analytics-line-chart">
            <LineChart data={graphData} />
          </div>
        </div>
      ) : (
        <Nodata />
      )}
    </div>
  );
};

export default Analytics;
