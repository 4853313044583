import { useLazyQuery } from '@apollo/client';
import { Empty, Select, SelectProps } from 'antd';
import { debounce } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SelectComponent from '../../../components/SelectComponent';

import {
  ProviderType,
  TemplateSortBy,
  TemplatesResponse,
} from '../../../gql/graphql';
import { AppContextType } from '../../../types/appContext.type';
import { TEMPLATES } from '../graphql/Queries';

const { Option } = Select;
type TemplateSelectProps = {
  value?: string[];
} & Omit<SelectProps, 'options'>;

const LIMIT = 10;

const TemplateSelect = (props: TemplateSelectProps) => {
  const { ...rest } = props;
  const {
    state: { projectEnvId },
  } = useContext(AppContext) as AppContextType;
  const [templateList, setTemplateList] = useState<
    TemplatesResponse['templates']
  >([]);
  const [templateSearchTerm, setTemplateSearchTerm] = useState<string>('');
  const [isAllTemplateFetched, setIsAllTemplateFetched] =
    useState<boolean>(false);
  const [isTemplateAllowClear, setIsTemplateAllowClear] =
    useState<boolean>(false);
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] =
    useState<boolean>(false);
  const [isFetchMoreTemplateLoading, setIsFetchMoreTemplateLoading] =
    useState<boolean>(false);
  const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);

  const [executeTemplate, { loading: isTemplateLoading }] = useLazyQuery(
    TEMPLATES,
    {
      onCompleted: (response) => {
        if (
          response?.templates?.templates &&
          response?.templates?.templates?.length < LIMIT
        ) {
          setIsAllTemplateFetched(true);
        }
        setIsTemplateSelected(false);
        setIsFetchMoreTemplateLoading(false);
        setTemplateList([
          ...(templateList || []),
          ...(response?.templates?.templates || []),
        ]);
      },
      fetchPolicy: 'network-only',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    },
  );

  useEffect(() => {
    if (!isTemplateDropdownVisible) {
      setTemplateSearchTerm('');
      setTemplateList([]);
      executeTemplate({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            search: templateSearchTerm,
            projectEnvId: projectEnvId as string,
            sortBy: TemplateSortBy.CreatedAtDesc,
            type: ProviderType.Email,
          },
        },
      });
    }
    setIsAllTemplateFetched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateDropdownVisible]);

  useEffect(() => {
    if (isTemplateSelected && isTemplateDropdownVisible && templateSearchTerm) {
      setTemplateSearchTerm('');
      setTemplateList([]);
      executeTemplate({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            search: templateSearchTerm,
            projectEnvId: projectEnvId as string,
            sortBy: TemplateSortBy.CreatedAtDesc,
            type: ProviderType.Email,
          },
        },
      });
    }
    setIsAllTemplateFetched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateSelected, isTemplateDropdownVisible, templateSearchTerm]);

  const handleTemplateScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!isAllTemplateFetched && !isTemplateLoading) {
      const target = event?.currentTarget;
      const currentLength = templateList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreTemplateLoading(true);
        executeTemplate({
          variables: {
            filter: {
              skip: currentLength,
              limit: LIMIT,
              search: templateSearchTerm,
              projectEnvId: projectEnvId as string,
              sortBy: TemplateSortBy.CreatedAtDesc,
              type: ProviderType.Email,
            },
          },
        });
      }
    }
  };

  const handleTemplateSearch = (searchTerm: string) => {
    const trimSearch = searchTerm?.trim();
    setIsAllTemplateFetched(false);
    setTemplateList([]);
    setTemplateSearchTerm(trimSearch);
    executeTemplate({
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
          search: trimSearch,
          projectEnvId: projectEnvId as string,
          sortBy: TemplateSortBy.CreatedAtDesc,
          type: ProviderType.Email,
        },
      },
    });
  };

  const handleTemplateScrollDebounce = debounce(handleTemplateScroll, 500);
  const handleSearchTemplateDebounce = debounce(handleTemplateSearch, 500);

  useEffect(() => {
    handleTemplateScrollDebounce?.cancel();
  }, [handleTemplateScrollDebounce]);

  useEffect(() => {
    handleSearchTemplateDebounce?.cancel();
  }, [handleSearchTemplateDebounce]);

  useEffect(() => {
    if (isTemplateLoading) {
      setIsTemplateAllowClear(false);
    } else {
      setIsTemplateAllowClear(true);
    }
  }, [isTemplateLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch
      loading={
        (isTemplateLoading && isTemplateDropdownVisible) ||
        isFetchMoreTemplateLoading
      }
      placeholder="Select Templates"
      optionFilterProp="children"
      allowClear={isTemplateAllowClear}
      onPopupScroll={handleTemplateScrollDebounce}
      onSearch={handleSearchTemplateDebounce}
      onDropdownVisibleChange={(visible: boolean) =>
        setIsTemplateDropdownVisible(visible)
      }
      filterOption={false}
      onChange={() => setIsTemplateAllowClear(true)}
      onSelect={() => setIsTemplateSelected(true)}
      notFoundContent={
        !isFetchMoreTemplateLoading && isTemplateLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {templateList?.map((template) => (
        <Option key={template?.id} value={template?.id}>
          {template?.title} ({template?.key})
        </Option>
      ))}
    </SelectComponent>
  );
};

export default TemplateSelect;
