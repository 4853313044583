import { gql } from '../../../__generated__';

export const GET_PROJECT_AUTH_DETAILS = gql(`
  query projectEnvAuthDetails($id: ID!) {
    projectEnvAuthDetails(id: $id) {
      apiKey
      secretKey
    }
  }
`);
