import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Popconfirm,
  TableColumnsType,
  TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  LOG_MAIL_STATUS,
  ROUTES,
  SCHEDULED_DATE_FORMATE,
  SORT,
  USERS_ROLE,
} from '../../common/constants';
import { formatDate } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import {
  MessageLogs,
  MessagesLogsResponse,
  MessageSortBy,
  ProviderType,
} from '../../gql/graphql';
import { AppContextType } from '../../types/appContext.type';
import { SendMailFormValues } from '../../types/common.type';
import SendRawEmailModal from './components/SendRawEmailModal';
import { RESEND_EMAIL, SEND_RAW_EMAIL } from './graphql/Mutations';
import { GET_LOGS } from './graphql/Queries';
import './logs.less';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

type LogsPropType = {
  isMailSend: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsMailSend: (value: boolean) => void;
  activeKey: string;
};

const Logs = ({ isMailSend, setIsMailSend, activeKey }: LogsPropType) => {
  const { state, getCurrentRole } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<MessageLogs>>({});
  const [isEmptyLogList, setIsEmptyLogList] = useState<boolean>(false);
  const [paginationProp, setPaginationProp] = useState<TablePaginationConfig>(
    initialPaginationValue,
  );
  const [isSendRawEmailModalOpen, setIsSendRawEmailModalOpen] =
    useState<boolean>(false);
  const [logList, setLogList] = useState<MessagesLogsResponse['messages']>([]);
  const [isLogLoading, setIsLogLoading] = useState<boolean>(true);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [logSearchTerm, setLogSearchTerm] = useState<string>('');
  const [isLogSearchLoading, setIsLogSearchLoading] = useState<boolean>(false);
  const [isAllowClear, setIsAllowClear] = useState<boolean>(false);

  const limit = 10;
  const [sendRawEmail] = useMutation(SEND_RAW_EMAIL);

  const [executeLogList] = useLazyQuery(GET_LOGS, {
    onCompleted: (response) => {
      setLogList([...(response?.messagesLogs?.messages || [])]);
      if (
        response?.messagesLogs?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyLogList(true);
      }
      const pagination: TablePaginationConfig = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.messagesLogs?.count || 0,
      };
      setPaginationProp(pagination);
      setIsLogLoading(false);
      setIsLogSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsLogLoading(false);
    },
  });

  const [resendEmail] = useMutation(RESEND_EMAIL, {
    onCompleted: (response) => {
      if (response?.resendEmail?.status === 'SUCCESS') {
        setIsLogLoading(true);
        executeLogList({
          variables: {
            filter: {
              skip: 0,
              limit,
              sortBy: MessageSortBy.UpdatedAtDesc,
              projectEnvId: state?.projectEnvId as string,
              type: ProviderType.Email,
            },
          },
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  useEffect(() => {
    if (state?.projectEnvId && activeKey === ROUTES?.LOGS) {
      setIsLogLoading(true);
      setLogList([]);
      executeLogList({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            sortBy: MessageSortBy.UpdatedAtDesc,
            projectEnvId: state?.projectEnvId as string,
            type: ProviderType.Email,
          },
        },
      });
      setIsMailSend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, isMailSend, activeKey]);

  const onFinish = async (values: SendMailFormValues) => {
    setLoadings(true);
    sendRawEmail({
      variables: {
        projectEnvId: state?.projectEnvId as string,
        data: {
          senderId: values?.senders_email,
          isTemplateUsed: values?.select_existing_template === 1,
          templateKey: values?.select_template,
          subject: values?.email_subject || '',
          body: values?.email_body,
          recipient: values?.recipient,
          data: values?.data_variables
            ? JSON.parse(values?.data_variables)
            : undefined,
        },
      },
    })
      .then(() => {
        setIsLogLoading(true);
        setPaginationProp({ ...paginationProp, current: 1 });
        setLogSearchTerm('');
        executeLogList({
          variables: {
            filter: {
              skip: 0,
              limit: paginationProp?.pageSize || limit,
              sortBy: MessageSortBy.UpdatedAtDesc,
              projectEnvId: state?.projectEnvId as string,
              type: ProviderType.Email,
            },
          },
        });
        setIsSendRawEmailModalOpen(false);
        setLoadings(false);
      })
      .catch((error) => {
        setLoadings(false);
        return error;
      });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    tablefilter: Record<string, FilterValue | null>,
    sorter: SorterResult<MessageLogs>,
  ) => {
    const { current } = pagination;
    const skip = ((current || 1) - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsLogLoading(true);
    setLogList([]);
    if (sorter?.column) {
      executeLogList({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: logSearchTerm,
            sortBy:
              sorter?.order === 'ascend'
                ? (`${sorter?.field}${SORT.ASC}` as MessageSortBy)
                : (`${sorter?.field}${SORT.DESC}` as MessageSortBy),
            projectEnvId: state?.projectEnvId as string,
            type: ProviderType.Email,
          },
        },
      });
    } else {
      executeLogList({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: logSearchTerm,
            sortBy: MessageSortBy.UpdatedAtDesc,
            projectEnvId: state?.projectEnvId as string,
            type: ProviderType.Email,
          },
        },
      });
    }
  };

  const handleSearch = (value: string) => {
    setIsLogSearchLoading(true);
    const trimValue = value?.trim() || '';
    setLogSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1 });
    setIsLogLoading(true);
    setLogList([]);
    executeLogList({
      variables: {
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          sortBy: MessageSortBy.UpdatedAtDesc,
          projectEnvId: state?.projectEnvId as string,
          type: ProviderType.Email,
        },
      },
    });
    setIsAllowClear(false);
  };

  const handleReSend = async (record: MessageLogs) => {
    await resendEmail({
      variables: {
        where: {
          messageLogId: record?.id,
        },
        projectEnvId: state?.projectEnvId as string,
      },
    });
  };

  const SendRawEmailFormsItems = [
    {
      title: 'Email Subject',
      name: 'email_subject',
      placeholder: 'Enter email subject',
      rules: [{ required: true, message: 'Please enter email subject!' }],
    },
  ];

  const columns: TableColumnsType<MessageLogs> = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs, index: number) => {
        return (
          <span>
            {(paginationProp?.pageSize || 0) * (paginationProp?.current || 1) -
              (paginationProp?.pageSize || 0) +
              index +
              1}
          </span>
        );
      },
    },
    {
      title: 'SENDER',
      dataIndex: 'sender',
      key: 'sender',
      ellipsis: true,
      width: 250,
      align: 'left' as const,

      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'sender' ? sortedInfo?.order : null,
      render: (_: string, record: MessageLogs) => record?.sender || '-',
    },
    {
      title: 'RECIPIENT',
      dataIndex: 'recipient',
      key: 'recipient',
      ellipsis: true,
      width: 250,
      align: 'left' as const,

      sorter: true,
      className: 'max-width-column',
      sortOrder:
        sortedInfo?.columnKey === 'recipient' ? sortedInfo?.order : null,
      render: (_: string, record: MessageLogs) => record?.recipient || '-',
    },
    {
      title: 'SUBJECT',
      dataIndex: 'subject',
      key: 'subject',
      ellipsis: true,
      width: 200,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs) =>
        record?.messageDetails?.subject || '-',
    },
    {
      title: 'TEMPLATE',
      dataIndex: 'template',
      key: 'template',
      ellipsis: true,
      width: 200,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs) =>
        record?.messageDetails?.templateKey || '-',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        LOG_MAIL_STATUS?.[record!.status!]?.label,
    },
    {
      title: 'IS READ',
      dataIndex: 'isRead',
      key: 'isRead',
      width: 100,
      ellipsis: true,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs) =>
        record?.isRead ? 'YES' : 'NO',
    },
    {
      title: 'SCHEDULED AT',
      dataIndex: 'scheduledTime',
      key: 'scheduledTime',
      width: 200,
      ellipsis: true,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs) =>
        formatDate(record?.scheduledTime, SCHEDULED_DATE_FORMATE) || '-',
    },
    {
      title: 'SENT AT',
      dataIndex: 'updateAt',
      key: 'updatedAt',
      width: 200,
      ellipsis: true,
      align: 'left' as const,

      className: 'max-width-column',
      render: (_: string, record: MessageLogs) =>
        formatDate(record?.updatedAt, SCHEDULED_DATE_FORMATE),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      hidden: false,
      width: 200,
      render: (_: string, record: MessageLogs) => {
        const isStatusFailed = record?.status !== LOG_MAIL_STATUS.FAILED.value;
        return (
          <Popconfirm
            title={`Are you sure you want to re-send mail to ${record?.recipient}?`}
            onConfirm={() => handleReSend(record)}
            okText="Yes"
            cancelText="No"
            disabled={isStatusFailed}
          >
            <Button
              type="primary"
              className="re-send"
              disabled={isStatusFailed}
            >
              Re-send
            </Button>
          </Popconfirm>
        );
      },
    },
  ].filter((item) => {
    if (userRole === USERS_ROLE.READ_ONLY && item.dataIndex === 'actions') {
      return item.hidden;
    }
    return !item.hidden;
  });

  const locale = {
    emptyText: isEmptyLogList ? '' : <span />,
  };

  return (
    <>
      {isSendRawEmailModalOpen && (
        <SendRawEmailModal
          isModalOpen={isSendRawEmailModalOpen}
          setIsModalOpen={setIsSendRawEmailModalOpen}
          formTitle="Send Raw Email"
          onFinish={onFinish}
          loadings={loadings}
          formItems={SendRawEmailFormsItems}
          submitButton="Send Raw Email"
        />
      )}
      <div className="logs">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            {(logList && logList?.length > 0) ||
            logSearchTerm ||
            isLogSearchLoading ||
            isAllowClear ? (
              <SearchWithLoading
                setIsAllowClear={setIsAllowClear}
                query={logSearchTerm}
                setQuery={setLogSearchTerm}
                getData={handleSearch}
              />
            ) : null}
          </div>
          {(logList && logList?.length > 0) ||
          logSearchTerm ||
          isLogSearchLoading ||
          isAllowClear ? (
            <>
              {(userRole === USERS_ROLE?.OWNER ||
                userRole === USERS_ROLE?.WRITE) && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="primary-button"
                  onClick={() => setIsSendRawEmailModalOpen(true)}
                >
                  Send Raw Email
                </Button>
              )}
            </>
          ) : null}
        </div>
        <div className="mt-30">
          {isLogLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isLogLoading}
            />
          ) : (
            <>
              {(logList && logList?.length > 0) ||
              logSearchTerm ||
              isLogSearchLoading ||
              isAllowClear ? (
                <CommonTable
                  locale={locale}
                  columns={columns}
                  data={
                    logList?.filter((log): log is MessageLogs => !!log) || []
                  }
                  loadingData={isLogLoading}
                  onChange={handleTableChange}
                  paginationConfig={paginationProp}
                  rowKey="id"
                />
              ) : (
                <div className="analytics-illustration">
                  <Nodata />
                  <br />
                  {(userRole === USERS_ROLE?.OWNER ||
                    userRole === USERS_ROLE?.WRITE) && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="primary-button"
                      onClick={() => setIsSendRawEmailModalOpen(true)}
                    >
                      Send Raw Email
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Logs;
