import { Button, Form, Input, Modal, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import { useEffect, useState } from 'react';
import { CloseCircle } from '../../../assets/svg';
import { Channel } from '../../../gql/graphql';
import { CommonModalProps, FormFieldConfig } from '../../../types/common.type';

type AddChannelPropTypes = CommonModalProps & {
  initialValues: Channel | null;
  formTitle: string;
  formItems: FormFieldConfig[];
  // eslint-disable-next-line no-unused-vars
  onFinish: (channelDetail: Channel) => void;
  submitButton: string;
  loadings: boolean;
  handleReset: () => void;
  form: FormInstance;
};
const AddChannel = ({
  isModalOpen,
  setIsModalOpen,
  initialValues,
  formTitle,
  formItems,
  onFinish,
  submitButton,
  loadings,
  form,
  handleReset,
}: AddChannelPropTypes) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState<boolean>(false);

  useEffect(() => {
    form?.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setTimeout(handleReset, 500);
  };

  return (
    <>
      <Modal
        centered
        wrapClassName="environment-modal channel-modal"
        open={isModalOpen}
        closeIcon={<CloseCircle />}
        onCancel={handleCancel}
        footer={null}
        onOk={form?.submit}
        forceRender
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <div className="channel add-container">
            <div className="d-flex flex-horizontal justify-between">
              <p className="add-title">{formTitle}</p>
            </div>
            <div className="d-flex flex-vertical justify-between ">
              {formItems?.length > 0 &&
                formItems?.map((formItem) => {
                  return (
                    <Form.Item
                      key={formItem?.name}
                      label={formItem?.title}
                      name={formItem?.name}
                      rules={formItem?.rules}
                    >
                      <Input
                        className="input-box"
                        placeholder={formItem?.placeholder}
                      />
                    </Form.Item>
                  );
                })}
            </div>
            <Space size="middle" className="mt-20">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={loadings}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddChannel;
