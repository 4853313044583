import { gql } from '../../../__generated__';

export const GET_PROFILE = gql(`
  query User {
    user {
      createdAt
      email
      firstName
      id
      lastName
      profileImage
      message
      refreshToken
    }
  }
`);
