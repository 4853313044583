import { Table } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { TABLE_HEIGHT, TABLE_HEIGHT_SEARCH } from '../common/constants';
import './styles/TableComponent.less';

type TableProps<T> = {
  onChange: (
    // eslint-disable-next-line no-unused-vars
    pagination: TablePaginationConfig,
    // eslint-disable-next-line no-unused-vars
    tablefilter: Record<string, FilterValue | null>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    sorter: any,
  ) => void;
  columns: ColumnType<T>[];
  data: T[];
  isSearch?: boolean;
  setHeight?: number;
  loadingData?: boolean;
  fullHeight?: boolean;
  paginationConfig?: TablePaginationConfig;
  className?: string;
  locale?: {
    emptyText: string | JSX.Element;
  };
  rowKey?: string;
  scroll?: {
    x?: number | string | true;
    y?: number | string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRow?: () => any;
};
function TableComponent<T>(props: TableProps<T>) {
  const {
    columns,
    data = [],
    onChange,
    isSearch = true, // need to pass false when there is no search for table, it set height from this.
    setHeight = !isSearch ? TABLE_HEIGHT : TABLE_HEIGHT_SEARCH,
    loadingData = false,
    fullHeight = true, // To enable scroll inside table
    paginationConfig, // required for showing pagination
    ...rest
  } = props;

  let tableClassName = '';

  if (!data?.length) {
    tableClassName = 'no-scroll-no-data';
  } else if (fullHeight && !paginationConfig) {
    tableClassName = 'no-scroll-data';
  } else if (fullHeight && isSearch) {
    tableClassName = 'has-scroll-data-search';
  } else {
    tableClassName = 'has-scroll-data';
  }

  const [paginationProps, setPaginationProps] = useState<TablePaginationConfig>(
    {
      pageSizeOptions: [10, 15, 20, 50, 100],
      defaultPageSize: 10,
      responsive: true,
      showSizeChanger: true,
      position: ['bottomCenter'],
    },
  );

  useEffect(() => {
    setPaginationProps({ ...paginationProps, ...paginationConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <Table
      columns={columns}
      bordered={false} // by default false if want then pass true from props
      dataSource={data}
      className={`ant-table-content ${tableClassName}`}
      {...(fullHeight && data?.length
        ? {
            scroll: {
              x: 'max-content',
              y: `calc(100vh - ${setHeight}px)`,
            },
          }
        : {
            scroll: {
              x: 'min-content',
            },
          })} // pass setHeight prop to change table height && need fullHeight or not.
      onChange={onChange}
      {...(onchange && {
        scroll: {
          x: 'max-content',
          y: `calc(100vh - ${setHeight}px)`,
        },
      })}
      pagination={paginationConfig ? paginationProps : false} // for server side or client side pagination
      loading={{
        spinning: loadingData, // keep it true to set loader
        size: 'large', // currently kept large loader
      }}
      {...rest}
    />
  );
}

export default TableComponent;
