import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { ProjectWithEnvironment } from '../../../gql/graphql';
import useRouter from '../../../hooks/useRouter';
import { UPDATE_PROJECT } from '../graphql/Mutations';
import { PROJECT } from '../graphql/Queries';

function EditProject() {
  const { projectId } = useParams();
  const { navigate } = useRouter();
  const [loadings, setLoadings] = useState<boolean>(false);
  const [isValidateTrigger, setIsValidateTrigger] = useState<boolean>(false);

  const [projectDetail, setProjectDetail] =
    useState<ProjectWithEnvironment | null>();
  const [projectLoading, setProjectLoading] = useState<boolean>(true);

  const [updateProject] = useMutation(UPDATE_PROJECT);

  const { text } = formValidatorRules;

  const [projectDataList] = useLazyQuery(PROJECT, {
    variables: {
      id: projectId as string,
    },
    onCompleted: (response) => {
      setProjectDetail(response?.project);
      setProjectLoading(false);
    },
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError() {},
  });

  useEffect(() => {
    setProjectLoading(true);
    projectDataList({
      variables: {
        id: projectId as string,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onFinish = (values: { name: string }) => {
    setLoadings(true);
    updateProject({
      variables: {
        data: {
          name: values?.name?.trim(),
        },
        id: projectId as string,
      },
    })
      .then(() => {
        setLoadings(false);
        navigate(ROUTES?.DASHBOARD);
      })
      .catch((error) => {
        return error;
      });
    setLoadings(false);
  };

  return (
    <>
      {projectLoading ? (
        <LoaderComponent setHeight="60" size="large" />
      ) : (
        <Card className="full-height-card add-projects">
          <div className="add-container">
            <div className="add-item">
              <div className="d-flex flex-horizontal justify-between">
                <p className="add-title">Update Project</p>
                <Button
                  type="primary"
                  className="primary-button"
                  onClick={() => navigate(-1)}
                >
                  Back to projects
                </Button>
              </div>
              <div className="d-flex flex-vertical justify-between mt-30">
                <Form
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
                  onFinishFailed={() => setIsValidateTrigger(true)}
                >
                  <Form.Item
                    label="Project Name"
                    name="name"
                    rules={[
                      { required: true, message: 'Please input project name!' },
                      text,
                    ]}
                    className="mt-10"
                    initialValue={projectDetail?.name}
                  >
                    <Input className="input-box" maxLength={70} />
                  </Form.Item>
                  <Form.Item>
                    <div className="mt-30 text-center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadings}
                      >
                        Update Project
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
}

export default EditProject;
