import { gql } from '../../../__generated__';

export const UPDATE_PROJECT = gql(`
  mutation updateProject($data: ProjectData!, $id: ID!) {
    updateProject(data: $data, id: $id) {
      message
    }
  }
`);

export const ADD_NEW_PROJECT = gql(`
  mutation createProject($data: ProjectData!) {
    createProject(data: $data) {
      message
    }
  }
`);

export const DELETE_PROJECT = gql(`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) {
      message
      status
    }
  }
`);

export const ADD_PROJECT_ENVIRONMENT = gql(`
  mutation addProjectEnvironment($data: CreateProjectEnvironmentInput!) {
    createProjectEnvironment(data: $data) {
      data {
        id
        environment
        projectId
        createdBy
      }
      status
      message
    }
  }
`);

export const DELETE_PROJECT_ENVIRONMENT = gql(`
  mutation deleteProjectEnvironment($id: ID!) {
    deleteProjectEnvironment(id: $id) {
      data {
        id
        environment
        projectId
        createdBy
      }
      status
      message
    }
  }
`);
