import { gql } from '../../../__generated__';

export const SEND_RAW_EMAIL = gql(`
  mutation sendRawEmail($data: SendRawEmailData!, $projectEnvId: ID!) {
    sendRawEmail(data: $data, projectEnvId: $projectEnvId) {
      message
      status
    }
  }
`);

export const RESEND_EMAIL = gql(`
  mutation ResendEmail($where: ResendEmailWhere!, $projectEnvId: ID!) {
    resendEmail(where: $where, projectEnvId: $projectEnvId) {
      message
      status
    }
  }
`);
