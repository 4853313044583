type CardComponentPropType = {
  total?: number;
  percentage?: number;
  count?: number;
  title: string;
};
const CardComponent = ({
  total,
  percentage,
  count,
  title,
}: CardComponentPropType) => {
  return (
    <div className="analytics-card">
      <div className="analytics-card-title">{title}</div>
      {total || total === 0 ? (
        <div className="analytics-card-count">{total}</div>
      ) : (
        <div>
          <div className="analytics-card-per">{percentage}%</div>
          <div className="analytics-card-small-count">{count}</div>
        </div>
      )}
    </div>
  );
};

export default CardComponent;
