import { Button, Popconfirm } from 'antd';
import { Dispatch, SetStateAction, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { PERMISSION, USERS_ROLE } from '../../../common/constants';
import { Permission, ProjectMember } from '../../../gql/graphql';
import { AppContextType } from '../../../types/appContext.type';

type InviteTeamMembersCardPropsType = {
  inviteTeamMember: ProjectMember | null;
  // eslint-disable-next-line no-unused-vars
  inviteTeamMemberDeleteHandler: (id: string) => void;
  setIsInviteMemberModalOpen: (
    // eslint-disable-next-line no-unused-vars
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  setInviteTeamMemberDetails: Dispatch<SetStateAction<ProjectMember | null>>;
  userRole: Permission | null;
};
const InviteTeamMembersCard = ({
  inviteTeamMember,
  inviteTeamMemberDeleteHandler,
  setIsInviteMemberModalOpen,
  setInviteTeamMemberDetails,
  userRole,
}: InviteTeamMembersCardPropsType) => {
  const { getCurrentUser } = useContext(AppContext) as AppContextType;
  const currentUser = getCurrentUser();

  return (
    <div className="member-container">
      <div className="member-header">
        <div className="left-header">
          <p className="member-name">{inviteTeamMember?.email}</p>
        </div>
        <div className="right-header">
          {currentUser?.email !== inviteTeamMember?.email &&
            inviteTeamMember?.permission !== USERS_ROLE?.OWNER &&
            userRole === USERS_ROLE?.OWNER && (
              <>
                <Button
                  type="link"
                  className="view-member"
                  onClick={() => {
                    setIsInviteMemberModalOpen(true);
                    setInviteTeamMemberDetails(inviteTeamMember);
                  }}
                >
                  Edit User
                </Button>
                <Popconfirm
                  title={`Are you sure you want to delete ${inviteTeamMember?.email}?`}
                  onConfirm={() => {
                    inviteTeamMemberDeleteHandler(
                      inviteTeamMember?.id as string,
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" className="delete-member">
                    Delete User
                  </Button>
                </Popconfirm>
              </>
            )}
        </div>
      </div>
      <div className="member-body">
        <div className="body-item">
          <div>
            <p className="item-title">
              {PERMISSION?.[inviteTeamMember?.permission || 'READ_ONLY']?.label}
            </p>
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default InviteTeamMembersCard;
