import { Button, Form, Input, Modal, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { CloseCircle } from '../../../assets/svg';
import { Template } from '../../../gql/graphql';
import { CommonModalProps, FormFieldConfig } from '../../../types/common.type';

type TemplateModalPropTypes = CommonModalProps & {
  initialValues: Template | null;
  formTitle: string;
  formItems: FormFieldConfig[];
  // eslint-disable-next-line no-unused-vars
  onFinish: (values: { title: string; subject: string; key: string }) => void;
  submitButton: string;
  loadings: boolean;
  handleReset: () => void;
  form: FormInstance;
};
const TemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  initialValues,
  formTitle,
  formItems,
  onFinish,
  submitButton,
  loadings,
  handleReset,
  form,
}: TemplateModalPropTypes) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState<boolean>(false);

  useEffect(() => {
    form?.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleCancel = () => {
    setIsModalOpen(false);
    // eslint-disable-next-line no-undef
    setTimeout(handleReset, 500);
  };

  return (
    <>
      <Modal
        wrapClassName="environment-modal template-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        closeIcon={<CloseCircle />}
        footer={null}
        onOk={form?.submit}
        forceRender
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <div className="add-container">
            <div className="d-flex flex-horizontal justify-between">
              <p className="add-title">{formTitle}</p>
            </div>
            <div className="d-flex flex-vertical justify-between ">
              {formItems?.length > 0 ? (
                formItems?.map((formItem) => {
                  return (
                    <div key={formItem?.name}>
                      <Form.Item
                        label={formItem?.title}
                        name={formItem?.name}
                        rules={formItem?.rules}
                      >
                        <Input
                          disabled={
                            formItem?.name === 'key'
                              ? !isEmpty(initialValues?.key)
                              : false
                          }
                          className="input-box"
                          placeholder={formItem?.placeholder}
                        />
                      </Form.Item>
                    </div>
                  );
                })
              ) : (
                <div />
              )}
            </div>
            <Space size="middle" className="mt-20">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={loadings}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TemplateModal;
