export const themeConfig = {
  token: {
    colorPrimary: '#eb4960',
    radiusBase: '6px',
    controlRadius: '4px',
    colorLink: '#eb4960',
    colorLinkActive: '#eb4960',
    colorLinkHover: '#eb4960',
  },
  components: {
    Typography: {},
    Button: {
      borderRadius: 4,
      colorPrimaryHover: '#eb4960',
      colorPrimary: '#eb4960',
      primaryShadow: 'unset',
    },
    Layout: {
      siderBg:
        'linear-gradient(180deg, #819a7d, #b28c77 45%, #8d4248 75%) !important',
    },
    Form: {
      labelColor: '#ffffff',
      controlHeight: 36,
      colorBgContainerDisabled: '#ffffff',
    },

    Input: {
      borderRadius: 10,
      controlHeight: 36,
    },
    Menu: { itemSelectedBg: '#f2f2f2' },
    Table: {
      colorBgContainer: '#263238',
    },
  },
};
