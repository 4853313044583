import { Button, Form, Modal, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import { Dispatch, SetStateAction } from 'react';
import { CloseCircle } from '../../assets/svg';
import { ProjectEnvironment } from '../../gql/graphql';
import { CommonModalProps } from '../../types/common.type';

type DeleteENVModalPropTypes = CommonModalProps & {
  value?: ProjectEnvironment | null;
  // eslint-disable-next-line no-unused-vars
  onFinish: (values: { environment: string }) => void;
  setProjectENVDeleteData: Dispatch<
    SetStateAction<ProjectEnvironment | null | undefined>
  >;
  submitButton: string;
  loadings: boolean;
  form?: FormInstance;
};

const DeleteENVModal = ({
  isModalOpen,
  setIsModalOpen,
  value,
  onFinish,
  setProjectENVDeleteData,
  submitButton,
  loadings,
  form,
}: DeleteENVModalPropTypes) => {
  const handleCancel = () => {
    setIsModalOpen(false);
    setProjectENVDeleteData(null);
    // eslint-disable-next-line no-undef
  };

  return (
    <>
      <Modal
        centered
        wrapClassName="environment-modal delete-env-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        closeIcon={<CloseCircle />}
        footer={null}
        onOk={form?.submit}
        forceRender
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger="onBlur"
        >
          <div className="add-container">
            <div className="d-flex flex-vertical justify-between">
              <p>
                Are you sure want to delete <span>{value?.environment}</span>{' '}
                ENV ?
              </p>
            </div>
            <Space size="middle" className="mt-20">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={loadings}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteENVModal;
