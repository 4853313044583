import { gql } from '../../../__generated__';

export const SENDERS = gql(`
  query senders(
    $projectEnvId: ID!
    $type: ProviderType!
    $filter: SenderFilter!
  ) {
    senders(projectEnvId: $projectEnvId, type: $type, filter: $filter) {
      count
      senders {
        id
        email
        name
        phoneNo
        isVerified
        createdAt
      }
    }
  }
`);
