import { NoEmailIcon } from '../assets/svg';

function Nodata() {
  return (
    <div className="no-data">
      <NoEmailIcon />
      <div className="text-section text-center mt-30">No data Found</div>
    </div>
  );
}
export default Nodata;
