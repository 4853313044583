import Editor from '@monaco-editor/react';
import { Button, Modal, Space } from 'antd';
import { useState } from 'react';
import { CloseCircle } from '../../../assets/svg';
import { Template } from '../../../gql/graphql';
import { CommonModalProps } from '../../../types/common.type';

type EditBodyModalPropType = CommonModalProps & {
  // eslint-disable-next-line no-unused-vars
  setIsEditBody: (value: boolean) => void;
  isTemplateBody?: string | null;
  // eslint-disable-next-line no-unused-vars
  saveTemplateBody: (values: {
    body?: string | null;
    design?: string | null;
  }) => void;
  handleReset: () => void;
  editRowData: Template | null;
};
const EditBodyModal = (props: EditBodyModalPropType) => {
  const {
    isModalOpen,
    setIsModalOpen,
    setIsEditBody,
    isTemplateBody,
    saveTemplateBody,
    handleReset,
    editRowData,
  } = props;
  const [editorContent, setEditorContent] = useState<string | null | undefined>(
    isTemplateBody,
  );
  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);

  const handleEditorChange = (content: string | null) => {
    setEditorContent(content);
  };

  const exportHtml = () => {
    saveTemplateBody({
      body: editorContent || '',
    });
    setIsEditBody(false);
  };

  const handleCancel = () => {
    setIsChangeModalOpen(true);
  };

  const handleChangeCancel = () => {
    setIsChangeModalOpen(false);
  };

  return (
    <Modal
      centered
      wrapClassName="edit-body-modal"
      open={isModalOpen}
      onCancel={handleCancel}
      closeIcon={<CloseCircle />}
      footer={null}
      width={1400}
      forceRender
    >
      <Modal
        centered
        wrapClassName="edit-body-modal"
        open={isChangeModalOpen}
        onCancel={handleChangeCancel}
        closeIcon={<CloseCircle />}
        footer={null}
        width={670}
        forceRender
      >
        <div className="changes-modal-title">
          There are unsaved changes. Are you sure want to cancel changes ?
        </div>
        <Space size="middle" className="mt-20 ">
          <Button
            type="primary"
            className="primary-button"
            onClick={() => {
              setIsEditBody(false);
              handleReset();
              setIsModalOpen(false);
            }}
          >
            Confirm
          </Button>
          <Button
            className="secondary-button"
            onClick={() => {
              setIsChangeModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>
      <div className="d-flex flex-horizontal justify-between">
        <p className="add-title">
          {isTemplateBody
            ? `Editing - ${editRowData?.key}`
            : `Adding - ${editRowData?.key}`}
        </p>
      </div>
      <div />
      <div className="d-flex justify-between">
        <div className="width-percent-48">
          <div className="body-modal-title">Enter HTML code here</div>
          <Editor
            defaultLanguage="html"
            value={editorContent || ''}
            onChange={(e) => handleEditorChange(e as string)}
            theme="vs-dark"
            height="70vh"
          />
        </div>
        <div className="width-percent-48">
          <div className="body-modal-title">Preview</div>
          <iframe
            title="Webview"
            className="preview"
            srcDoc={editorContent || ''}
          />
        </div>
      </div>
      <Space size="middle" className="mt-20 ">
        <Button type="primary" className="primary-button" onClick={exportHtml}>
          Save
        </Button>
        <Button
          className="secondary-button"
          onClick={() => {
            setIsChangeModalOpen(true);
          }}
        >
          Cancel
        </Button>
      </Space>
    </Modal>
  );
};

export default EditBodyModal;
