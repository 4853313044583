import { Layout } from 'antd';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import Sidebar from './app/components/sidebar/Sidebar';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import { Permission } from './gql/graphql';
import useRouter from './hooks/useRouter';
import { AppActionType, AppContextType } from './types/appContext.type';

const { Content } = Layout;

const App = () => {
  const { dispatch, getCurrentUser } = useContext(AppContext) as AppContextType;
  const currentUser = getCurrentUser();
  const {
    location: { pathname },
  } = useRouter();

  useEffect(() => {
    dispatch({ type: AppActionType.setCurrentRole, data: Permission.Owner });
  }, [dispatch, currentUser?.email]);

  return (
    <Layout>
      {pathname !== ROUTES?.DASHBOARD && <Sidebar />}
      <Layout>
        <Content className="gx-layout-content">
          <div className="gx-main-content-wrapper">
            <div className="env-dropdown" id="env-dropdown" />
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
