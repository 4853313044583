import { gql } from '../../../__generated__';

export const CREATE_SENDER = gql(`
  mutation createSender($data: SenderDataCreate!) {
    createSender(data: $data) {
      status
      message
    }
  }
`);

export const UPDATE_SENDER = gql(`
  mutation updateSender($id: ID!, $data: SenderDataUpdate!) {
    updateSender(id: $id, data: $data) {
      data {
        email
      }
    }
  }
`);

export const DELETE_SENDER = gql(`
  mutation deleteSender($id: ID!) {
    deleteSender(id: $id) {
      status
      message
    }
  }
`);

export const VERIFY_SENDER = gql(`
  mutation verifySender($id: ID!, $token: String, $reVerify: Boolean!) {
    verifySender(id: $id, token: $token, reVerify: $reVerify) {
      message
      status
    }
  }
`);
