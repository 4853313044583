import { gql } from '../../../__generated__';

export const GET_PROJECT_MEMBERS = gql(`
  query projectMembers(
    $id: ID
    $projectEnvId: ID!
    $filter: ProjectMemberFilter!
  ) {
    projectMembers(id: $id, projectEnvId: $projectEnvId, filter: $filter) {
      projectMember {
        id
        email
        inviteStatus
        permission
      }
    }
  }
`);
