import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, message, Popconfirm, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { keys } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { ProviderType } from '../../../__generated__/graphql';
import { AppContext } from '../../../AppContext';
import { NoSettingIcon } from '../../../assets/svg';
import {
  PROJECT_ENV_ID_ERROR,
  PROVIDER_FIELD_TYPE,
  ROUTES,
  USERS_ROLE,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import {
  Platform,
  PlatformSortBy,
  PlatformType,
  Provider,
  ProviderSortBy,
} from '../../../gql/graphql';
import { AppContextType } from '../../../types/appContext.type';
import { DELETE_PLATFORM, UPDATE_PLATFORM } from '../graphql/Mutation';
import {
  GET_PLATFORM_CONFIGS,
  GET_PLATFORMS,
  GET_PROVIDER,
  GET_PROVIDERS,
} from '../graphql/Queries';

const { Option } = Select;
const { number } = formValidatorRules;

type PlatformListPropType = {
  providerId: string;
  // eslint-disable-next-line no-unused-vars
  setProviderId: (value: string | ((prevState: string) => string)) => void;
  // eslint-disable-next-line no-unused-vars
  setProviderValue: (value: string | ((prevState: string) => string)) => void;
  // eslint-disable-next-line no-unused-vars
  setProviderSlug: (value: string | ((prevState: string) => string)) => void;
  activeKey: string;
};
const PlatformList = ({
  providerId,
  setProviderId,
  setProviderValue,
  setProviderSlug,
  activeKey,
}: PlatformListPropType) => {
  const [form] = Form.useForm();
  const { state, getCurrentRole } = useContext(AppContext) as AppContextType;
  const userRole = getCurrentRole();
  const [platformList, setPlatformList] = useState<(Platform | null)[]>([]);
  const [providerList, setProviderList] = useState<(Provider | null)[]>([]);
  const [platformsLoading, setPlatformsLoading] = useState<boolean>(true);
  const [platformName, setPlatformName] = useState<string | null>();
  const [platformId, setPlatformId] = useState<string | null>();
  const [providerDetail, setProviderDetail] = useState<Provider | null>();
  const [platformSlug, setPlatformSlug] = useState<string>('');
  const [editPlatform, setEditPlatform] = useState<boolean>(false);
  const [isEditPlatformBtnLoading, setIsEditPlatformBtnLoading] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState();
  const isUpdatePermission =
    userRole === USERS_ROLE.OWNER || userRole === USERS_ROLE.WRITE;

  const [executePlatformsList, { loading: isProviderLoading }] = useLazyQuery(
    GET_PLATFORMS,
    {
      onCompleted: (response) => {
        if (
          response?.platforms?.platforms &&
          response?.platforms?.platforms?.length > 0
        ) {
          setPlatformList([
            ...(platformList || []),
            ...response?.platforms?.platforms,
          ]);

          setPlatformName(response?.platforms?.platforms?.[0]?.name);
          setPlatformId(response?.platforms?.platforms?.[0]?.id);
          setPlatformSlug(
            keys(
              response?.platforms?.platforms?.[0]?.platformConfigs?.EMAIL,
            )[0],
          );
          setProviderDetail(response?.platforms?.platforms?.[0]?.provider);
        }
        setPlatformsLoading(false);
      },
      fetchPolicy: 'network-only',
    },
  );

  const [executePlatform, { loading: isPlatFormLoading }] = useLazyQuery(
    GET_PLATFORM_CONFIGS,
    {
      onCompleted: (response) => {
        setInitialValues(response?.getPlatformConfigs?.platformConfigs);
      },
      fetchPolicy: 'network-only',
    },
  );

  const [executeUpdatePlatform] = useMutation(UPDATE_PLATFORM, {
    onError: () => {
      setIsEditPlatformBtnLoading(false);
    },
  });

  const [executeProvider] = useLazyQuery(GET_PROVIDER, {
    onCompleted: (response) => {
      setProviderDetail(response?.provider);
    },
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError() {},
  });

  const [executeProvidersList] = useLazyQuery(GET_PROVIDERS, {
    onCompleted: (response) => {
      if (
        response?.providers?.providers &&
        response?.providers?.providers?.length > 0
      ) {
        setProviderList([...providerList, ...response?.providers?.providers]);
      }
    },
    fetchPolicy: 'network-only',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError() {},
  });

  const [executeDeletePlatform] = useMutation(DELETE_PLATFORM, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  useEffect(() => {
    if (state?.projectEnvId && activeKey === ROUTES?.SETTING) {
      setPlatformsLoading(true);
      setPlatformList([]);
      executePlatformsList({
        variables: {
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            sortBy: PlatformSortBy.CreatedAtDesc,
          },
        },
      });
      if (providerList?.length === 0) {
        executeProvidersList({
          variables: {
            filter: {
              skip: 0,
              limit: 10,
              sortBy: ProviderSortBy.CreatedAtAsc,
              type: ProviderType.Email,
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, activeKey]);

  useEffect(() => {
    if (state?.projectEnvId && platformList?.length > 0 && platformId) {
      executePlatform({
        variables: {
          id: platformId,
          type: PlatformType.Email,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, platformId]);

  useEffect(() => {
    if (providerId) {
      executeProvider({
        variables: {
          id: providerId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    if (initialValues) {
      if (providerDetail?.slug) {
        form.setFieldsValue({
          name: platformName,
          platformConfigs: initialValues,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, platformName, editPlatform]);

  const deletePlatformFun = async (id: string | undefined) => {
    if (id) {
      const response = await executeDeletePlatform({
        variables: {
          id: id,
        },
      });
      if (response?.data) {
        setPlatformsLoading(true);
        setPlatformList([]);
        if (state?.projectEnvId) {
          executePlatformsList({
            variables: {
              projectEnvId: state?.projectEnvId,
              filter: {
                skip: 0,
                sortBy: PlatformSortBy.CreatedAtDesc,
              },
            },
          });
        } else {
          message.error(PROJECT_ENV_ID_ERROR);
        }
      }
    } else {
      message.error('Project does not exist');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const providerConfigs: Record<string, any>[] | null =
    providerDetail?.providerConfigs || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePlatformFun = async (values: { platformConfigs: any }) => {
    if (platformId) {
      setIsEditPlatformBtnLoading(true);
      const response = await executeUpdatePlatform({
        variables: {
          data: {
            name: platformName,
            platformConfigs:
              providerDetail?.name === 'DEFAULT'
                ? {
                    EMAIL: { DEFAULT: {} },
                  }
                : values?.platformConfigs || {},
            projectEnvId: state?.projectEnvId,
            type: PlatformType.Email,
          },
          id: platformId,
        },
      });
      if (response?.data) {
        setIsEditPlatformBtnLoading(false);
        executePlatform({
          variables: {
            id: platformId,
            type: PlatformType.Email,
          },
        });
        setEditPlatform(false);
      }
    } else {
      message.error('Platform id does not exist');
    }
  };

  if (platformsLoading) return <LoaderComponent setHeight="50" />;

  return (
    <div className="platform">
      {platformList?.length > 0 && (
        <>
          <LoaderComponent spinning={isProviderLoading || isPlatFormLoading}>
            <div className="add-container setting">
              <div className="d-flex flex-horizontal justify-between">
                <p className="add-title">
                  {!editPlatform ? 'Platform' : 'Update Platform'}
                </p>
              </div>
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="horizontal"
                onFinish={updatePlatformFun}
                labelCol={{
                  span: 3,
                }}
                wrapperCol={{
                  span: 21,
                }}
              >
                <Form.Item label="Platform Name" name="name" className="mt-10">
                  <Input className="input-box" disabled />
                </Form.Item>
                {providerConfigs?.map((providerConfig) => {
                  if (providerConfig?.type === PROVIDER_FIELD_TYPE?.NUMBER) {
                    return (
                      <Form.Item
                        key={providerConfig?.key}
                        label={providerConfig?.label}
                        name={[
                          'platformConfigs',
                          'EMAIL',
                          providerDetail?.slug,
                          providerConfig?.key,
                        ]}
                        className="mt-10"
                        rules={[
                          {
                            required: providerConfig?.isRequired,
                            message: `Please input ${providerConfig?.label}`,
                          },
                          number,
                        ]}
                      >
                        {providerConfig?.type ===
                        PROVIDER_FIELD_TYPE?.PASSWORD ? (
                          <Input.Password
                            className="customize-password"
                            disabled={!editPlatform}
                          />
                        ) : (
                          <Input
                            className="input-box"
                            disabled={!editPlatform}
                          />
                        )}
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item
                      key={providerConfig?.key}
                      label={providerConfig?.label}
                      name={[
                        'platformConfigs',
                        'EMAIL',
                        providerDetail?.slug,
                        providerConfig?.key,
                      ]}
                      className="mt-10"
                      rules={[
                        {
                          required: providerConfig?.isRequired,
                          message: `Please input ${providerConfig?.label}`,
                        },
                      ]}
                    >
                      {providerConfig?.type ===
                      PROVIDER_FIELD_TYPE?.PASSWORD ? (
                        <Input.Password
                          className="customize-password"
                          disabled={!editPlatform}
                        />
                      ) : (
                        <Input className="input-box" disabled={!editPlatform} />
                      )}
                    </Form.Item>
                  );
                })}
                {isUpdatePermission && (
                  <Form.Item
                    wrapperCol={{
                      offset: 3,
                      span: 21,
                    }}
                  >
                    {!editPlatform ? (
                      providerDetail?.name !== 'DEFAULT' && (
                        <Button
                          type="primary"
                          className="primary-button"
                          onClick={() => setEditPlatform(true)}
                        >
                          <EditOutlined
                            onClick={() => {
                              setProviderSlug(platformSlug);
                            }}
                            className="mr-8"
                          />
                          Edit Platform
                        </Button>
                      )
                    ) : (
                      <>
                        <Button
                          className="primary-button"
                          type="primary"
                          onClick={form?.submit}
                          loading={isEditPlatformBtnLoading}
                        >
                          Update Platform
                        </Button>
                        <Button
                          className="primary-button"
                          type="primary"
                          onClick={() => {
                            setEditPlatform(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                    <Popconfirm
                      title={`Are you sure to delete ${platformName} ?`}
                      onConfirm={() => deletePlatformFun(platformList?.[0]?.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className="secondary-button "
                        type="primary"
                        htmlType="submit"
                      >
                        <DeleteOutlined className="mr-8" />
                        Delete Platform
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
            </div>
          </LoaderComponent>
        </>
      )}

      {platformList?.length === 0 && !platformsLoading && (
        <div className="d-flex flex-vertical align-center">
          <div className="img-section mt-40">
            <NoSettingIcon />
          </div>
          <div className="text-section align-center mt-30">
            {isUpdatePermission ? (
              <span>
                No Setting Available! Please select the Platform Provider!
              </span>
            ) : (
              <span>No Data Found</span>
            )}
          </div>
          {isUpdatePermission && (
            <div className="provider-dropdown mt-20" id="provider-dropdown">
              <SelectComponent
                className="provider-select"
                showSearch={false}
                allowClear={false}
                placeholder="Select Platform Provider"
                onChange={(
                  value: string,
                  option: DefaultOptionType | DefaultOptionType[],
                ) => {
                  const typedOption = option as unknown as {
                    key: string;
                    value: string;
                    data: Provider;
                  };
                  setProviderSlug(typedOption?.data?.slug as string);
                  setProviderId(typedOption?.key);
                  setProviderValue(value);
                }}
              >
                {providerList?.length > 0 &&
                  providerList?.map((provider) => {
                    return (
                      <Option
                        key={provider?.id}
                        value={provider?.name}
                        data={provider}
                      >
                        {provider?.name}
                      </Option>
                    );
                  })}
              </SelectComponent>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlatformList;
