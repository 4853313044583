import { Card, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MoreIcon } from '../../../assets/svg';
import { ACTIVE_TABS, MODULES, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import Analytics from '../../analytics/Analytics';
import AuthDetail from '../../authDetails/AuthDetails';
import ChannelComponent from '../../channels/Channels';
import Logs from '../../logs/Logs';
import Senders from '../../senders/Senders';
import Setting from '../../setting/Setting';
import Templates from '../../template/Template';
import '../email.less';

const { TabPane } = Tabs;
type EmailTabsPropType = {
  // eslint-disable-next-line no-unused-vars
  setActiveKey: (value: string | ((prevState: string) => string)) => void;
  activeKey: string;
};
const EmailTabs = ({
  setActiveKey,
  activeKey = 'analytics',
}: EmailTabsPropType) => {
  const [isFirstTab, setIsFirstTab] = useState<boolean>(false);
  const { projectId, id } = useParams();
  const { navigate } = useRouter();
  const [isMailSend, setIsMailSend] = useState<boolean>(false);

  const handleKeyComponent = (keyPath: string) => {
    navigate(`${ROUTES?.PROJECTS}/${projectId}${ROUTES?.EMAIL}${keyPath}`);
    if (keyPath === ROUTES?.ANALYTICS) {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }
  };

  useEffect(() => {
    setActiveKey(id ? `/${id}` : ROUTES.ANALYTICS);
    // eslint-disable-next-line no-undef
    localStorage.setItem(ACTIVE_TABS, id ? `/${id}` : ROUTES.ANALYTICS);
    if (`/${id}` === ROUTES?.ANALYTICS) {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card className="full-height-card">
      <Tabs
        className={`${isFirstTab ? 'tabs no-border-top-left-radius' : 'tabs'}`}
        type="card"
        activeKey={activeKey}
        onChange={(e) => {
          handleKeyComponent(e);
        }}
        renderTabBar={(tabBarProps, DefaultTabBar) => {
          return (
            <DefaultTabBar
              {...tabBarProps}
              more={{
                icon: (
                  <>
                    <MoreIcon className="pointer" />
                  </>
                ),
              }}
            />
          );
        }}
      >
        <TabPane tab={MODULES?.ANALYTICS} key={ROUTES?.ANALYTICS}>
          <div className="tab-content-block">
            <Analytics activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.TEMPLATES} key={ROUTES?.TEMPLATES}>
          <div className="tab-content-block">
            <Templates activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.SENDERS} key={ROUTES?.SENDERS}>
          <div className="tab-content-block">
            <Senders activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.CHANNELS} key={ROUTES?.CHANNELS}>
          <div className="tab-content-block">
            <ChannelComponent
              setIsMailSend={setIsMailSend}
              activeKey={activeKey}
            />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.LOGS} key={ROUTES?.LOGS}>
          <div className="tab-content-block">
            <Logs
              isMailSend={isMailSend}
              setIsMailSend={setIsMailSend}
              activeKey={activeKey}
            />
          </div>
        </TabPane>
        <TabPane
          className="setting"
          tab={MODULES?.SETTING}
          key={ROUTES?.SETTING}
        >
          <div className="tab-content-block">
            <Setting activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.AUTH_DETAILS} key={ROUTES?.AUTH_DETAILS}>
          <div className="tab-content-block">
            <AuthDetail activeKey={activeKey} />
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default EmailTabs;
